import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Index from "../../../Index";
import QrCodeGenerate from "../../../../component/common/QrCodeGenerate";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { ethers } from "ethers";
import MenuItem from "@mui/material/MenuItem";
import { TailSpin } from "react-loader-spinner";

import copyTextToClipboard from "copy-text-to-clipboard";
import { toast } from "react-toastify";
import { BrowserDetect } from "../../../../component/common/DetectBrowser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DepositModal = ({ handleClose1 }) => {
  let browser = BrowserDetect();

  const [flag, setFlag] = useState(false);
  const [hash, setHash] = useState("");
  const [network, setNetwork] = useState(56);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [IpAddress, setIpAddress] = useState("");

  const [networkError, setNetworkError] = useState("");
  const [transactionError, setTransactionError] = useState("");

  const details = useSelector((store) => store?.admin?.userDetail);
  let userDetails = JSON?.parse(window?.atob(details));
  const settingData = useSelector((store) => store.admin.getSettingDetails);
  let settingDetails = JSON.parse(window.atob(settingData));

  const getIpAddress = async () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getIpAddress();
    let val = selectNetwork(network);
    setSelectedNetwork(val);
  }, []);

  const selectNetwork = (value) => {
    let name = "";
    switch (value) {
      case 137:
        name = "Polygon";
        break;
      case 1:
        name = "Ethereum";
        break;
      case 56:
        name = "BNB Smart Chain";
        break;
      case 5:
        name = "TRON";
        break;

      default:
        return false;
    }
    return name;
  };

  const handleChange = (event) => {
    const { value } = event.target;
    let network = selectNetwork(value);
    setFlag(false);
    if (
      (value == 137 || value == 1 || value == 56) &&
      !settingDetails?.usdt_address?.disable
    ) {
      setHash("");
      setNetwork(value);
      setSelectedNetwork(network);
      setQRCodeData(settingDetails?.usdt_address?.address);
    }
    if (value == 5 && !settingDetails?.tron_address?.is_disable) {
      setHash("");
      setNetwork(value);
      setSelectedNetwork(network);
      setQRCodeData(settingDetails?.tron_address?.address_tron);
    }
  };

  // copy deposit address
  const handleAddress = (data) => {
    copyTextToClipboard(data);
    toast.success("Wallet Address Copied");
  };

  const handleHash = (e) => {
    setHash(e.target.value);
    setTransactionError("");
  };

  useEffect(() => {
    if (settingDetails?.usdt_address?.disable) {
      // setQRCodeData(settingDetails?.tron_address.address_tron);
      if (settingDetails?.tron_address?.is_disable) {
        // setQRCodeData(settingDetails?.usdt_address.address);
        // setOpen1(false);
      } else {
        setQRCodeData(settingDetails?.tron_address?.address_tron);
      }
    } else {
      setQRCodeData(settingDetails?.usdt_address?.address);
    }
  }, []);

  let networkArr = [
    {
      id: 1,
      value: 1,
      label: "Ethereum",
    },
    {
      id: 2,
      value: 56,
      label: "BNB Smart Chain",
    },
    {
      id: 3,
      value: 137,
      label: "Polygon",
    },
    {
      id: 4,
      value: 5,
      label: "TRON",
    },
  ];

  const menuItems = (networkArr) => {
    return networkArr.map((item) => {
      if (!settingDetails?.usdt_address?.disable && item.id !== 4) {
        return (
          <MenuItem itemID={item?.label} value={item.value}>
            {item.label}
          </MenuItem>
        );
      }

      if (!settingDetails?.tron_address?.is_disable && item.id == 4) {
        return <MenuItem value={item.value}>{item.label}</MenuItem>;
      }

      if (
        !settingDetails?.usdt_address?.disable &&
        !settingDetails?.tron_address?.is_disable
      ) {
        <MenuItem value={item.value}>{item.label}</MenuItem>;
      }
    });
  };
  const validateHashKey = () => {
    let hashRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;

    const data = hashRegex.test(hash);
    console.log(data, ":data");
    if (hash == "") {
      setTransactionError("Please Enter Hash key");
    } else if (data) {
      setTransactionError("Please Enter Valid Hash key");
    } else {
      setTransactionError("");
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    validateHashKey();
    if (hash && network && selectedNetwork && qrCodeData) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("transactionHash", hash);
      urlencoded.append("network_address", qrCodeData);

      urlencoded.append("network_chain_id", network);
      urlencoded.append("network_type", selectedNetwork);
      urlencoded.append("ip_address", IpAddress);
      urlencoded.append("web_browser", browser);

      await DataService(userDetails?.token)
        .post(API.User.VERIFY_TRANSACTION, urlencoded)
        .then((res) => {
          if (res.data.status == 200) {
            toast.success(res?.data?.message);
            handleClose1();
          }
        })
        .catch((error) => {
          if (error.response.data.status === 400) {
            toast.error(error.response.data.message);
          }
          if (error.response.data.status === 500) {
            toast.error("Invalid Hash Key");
          }
          handleClose1();
        });
      setHash("");
      setNetwork("");
    } else {
      if (network == "") {
        setNetworkError("Please select network");
      }
    }
  };
  console.log(qrCodeData, ":qrCodeDataqrCodeDataqrCodeData");

  return (
    <div>
      <Index.Box sx={style} className="qr-main-modal deposit-modal-scroll">
        <Index.Link onClick={handleClose1}>
          <Index.Box className="colse-position">
            <Index.Box className="close-modal">
              <img
                src={Index.Png.closeicon}
                className="close-icon-modal"
                alt="dashboard icon"
              />
            </Index.Box>
          </Index.Box>
        </Index.Link>
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 5 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="set-modal-line">
                <Index.Box className="qr-modal-set">
                  <QrCodeGenerate qrCodeData={qrCodeData} />
                </Index.Box>
                <Index.Box>
                  <Index.Box class="desktop-view ">
                    <Index.Box class="dash-referral-btn-main   display-flex ">
                      <Index.Box class="text-copy-box dash-text-copy-box modal-text-copybox">
                        <Index.Typography
                          className="text-copy modal-text-copy"
                          component="h3"
                          variant="h3"
                        >
                          {qrCodeData}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box class="primary-btn-main copy-btn">
                        <Index.IconButton
                          className="history-btn"
                          onClick={() => {
                            handleAddress(qrCodeData);
                          }}
                        >
                          <Index.ContentCopyIcon />
                        </Index.IconButton>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box>
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h3"
                    variant="h3"
                  >
                    {" "}
                    Transaction
                  </Index.Typography>
                </Index.Box>
                <Index.Box className=" mt-10 ">
                  <Index.FormHelperText className="modal-text">
                    Network
                  </Index.FormHelperText>
                  <Index.Box className="input-design-div">
                    <FormControl
                      sx={{ m: 1, minWidth: 120 }}
                      className="main-select-box"
                    >
                      <Select
                        value={network}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {menuItems(networkArr)}
                      </Select>
                    </FormControl>
                    <p className="error-text">{networkError}</p>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                  <Index.FormHelperText className="modal-text mb-15">
                    Hash Key
                  </Index.FormHelperText>
                  <Index.Box className="form-group" mb={2}>
                    <Index.TextField
                      fullWidth
                      id=""
                      className="form-control"
                      name="hash"
                      value={hash}
                      onChange={(e) => handleHash(e)}
                    />
                  </Index.Box>
                  <p className="error-text">{transactionError}</p>
                </Index.Box>
                <Index.Box class="primary-btn-main qr-btn ">
                  <Index.Button
                    class="primary-btn copy-btn "
                    tabindex="0"
                    type="button"
                    onClick={(e) => handleVerify(e)}
                  >
                    Verify <span class=""></span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default DepositModal;
