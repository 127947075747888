import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector, useDispatch } from "react-redux";
import {
  validateEmail,
  validateMobileNo,
  validateName,
} from "../../../../component/common/AuthValidation";
import { adminProfile } from "../../../../redux/features/slices/Admin/AdminSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // Input States
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [adminDetails, setAdminDetails] = useState({});

  // Error States
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    let imgurl = URL.createObjectURL(e.target.files[0]);

    setUrl(imgurl);
  };

  const handleName = (e) => {
    setNameError(validateName(e.target.value));
    setName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailError(validateEmail(e.target.value.toLowerCase()));
    setEmail(e.target.value.toLowerCase());
  };

  const handleMobileNo = (e, data) => {
    setPhoneNumberError(validateMobileNo(e));
    setPhoneNumber(e);
    setCountryCode(data?.dialCode);
  };

  useEffect(() => {
    setName(adminDetails?.name);
    setEmail(adminDetails?.email);
    setPhoneNumber(adminDetails?.mobile_number);
  }, [adminDetails]);

  const validateInput = () => {
    setNameError(validateName(name));
    setEmailError(validateEmail(email));
    setPhoneNumberError(validateMobileNo(phoneNumber));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name && email && phoneNumber) {
      const urlencoded = new FormData();
      urlencoded.append("name", name?.trim());
      urlencoded.append("email", email?.trim());
      urlencoded.append("mobile_number", phoneNumber?.trim());
      urlencoded.append("country_code", countryCode);
      urlencoded.append("profile", image);

      await DataService(token)
        .post(API.Admin.EDIT_PROFILE, urlencoded)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            Index.toast.success(res?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
          Index.toast.error(error?.response?.data?.message);
        });
      getProfile();
    } else {
      validateInput();
    }
  };

  const getProfile = async () => {
    await DataService(token)
      .get(API.Admin.GET_ADMIN_PROFILE)
      .then((res) => {
        setAdminDetails(res?.data?.data);
        dispatch(adminProfile(res?.data?.data));
        setUrl(
          `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.data?.profile_image}`
        );
      })
      .catch((error) => {
        console.log(error, "Error");

        // Index.toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getProfile();
  }, [token]);

  const onDiscard = () => {
    setName(adminDetails?.name);
    setEmail(adminDetails?.email);
    setPhoneNumber(adminDetails?.mobile_number);
  };

  return (
    <Index.Box className="tabpanel-main">
      <Index.Box className="add-user-data-main">
        <Index.Box className="edit-profile-flex">
          <Index.Box className="file-upload-btn-main">
            <img
              src={url ? url : Index.Png.logo_new.png}
              className="upload-profile-img"
              alt="upload img"
            ></img>
            <Index.Button
              variant="contained"
              component="label"
              className="file-upload-btn"
            >
              <img
                src={Index.Svg.edit}
                className="upload-icon-img"
                alt="img"
              ></img>
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
              />
            </Index.Button>
          </Index.Box>
        </Index.Box>
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Name
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={name}
                    name="name"
                    inputProps={{ maxlength: 15 }}
                    onChange={(e) => handleName(e)}
                  />
                </Index.Box>
                <p className="error-text">{nameError}</p>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Email
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={email}
                    name="email"
                    onChange={(e) => handleEmail(e)}
                  />
                </Index.Box>
                <p className="error-text">{emailError}</p>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="input-box add-user-input">
                <Index.FormHelperText className="form-lable">
                  Phone Number
                </Index.FormHelperText>
                <Index.Box className="form-group country-code-main">
                  <Index.MuiPhoneInput
                    value={phoneNumber}
                    defaultCountry="in"
                    onChange={(e, countryDetails) =>
                      handleMobileNo(e, countryDetails)
                    }
                    className="country-code-details country-code-add-user"
                  />
                </Index.Box>
                <Index.ErrorShow error={phoneNumberError} />
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="user-btn-flex comman-btn-main">
                <Index.Box className="discard-btn-main border-btn-main">
                  <Index.Button
                    className="discard-user-btn border-btn"
                    onClick={onDiscard}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="save-btn-main ">
                  <Index.Button
                    className="save-user-btn primary-btn"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <img
                      src={Index.Svg.save}
                      alt="save"
                      className="user-save-icon"
                    ></img>
                    Save
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Profile;
