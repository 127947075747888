import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import message from "../../../../ValidationMessage.json";
import {
  validateRoundNo,
  validatePrice,
  validateDatePicker,
  validatePaymentMethod,
  validateMinimumPurchase,
  validateMonthlyRealese,
  validateRoundPercent,
  validateVestingPeriod,
} from "../../../../component/common/AuthValidation";

import dayjs from "dayjs";
import { adminLogout } from "../../../../redux/features/slices/Admin/AdminSlice";

let convertDate = (date = new Date()) => {
  let d = new Date(date);
  d.setHours(5, 30, 0, 0);
  return d.toJSON();
};

const PrivateAdminSale = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [flag, setFlag] = useState(false);

  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const presaleList = useSelector((store) => store.admin.presaleList);

  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");
  const [roundNo, setRoundNo] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [minimumPur, setMinimumPur] = useState("");
  const [vestingPeriod, setVestingPeriod] = useState("");
  const [monthlyRealese, setMonthlyRealese] = useState("");
  const [roundPercent, setRoundPercent] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [singleData, setSingleData] = useState({});
  const { editId } = useParams();
  const [price, setPrice] = useState("");
  const [error, setError] = useState({
    roundNoError: "",
    tokenQuantityError: "",
    priceError: "",
    paymentMethodError: "",
    minimumPurchaseError: "",
    monthlyRealeseError: "",
    roundPercentError: "",
    vestingPeriodError: "",
  });
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setendDateError] = useState("");

  useEffect(() => {
    if (editId && Object.keys(singleData).length > 0) {
      const initialValues = {
        startValue: editId
          ? Index.moment(singleData?.startDate).format("DD-MM-YYYY")
          : "",
        endValue: editId ? singleData?.endDate : "",
        roundNo: editId ? singleData?.phaseName : "",
        minimumPur: editId ? singleData?.minimumPurchase : "",
        paymentMethod: editId ? singleData?.paymentMethod : "",
        price: editId ? singleData?.tokenPrice : "",
        roundPercent: editId ? singleData?.roundPercent : "",
        bonusPercent: editId ? singleData?.bonusPercent : "",
        vestingPeriod: editId ? singleData?.vestingPeriod : "",
        monthlyRealese: editId ? singleData?.monthlyRelease : "",
      };
      // setStartValue(initialValues?.startValue);
      // setEndValue(initialValues?.endValue);
      setRoundNo(initialValues?.roundNo);
      // setPaymentMethod(initialValues?.paymentMethod);
      setMinimumPur(initialValues?.minimumPur);
      setPrice(initialValues?.price);
      setRoundPercent(initialValues?.roundPercent);
      setVestingPeriod(initialValues?.vestingPeriod);
      setMonthlyRealese(initialValues?.monthlyRealese);
    }
  }, [singleData, editId]);

  const handleChange = (e) => {
    setStartValue(convertDate(new Date(e)));
    setStartDateError(validateDatePicker(e));
  };
  const handleChange1 = (e) => {
    setEndValue(convertDate(new Date(e)));
    setendDateError(validateDatePicker(e));
  };
  const handleRoundOnErrorChange = (e) => {
    setRoundNo(e.target.value);
    setError((prev) => ({
      ...prev,
      roundNoError: validateRoundNo(e.target.value),
    }));
  };

  const onChangeroundPercent = (e) => {
    setRoundPercent(e.target.value);
    setError((prev) => ({
      ...prev,
      roundPercentError: validateRoundPercent(e.target.value),
    }));
  };

  const onPaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setError((prev) => ({
      ...prev,
      paymentMethodError: validatePaymentMethod(e.target.value),
    }));
  };
  const onMinimumPurchaseChange = (e) => {
    setMinimumPur(e.target.value);
    setError((prev) => ({
      ...prev,
      minimumPurchaseError: validateMinimumPurchase(e.target.value),
    }));
  };

  const onVestingPeriod = (e) => {
    setVestingPeriod(e.target.value);
    setError((prev) => ({
      ...prev,
      vestingPeriodError: validateVestingPeriod(e.target.value),
    }));
  };
  const onMonthlyRealese = (e) => {
    const val = Number(e.target.value);
    const threeDigitRegex = /^\d{3}$/;

    setMonthlyRealese(e.target.value);

    setError((prev) => ({
      ...prev,
      monthlyRealeseError: validateMonthlyRealese(e.target.value),
    }));
  };

  const handlePriceErrorChange = (e) => {
    setPrice(e.target.value);
    setError((prev) => ({
      ...prev,

      priceError: validatePrice(e.target.value),
    }));
  };

  const getSingleData = async () => {
    const formData = new URLSearchParams();

    formData.append("id", editId?.trim());

    await DataService(token)
      .post(API.Admin.GET_SINGLE_PRIVATE_SALE, formData)
      .then((response) => {
        setSingleData(response?.data?.data?.findData);
        setEndValue(response?.data.data?.findData?.endDate);
        setStartValue(response?.data.data?.findData?.startDate);
      })
      .catch((error) => {
        console.log(error, "Error");

        // Index.toast.error(error?.response?.data?.message);
        if (error.response.data.message === "Token not authorized") {
          dispatch(adminLogout(""));
          navigate("/admin/login");
        }
      });
  };

  useEffect(() => {
    if (editId) {
      getSingleData();
    }
  }, [editId]);

  const handleSubmit = async (e) => {
    setIsFetching(true);
    setFlag(true);

    if (
      startValue !== "" &&
      !startDateError &&
      endValue !== "" &&
      !endDateError &&
      roundNo !== "" &&
      !error.roundNoError &&
      price !== "" &&
      !error.priceError &&
      minimumPur !== "" &&
      !error.minimumPurchaseError &&
      roundPercent !== "" &&
      !error.roundPercentError &&
      vestingPeriod !== "" &&
      !error.vestingPeriodError &&
      monthlyRealese !== "" &&
      !error.monthlyRealeseError
    ) {
      const urlencoded = new URLSearchParams();
      if (startValue) {
        urlencoded.append("startDate", startValue);
      }
      if (endValue) {
        urlencoded.append("endDate", endValue);
      }

      urlencoded.append("phaseName", roundNo);
      // urlencoded.append("paymentMethod", paymentMethod);
      urlencoded.append("minimumPurchase", minimumPur);
      urlencoded.append("monthlyRelease", monthlyRealese);
      urlencoded.append("roundPercent", roundPercent);
      urlencoded.append("tokenPrice", price);
      urlencoded.append("vestingPeriod", vestingPeriod);
      if (editId !== undefined) {
        urlencoded.append("id", editId);
      }

      await DataService(token)
        .post(API.Admin.ADD_EDIT_PRIVATE_SALE, urlencoded)
        .then((response) => {
          setIsFetching(false);
          setFlag(false);
          Index.toast.success(response.data.message);
          setStartValue("");
          setEndValue("");
          setRoundNo("");
          setPrice("");
          setPaymentMethod("");
          setMinimumPur("");
          setRoundPercent("");
          setVestingPeriod("");
          setMonthlyRealese("");
          setTimeout(() => {
            navigate("/admin/presale-round");
          }, 1500);
        })
        .catch((error) => {
          Index.toast.error(error.response.data.message);
          setIsFetching(false);
        });
    } else {
      setFlag(false);
      if (error?.response?.data?.message === "Token not authorized") {
        dispatch(adminLogout(""));
        navigate("/admin/login");
      }
      if (startValue === null) {
        setStartDateError(message.SELECT_DATE);
      }
      if (endValue === null) {
        setendDateError(message.SELECT_DATE);
      }
      if (roundNo === "") {
        setError((prev) => ({ ...prev, roundNoError: message.ENTER_ROUND_NO }));
      }
      if (paymentMethod === "") {
        setError((prev) => ({
          ...prev,
          paymentMethodError: "Please Enter Payment Method",
        }));
      }
      if (roundPercent === "") {
        setError((prev) => ({
          ...prev,
          roundPercentError: "Please Enter Round Percentage",
        }));
      }
      if (minimumPur === "") {
        setError((prev) => ({
          ...prev,
          minimumPurchaseError: "Please Enter Minimum Purchase",
        }));
      }
      if (monthlyRealese === "") {
        setError((prev) => ({
          ...prev,
          monthlyRealeseError: "Please Enter Monthly Realese Amount",
        }));
      }

      if (vestingPeriod === "") {
        setError((prev) => ({
          ...prev,
          vestingPeriodError: "Please Enter Vesting Period",
        }));
      }

      if (price === "") {
        setError((prev) => ({
          ...prev,
          priceError: message.ENTER_PRICE,
        }));
      }
    }
    setFlag(false);
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });
  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            {editId === undefined ? "Add" : "Edit"} Private Sale
          </Index.Typography>
        </Index.Box>

        <Index.Box className="add-user-data-main">
          <Index.Box className="admin-private-main">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main presale-input">
                          <Index.Box className="input-box input-box-admin input-box-admin2">
                            <Index.FormHelperText
                              className="form-lable"
                              for="user"
                            >
                              Start Date
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                {startValue && (
                                  <Index.Stack className="sale-field date-picker-mui">
                                    <Index.DatePicker
                                      className="privat-sale-datepicker"
                                      format="DD-MM-YYYY"
                                      disabled={editId ? true : false}
                                      shouldDisableDate={(date) =>
                                        new Date(date).getTime() <=
                                        new Date(
                                          presaleList?.data?.presale?.slice(
                                            -1
                                          )[0]?.endDate
                                        )?.getTime()
                                      }
                                      defaultValue={dayjs(
                                        editId ? startValue : ""
                                      )}
                                      onChange={handleChange}
                                      renderInput={(params) => (
                                        <Index.TextField {...params} />
                                      )}
                                    />
                                  </Index.Stack>
                                )}
                                {!startValue && (
                                  <Index.Stack className="sale-field date-picker-mui">
                                    <Index.DatePicker
                                      className="privat-sale-datepicker"
                                      format="DD-MM-YYYY"
                                      shouldDisableDate={(date) =>
                                        new Date(date).getTime() <=
                                        new Date(
                                          presaleList?.data?.presale?.slice(
                                            -1
                                          )[0]?.endDate
                                        )?.getTime()
                                      }
                                      defaultValue={dayjs(
                                        editId ? startValue : ""
                                      )}
                                      onChange={handleChange}
                                      renderInput={(params) => (
                                        <Index.TextField {...params} />
                                      )}
                                    />
                                  </Index.Stack>
                                )}
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                          <p className="error-text">{startDateError}</p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-text-field-main presale-input">
                          <Index.Box className="input-box input-box-admin input-box-admin2">
                            <Index.FormHelperText className="form-lable">
                              End Date
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                {endValue && (
                                  <Index.Stack className="sale-field date-picker-mui">
                                    <Index.DatePicker
                                      className="privat-sale-datepicker"
                                      format="DD-MM-YYYY"
                                      shouldDisableDate={(date) =>
                                        new Date(date)?.getTime() <=
                                        new Date()?.getTime()
                                      }
                                      defaultValue={dayjs(
                                        editId ? endValue : ""
                                      )}
                                      onChange={handleChange1}
                                      renderInput={(params) => (
                                        <Index.TextField {...params} />
                                      )}
                                    />
                                  </Index.Stack>
                                )}

                                {!endValue && (
                                  <Index.Stack className="sale-field date-picker-mui">
                                    <Index.DatePicker
                                      className="privat-sale-datepicker"
                                      format="DD-MM-YYYY"
                                      shouldDisableDate={(date) =>
                                        new Date(date).getTime() <=
                                        new Date(
                                          presaleList?.data?.presale?.slice(
                                            -1
                                          )[0]?.endDate
                                        )?.getTime()
                                      }
                                      defaultValue={dayjs(
                                        editId ? endValue : ""
                                      )}
                                      onChange={handleChange1}
                                      renderInput={(params) => (
                                        <Index.TextField {...params} />
                                      )}
                                    />
                                  </Index.Stack>
                                )}
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                          <p className="error-text">{endDateError}</p>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Round No
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          id="outlined-basic"
                          type="number"
                          placeholder="Please Enter Round Number"
                          value={roundNo}
                          onChange={handleRoundOnErrorChange}
                          disabled={editId ? true : false}
                        />
                      </Index.Box>
                    </Index.Box>
                    <p className="error-text">{error.roundNoError}</p>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Minimum Purchase
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          variant="outlined"
                          id="outlined-basic"
                          type="number"
                          placeholder="Please Enter Minimum Purchase"
                          value={minimumPur}
                          onChange={onMinimumPurchaseChange}
                          disabled={editId ? true : false}
                        />{" "}
                      </Index.Box>
                    </Index.Box>

                    <p className="error-text">{error.minimumPurchaseError}</p>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Monthly release %
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          variant="outlined"
                          type="number"
                          id="outlined-basic"
                          placeholder="Please Enter Monthly Release %"
                          value={monthlyRealese}
                          onChange={onMonthlyRealese}
                          disabled={editId ? true : false}
                        />
                      </Index.Box>
                    </Index.Box>
                    <p className="error-text">{error.monthlyRealeseError}</p>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Round Percent %
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          variant="outlined"
                          id="outlined-basic"
                          type="number"
                          placeholder="Please Enter Round Percentage"
                          value={roundPercent}
                          onChange={onChangeroundPercent}
                          disabled={editId ? true : false}
                        />
                      </Index.Box>
                    </Index.Box>
                    <p className="error-text">{error.roundPercentError}</p>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Token Price
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          variant="outlined"
                          type="number"
                          id="outlined-basic"
                          placeholder="Please Enter Token Price"
                          value={price}
                          onChange={handlePriceErrorChange}
                        />
                      </Index.Box>
                    </Index.Box>
                    <p className="error-text">{error.priceError}</p>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-text-field-main presale-input">
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable" for="user">
                        Vesting Period
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          className="admin-textfield"
                          variant="outlined"
                          id="outlined-basic"
                          type="number"
                          placeholder="Please Enter Vesting Period"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 3);
                          }}
                          value={vestingPeriod}
                          onChange={onVestingPeriod}
                          disabled={editId ? true : false}
                        />
                      </Index.Box>
                    </Index.Box>

                    <p className="error-text">{error.vestingPeriodError}</p>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="private-admin-btn-main primary-btn-main-comman">
              <Index.PrimaryButton
                className="sale-btn add-user-btn admin-private-btn btn-dark-comman-main box-shadow-none"
                type="button"
                variant="contained"
                btnLabel={editId ? "Update" : "Submit"}
                onClick={() => {
                  !flag && handleSubmit();
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PrivateAdminSale;
