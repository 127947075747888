import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AddUser from "../container/admin/pages/user/AddUser";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Login from "../container/admin/auth/Login";
import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import UserList from "../container/admin/pages/user/UserList";
import UserLogin from "../container/user/auth/Login";
import Home from "../container/user/pages/home/Home";
import PresaleRound from "../container/admin/pages/privateSale/PresaleRound";
import PrivateAdminSalefrom from "../container/admin/pages/privateSale/PrivateAdminSale";
import Waitlist from "../container/admin/pages/waitlist/Waitlist";
import UserDashboard from "../container/user/pages/userDashboard/UserDashboard";
import DashboardContent from "../container/user/pages/dashboardcontent/DashboardContent";
import AdminSetting from "../container/admin/pages/setting/AdminSetting";
import Setting from "../container/user/pages/setting/Setting";
import PrivateRoutes from "./protectedRoutes/ProtectedUserRoutes";
import Referral from "../container/user/pages/referral/Referral";
import Chart from "../container/user/pages/treeStructure/Chart";
import AdminDashboard from "../container/admin/pages/dashboard/AdminDashboard";
import TransactionList from "../container/admin/pages/transactions/TransactionList";
import PrivacyPolicy from "../container/admin/pages/cms/PrivacyPolicy";
import TermsAndCondition from "../container/admin/pages/cms/TermsAndCondition";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import OtpVerify from "../container/admin/auth/OtpVerify";
import UserOtpVerify from "../container/user/auth/OtpVerify";
import UserForgotPassword from "../container/user/auth/ForgotPassowrd";
import UserResetPassword from "../container/user/auth/ResetPassword";
import ResetPassword from "../container/admin/auth/ResetPassword";
import MyTransaction from "../container/user/pages/mytransaction/MyTransaction";
import UserDetails from "../container/admin/pages/user/UserDetails";
import UserEditProfile from "../container/user/pages/userProfile/UserEditProfile";
import GeneralSetting from "../container/user/pages/userProfile/GeneralSetting";
import WaitlistTab from "../container/admin/pages/waitlist/waitlistTab/WaitlistTab";
import PresaleTab from "../container/admin/pages/privateSale/presaleTab/PresaleTab";
import Userkyc from "../container/user/pages/kyc/Kyc";
import KycList from "../container/admin/pages/kyc/KycList";
import AdminKycview from "../container/admin/pages/kyc/KycView";
import ReferralTab from "../container/user/pages/referral/ReferralTab";
import MainReferral from "../container/admin/pages/mainReferral/MainReferral";
import ReferralUserList from "../container/user/pages/referral/ReferralUserList";
import KycResponse from "../container/user/pages/kyc/KycResponse";

import MyIncomeTab from "../container/user/pages/myIncome/myIncomeTab/MyIncomeTab";
import StakingTab from "../container/admin/pages/staking/stakingTab/StakingTab";
import StakingList from "../container/user/pages/stak/StakingList";
import StakingDetails from "../container/user/pages/stak/StakingDetails";
import AdminPrivateRoutes from "./protectedRoutes/ProtectedAdminRoutes";
import WithdrawList from "../container/user/pages/withdraw/WithdrawList";
import WithdrawRequestList from "../container/admin/pages/withdrawRequest/WithdrawRequestList";

import TokenCategoryDetails from "../container/admin/pages/category/TokenCategoryDetails";
import TokenCategoryList from "../container/admin/pages/category/TokenCategoryList";

const Routers = () => {
  return (
    <Routes>
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin/forgot-password" element={<ForgotPassword />} />
      <Route path="/admin/reset-password/:id" element={<ResetPassword />} />
      <Route path="/admin/otp" element={<OtpVerify />} />
      <Route path="/admin/response" element={<KycResponse />} />

      <Route element={<AdminPrivateRoutes />}>
        <Route path="/admin" element={<Dashboard />}>
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="user-list" element={<UserList />} />
          <Route path="user-details/:userId" element={<UserDetails />} />
          <Route path="add-user" element={<AddUser />} />
          <Route path="edit-user/:editId" element={<AddUser />} />
          <Route path="presale-round" element={<PresaleRound />} />
          <Route path="privateadminsale" element={<PrivateAdminSalefrom />} />
          <Route path="presale-details/:presaleId" element={<PresaleTab />} />
          <Route path="transaction" element={<TransactionList />} />
          <Route path="kycDetail" element={<KycList />} />
          <Route path="stak" element={<StakingTab />} />
          <Route path="Kycview/:id" element={<AdminKycview />} />

          <Route path="waitlist-details/:userId" element={<WaitlistTab />} />
          <Route path="main-referral-list" element={<MainReferral />} />

          <Route
            path="privateadminsale/:editId"
            element={<PrivateAdminSalefrom />}
          />
          <Route path="waitlist" element={<Waitlist />} />
          <Route path="setting" element={<AdminSetting />} />
          <Route
            path="withdraw-request-list"
            element={<WithdrawRequestList />}
          />
          <Route path="token-category" element={<TokenCategoryList />} />
          <Route path="token-category/:id" element={<TokenCategoryDetails />} />
          <Route path="token-category/all" element={<TokenCategoryDetails />} />

          <Route path="cms">
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-condition" element={<TermsAndCondition />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/user/login" replace={true} />} />
      <Route path="/user/login" element={<UserLogin />} />
      <Route path="/user/forgot-password" element={<UserForgotPassword />} />
      <Route path="/user/reset-Password" element={<UserResetPassword />} />
      <Route path="/user/otp" element={<UserOtpVerify />} />
      <Route path="/user/join-waitlist" element={<Home />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/user" element={<UserDashboard />}>
          <Route path="dashboard" element={<DashboardContent />} />
          <Route path="setting" element={<Setting />} />
          <Route path="referral" element={<Referral />} />
          <Route path="mytree" element={<Chart />} />
          <Route path="mytransaction" element={<MyTransaction />} />
          <Route path="edit-profile" element={<UserEditProfile />} />
          <Route path="general-setting" element={<GeneralSetting />} />
          <Route path="kyc" element={<Userkyc />} />
          <Route path="referral-details" element={<ReferralTab />} />
          <Route path="referral-user-list" element={<ReferralUserList />} />
          <Route path="stak" element={<StakingList />} />
          <Route path="stak-details/:stakId" element={<StakingDetails />} />
          <Route path="myIncome" element={<MyIncomeTab />} />
          <Route path="withdraw" element={<WithdrawList />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Routers;
