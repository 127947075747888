import React from "react";
import Index from "../../../../Index";

const PresaleDashboard = ({ data }) => {
  return (
    <div>
      <Index.Box className="tabpanel-main">
        <Index.Box className="add-user-data-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box admin-dashboard-box2 presale-dash">
                  <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text user-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Transaction
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price user-dash-price "
                        variant="h1"
                        component="h1"
                      >
                        {data?.transactionList?.length
                          ? data?.transactionList?.length
                          : "0"}
                        {/* {totalValue?.totalHash ? totalValue?.totalHash : 0} */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right dash-icon-main">
                      <img
                        src={Index.Png.logo_new}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box admin-dashboard-box2 presale-dash">
                  <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text user-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Purchase
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price user-dash-price "
                        variant="h1"
                        component="h1"
                      >
                        0
                        {/* {totalValue?.totalUsdt > 999999 ? ((totalValue?.totalUsdt) / 1000000)?.toFixed(2) + " M" : totalValue?.totalUsdt ? totalValue?.totalUsdt?.toFixed(2) : 0} */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right dash-icon-main">
                      <img
                        src={Index.Svg.usdtlogo}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box admin-dashboard-box2 presale-dash">
                  <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text user-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Sale
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price user-dash-price "
                        variant="h1"
                        component="h1"
                      >
                        0
                        {/* {totalValue?.totalToken > 999999 ? (parseInt(totalValue?.totalToken) / 1000000)?.toFixed(2) + " M" : totalValue?.totalToken ? parseInt(totalValue?.totalToken)?.toFixed(2) : 0} */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right dash-icon-main">
                      <img
                        src={Index.Png.logo_new}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box admin-dashboard-box2 presale-dash">
                  <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text user-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Deposit
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price user-dash-price "
                        variant="h1"
                        component="h1"
                      >
                        {data?.totalDeposit
                          ? data?.totalDeposit?.toFixed(2)
                          : 0}
                        {/* {totalValue?.totalToken > 999999 ? (parseInt(totalValue?.totalToken) / 1000000)?.toFixed(2) + " M" : totalValue?.totalToken ? parseInt(totalValue?.totalToken)?.toFixed(2) : 0} */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right dash-icon-main">
                      <img
                        src={Index.Png.logo_new}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box admin-dashboard-box2 presale-dash">
                  <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box mb-0">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text user-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total User
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price user-dash-price "
                        variant="h1"
                        component="h1"
                      >
                        {data?.user?.length ? data?.user?.length : 0}
                        {/* {totalValue?.totalToken > 999999 ? (parseInt(totalValue?.totalToken) / 1000000)?.toFixed(2) + " M" : totalValue?.totalToken ? parseInt(totalValue?.totalToken)?.toFixed(2) : 0} */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right dash-icon-main">
                      <img
                        src={Index.Png.logo_new}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PresaleDashboard;
