import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

function RoiTable({ data }) {
  return (
    <Index.Box class="">
      <Index.Box className="">
        <Index.Box className="admin-dashboard-list-row roi-year-intereset">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main roi-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        // sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Year 1
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Year 2
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Year 3
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Year 4
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Year 5
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          <Index.TableRow
                            key={data[0]}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td"
                            >
                              {data[0]}%
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              align="center"
                              className="table-td set-hover-text"
                            >
                              {data[1]}%
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td set-hover-text"
                              align="center"
                            >
                              {data[2]}%
                            </Index.TableCell>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td set-hover-text"
                              align="center"
                            >
                              {data[3]}%
                            </Index.TableCell>

                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="table-td email-ellip"
                              align="right "
                            >
                              {data[4]}%
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default RoiTable;
