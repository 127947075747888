import React from "react";
import Index from "../../../../Index";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DataService } from "../../../../../config/DataService";
import { API } from "../../../../../config/Api";
import { Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Staking = () => {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [stakingList, setStakingList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearch = (event) => {
    const result = stakingList.filter((item) => {
      return (
        item?.user_id?.user_name
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase()) ||
        item?.user_id?.email
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase())
      );
    });
    setFilterData(result);
    setPage(0);
  };

  const getStakingList = async () => {
    await DataService(token)
      .get(API.Admin.ALL_STACK_LIST)
      .then((res) => {
        setStakingList(res.data.data);
        setFilterData(res.data.data);
      });
  };

  useEffect(() => {
    getStakingList();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Index.Box className="stack-tab-one">
        <Index.Box className="dashboard-content add-user-container">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title user-list-page-title res-admin-page-title-set"
              component="h2"
              variant="h2"
            >
              Staking User
            </Index.Typography>

            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main transaction-table-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Created Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                USERNAME
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Total Amount (USDT)
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  return (
                                    <Index.TableRow
                                      key={row.createdAt}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.createdAt
                                          ? Index.moment(row?.createdAt).format(
                                              "DD-MM-YYYY hh:mm A"
                                            )
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_id?.user_name}
                                          arrow
                                          placement="top"
                                        >
                                          <Index.Box className="username-bold">
                                            {row?.user_id?.user_name
                                              ? row?.user_id?.user_name
                                              : "-"}
                                          </Index.Box>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_id?.email}
                                          arrow
                                          placement="top"
                                        >
                                          <span>
                                            {row?.user_id?.email
                                              ? row?.user_id?.email
                                              : "-"}
                                          </span>
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.total_amount
                                          ? row?.total_amount
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td email-ellip"
                                        align="right"
                                      >
                                        <Index.Button onClick={handleOpen}>
                                          <img
                                            src={Index.Svg.view}
                                            className="view-icon"
                                          ></img>
                                        </Index.Button>
                                        <Modal
                                          open={open}
                                          onClose={handleClose}
                                          aria-labelledby="modal-modal-title"
                                          aria-describedby="modal-modal-description"
                                        >
                                          <Index.Box sx={style}>
                                            here pass the table
                                          </Index.Box>
                                        </Modal>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={filterData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Staking;
