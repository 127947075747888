import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { API } from "../../../../../config/Api";
import { DataService } from "../../../../../config/DataService";
import Index from "../../../../Index";

function AllInCome({ data }) {
  const [allIncomeList, setAllIncomeList] = useState({});

  useEffect(() => {
    setAllIncomeList(data);
  }, [data]);

  return (
    <Index.Box class="">
      <Index.Box className="setting-card">
        <Index.Box className="dashboard-content referral-list-tree-btn">
          <Index.Typography
            className="admin-page-title res-referral-list-tree-btn"
            component="h2"
            variant="h2"
          ></Index.Typography>
          {/* <Index.Box className="preasale-search">
            <Index.Box className="user-search-main cus-search">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 3",
              lg: "span 4",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dashboard-box">
              <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography
                    className="admin-dash-text user-dash-text"
                    variant="p"
                    component="p"
                  >
                    Total Stake Income
                  </Index.Typography>
                  <Index.Typography
                    className="admin-dash-price user-dash-price "
                    variant="h1"
                    component="h1"
                  >
                    {allIncomeList?.stak_income
                      ? allIncomeList?.stak_income.toFixed(2)
                      : 0}{" "}
                    USDT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right dash-icon-main">
                  <img
                    src={Index.Svg.usdtlogo}
                    className="dash-icons"
                    alt="dashboard icon"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 3",
              lg: "span 4",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dashboard-box">
              <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography
                    className="admin-dash-text user-dash-text"
                    variant="p"
                    component="p"
                  >
                    Total Bonus Income
                  </Index.Typography>
                  <Index.Typography
                    className="admin-dash-price user-dash-price"
                    variant="h1"
                    component="h1"
                  >
                    {allIncomeList?.bonus_amount
                      ? allIncomeList?.bonus_amount.toFixed(2)
                      : 0}{" "}
                    USDT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right dash-icon-main">
                  <img
                    src={Index.Svg.usdtlogo}
                    className="dash-icons"
                    alt="dashboard icon"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 3",
              lg: "span 4",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dashboard-box">
              <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                <Index.Box className="admin-dash-left">
                  <Index.Typography
                    className="admin-dash-text user-dash-text"
                    variant="p"
                    component="p"
                  >
                    Total Working Income
                  </Index.Typography>
                  <Index.Typography
                    className="admin-dash-price user-dash-price "
                    variant="h1"
                    component="h1"
                  >
                    {allIncomeList?.working_income
                      ? allIncomeList?.working_income.toFixed(2)
                      : 0}{" "}
                    USDT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-dash-right dash-icon-main">
                  <img
                    src={Index.Svg.usdtlogo}
                    className="dash-icons"
                    alt="dashboard icon"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default AllInCome;
