import React, { useState } from "react";
import Index from "../../../../component/Index";
import { Outlet } from "react-router-dom";
export default function Dashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  return (
    <div>
      <Index.Box
        className={drawerOpen ? "dashboard-main" : "dashboard-main active"}
      >
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
          />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
