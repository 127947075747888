import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export default function KycList() {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [kycList, setKycList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Get Kyc List
  const getKycList = async () => {
    try {
      const res = await DataService(token).get(API.Admin.KYC_LIST);
      const data = res.data.data;
      setKycList(data);
      setFilterData(data);
    } catch (err) {
      console.log(err, ":errs");
    }
  };

  const handleSearch = (event) => {
    const result = kycList.filter((row) => {
      return (
        row?.user_id?.user_name
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value.toLowerCase()) ||
        row?.pancard_number?.toString()?.includes(event?.target?.value) ||
        row?.address_proof?.toString()?.includes(event?.target?.value) ||
        row?.status?.toString()?.includes(event?.target?.value)
      );
    });

    setFilterData(result);
    setPage(0);
  };

  useEffect(() => {
    getKycList();
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            KYC Details
          </Index.Typography>

          <Index.Box className="preasale-search">
            <Index.Box className="user-search-main cus-search">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
              <Index.Link
                // to="/admin/privateadminsale"
                className="add-user-btn btn-dark-comman-main"
                style={{
                  textDecoration: "none",
                  fontSize: 15,
                  fontFamily: "Poppins-SemiBold",
                }}
              >
                <Index.AddIcon /> Add
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main kyc-table-main table-design-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Created Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              User Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              PhotoId Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Pancard Number
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Address Proof
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Status
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => {
                                return (
                                  <Index.TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt).format(
                                            "DD-MM-YYYY hh:mm A"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.user_id?.user_name}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="username-bold">
                                          {row?.user_id?._id
                                            ? row?.user_id?.user_name
                                            : "-"}
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.name}
                                        arrow
                                        placement="top"
                                      >
                                        <span>
                                          {" "}
                                          {row?.name
                                            ? row?.name.substring(0, 30)
                                            : "-"}{" "}
                                        </span>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.pancard_number}
                                        arrow
                                        placement="top"
                                      >
                                        <span>
                                          {row?.pancard_number
                                            ? row?.pancard_number
                                            : "-"}
                                        </span>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td set-hover-text"
                                      align="right"
                                    >
                                      <Index.Tooltip
                                        title={row?.address_proof}
                                        arrow
                                        placement="top"
                                      >
                                        <span>
                                          {row?.address_proof
                                            ? row?.address_proof
                                            : "-"}
                                        </span>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="right"
                                    >
                                      {row?.status ? row?.status : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td email-ellip"
                                      align="right"
                                    >
                                      <Index.Link
                                        to={`/admin/Kycview/${row?.user_id?._id}`}
                                      >
                                        <Index.Tooltip
                                          title={"View"}
                                          arrow
                                          placement="top"
                                        >
                                          <span>View</span>
                                        </Index.Tooltip>
                                      </Index.Link>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={7}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
