import backaadhar from './images/png/backaadhar.jpg';
import frontaadhar from './images/png/frontaadhar.jpg';
import ResponseImg from './images/jpg/response.jpg';


const Jpg = {
    backaadhar,
    frontaadhar,
    ResponseImg
}

export default Jpg;