import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import Index from "../../../../component/Index";
import { changePasswordSchema } from "../../../../validations/ValidationSchema";

const UserChangePassword = () => {
  const token = Index.useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  const formik = useRef();

  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [showOldPassword, setOldShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowOldPassword = () => setOldShowPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleFormSubmit = async (values) => {
    const formData = new URLSearchParams();
    formData.append("oldPassword", values?.oldPassword?.trim());
    formData.append("newPassword", values?.newPassword?.trim());
    await DataService(userToken)
      .post(API.User.USER_CHANGE_PASSWORD, formData)
      .then((response) => {
        Index.toast.success(response?.data?.message);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
    formik.current.resetForm();
  };

  return (
    <Index.Box className="tabpanel-main">
      <Formik
        innerRef={formik}
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={changePasswordSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => (
          <Form onSubmit={() => handleSubmit()}>
            <Index.Box className="change-pass-main">
              <Index.Typography
                className="common-para edit-highlight-text"
                component="p"
                variant="p"
              >
                Password
              </Index.Typography>
              <Index.Typography
                className="common-para edit-para-text"
                component="p"
                variant="p"
              >
                Enter your current & new password to reset your password
              </Index.Typography>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Old Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      onBlur={handleBlur}
                      value={values.oldPassword}
                      onChange={handleChange}
                      helperText={touched.oldPassword && errors.oldPassword}
                      error={Boolean(errors.oldPassword && touched.oldPassword)}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.oldPassword && errors.oldPassword && (
                      <Index.FormHelperText error>
                        {errors.oldPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      onBlur={handleBlur}
                      value={values.newPassword}
                      onChange={handleChange}
                      helperText={touched.newPassword && errors.newPassword}
                      error={Boolean(errors.newPassword && touched.newPassword)}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />

                    {touched.newPassword && errors.newPassword && (
                      <Index.FormHelperText error>
                        {errors.newPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Confirm New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.Box className="form-group pass_group_main">
                    <Index.OutlinedInput
                      className="form-control custom_form_control"
                      inputProps={{ maxlength: 16 }}
                      id="outlined-adornment-password"
                      type={showPassword1 ? "text" : "password"}
                      name="confirmNewPassword"
                      onBlur={handleBlur}
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                      helperText={
                        touched.confirmNewPassword && errors.confirmNewPassword
                      }
                      error={Boolean(
                        errors.confirmNewPassword && touched.confirmNewPassword
                      )}
                      endAdornment={
                        <Index.InputAdornment
                          position="end"
                          className="pass_position"
                        >
                          <Index.IconButton
                            className="icon_end_icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword1 ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                      label="Password"
                    />
                    {touched.confirmNewPassword &&
                      errors.confirmNewPassword && (
                        <Index.FormHelperText error>
                          {errors.confirmNewPassword}
                        </Index.FormHelperText>
                      )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-btn-flex comman-btn-main change-pass-flex">
                <Index.Box className="discard-btn-main border-btn-main">
                  <Index.Button
                    className="discard-user-btn border-btn"
                    onClick={resetForm}
                  >
                    Discard
                  </Index.Button>
                </Index.Box>
                <Index.Box className="save-btn-main ">
                  <Index.Button
                    className="save-user-btn primary-btn"
                    onClick={handleSubmit}
                  >
                    <img
                      src={Index.Svg.save}
                      alt="save"
                      className="user-save-icon"
                    ></img>
                    Save
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </Index.Box>
  );
};

export default UserChangePassword;
