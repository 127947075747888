import React, { useState, useEffect } from "react";
import Index from "../../../../Index";

function StakIncome({ data }) {
  const [filterData, setFilterData] = useState([]);
  const [stakIncomeList, setStakIncomeList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearch = (event) => {
    const result = stakIncomeList.filter((item) => {
      let date = Index.moment(item?.date)
        .format("DD-MM-YYYY hh:mm A")
        .toString()
        ?.includes(event?.target?.value);
      let stakAmount = item?.stack_amount
        .toString()
        ?.includes(event?.target?.value);
      let stakId = item?.stack_id
        ?.toLowerCase()
        .toString()
        ?.includes(event?.target?.value?.toLowerCase());
      let roi = item?.roi.toString()?.includes(event?.target?.value);
      let dailyReport = item?.daily_profit
        .toString()
        ?.includes(event?.target?.value);

      return date || stakAmount || stakId || roi || dailyReport;
    });
    setFilterData(result);
    setPage(0);
  };

  useEffect(() => {
    setStakIncomeList(data);
    setFilterData(data);
  }, []);

  return (
    <>
      <Index.Box class="">
        <Index.Box className="setting-card">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title res-referral-list-tree-btn"
              component="h2"
              variant="h2"
            ></Index.Typography>
            <Index.Box className="preasale-search">
              <Index.Box className="user-search-main cus-search">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />

                    <img
                      src={Index.Svg.greysearch}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Stake Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                ROI
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Daily Profit (USDT)
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Stake Amount (USDT)
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData?.length ? (
                              filterData?.map((row, index) => {
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.date
                                        ? Index.moment(row?.date).format(
                                            "DD-MM-YYYY hh:mm A"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.stack_id ? row?.stack_id : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td set-hover-text"
                                      align="right"
                                    >
                                      {row ? row.roi : 0} %
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td set-hover-text"
                                      align="right"
                                    >
                                      {row?.daily_profit
                                        ? row?.daily_profit
                                        : 0}{" "}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.stack_amount
                                        ? row?.stack_amount
                                        : "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={5}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={filterData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default StakIncome;
