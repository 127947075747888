import React, { useState } from "react";
import Index from "../../Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { useParams } from "react-router-dom";

// New Import
import { Formik, Form } from "formik";
import { ResetPasswordSchema } from "../../../validations/ValidationSchema";

export default function ResetPassword() {
  const navigate = Index.useNavigate();
  const params = Index.useParams();

  let initialValues = {
    password: "",
    confirmPassword: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handlePasswordShow = () => setShowPassword((show1) => !show1);

  const handleConfirmPasswordShow = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("forgot_id", params?.id?.trim());
    urlencoded.append("password", values?.password?.trim());

    await DataService()
      .post(API.Admin.ADMIN_RESET_PASSWORD, urlencoded)
      .then((res) => {
        console.log(res, "ResetPassword");
        navigate("/admin/login");
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Png.logo_new}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={ResetPasswordSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Index.Box className="card-login-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Reset Password
                      </Index.Typography>

                      <Index.Box className="input-box input-box-admin">
                        <Index.FormHelperText className="form-lable">
                          Password <span style={{ color: "red" }}>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group pass_group_main">
                          <Index.Box className="form-group pass_group_main">
                            <Index.OutlinedInput
                              className="form-control custom_form_control"
                              id="outlined-adornment-password"
                              inputProps={{ maxlength: 16 }}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              endAdornment={
                                <Index.InputAdornment
                                  position="end"
                                  className="pass_position"
                                >
                                  <Index.IconButton
                                    className="icon_end_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordShow}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                              label="Password"
                            />
                            {touched.password && errors.password && (
                              <Index.FormHelperText error>
                                {errors.password}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box custom-form-details login-input">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group pass_group_main">
                          <Index.Box className="form-group pass_group_main">
                            <Index.OutlinedInput
                              className="form-control custom_form_control"
                              name="confirmPassword"
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={Boolean(
                                errors.confirmPassword &&
                                  touched.confirmPassword
                              )}
                              inputProps={{ maxlength: 16 }}
                              id="outlined-adornment-password"
                              type={showConfirmPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment
                                  position="end"
                                  className="pass_position"
                                >
                                  <Index.IconButton
                                    className="icon_end_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleConfirmPasswordShow}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                              label="Password"
                            />
                            {touched.confirmPassword &&
                              errors.confirmPassword && (
                                <Index.FormHelperText error>
                                  {errors.confirmPassword}
                                </Index.FormHelperText>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                        <Index.Button
                          className="btn login-btn btn-dark-comman-main"
                          onClick={handleSubmit}
                          type="submit"
                          disableRipple
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
