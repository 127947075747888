import React from "react";
import Index from "../../../../Index";

function WaitlistTotalPurchase() {
  return (
    <>
      <Index.Box className="">
        <Index.Box className="">
          <Index.Box className="page-table-main table-design-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                    >
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                    >
                      USERNAME
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                    >
                      Email
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                    >
                      Mobile Number
                    </Index.TableCell>
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                    >
                      Amount
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                      colSpan={5}
                    >
                      No Record found
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>{" "}
        <Index.Box className="pagination-main">
          {/* <TablePagination
            component="div"
            rowsPerPageOptions={[filterData?.length % 10]}
            count={filterData?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
             labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
          /> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default WaitlistTotalPurchase;
