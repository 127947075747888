import React, { useState, useEffect } from "react";
import Index from "../../Index";
import DepositModal from "../../../container/user/pages/dashboardcontent/depositModal";
import {
  getSettingData,
  userLogout,
} from "../../../redux/features/slices/Admin/AdminSlice";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";

export default function UserHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSetting, setAnchorElSetting] = useState(null);

  const dispatch = Index.useDispatch();
  const naviagte = Index.useNavigate();

  const open = Boolean(anchorEl);
  const settingOpen = Boolean(anchorElSetting);
  const [open1, setOpen1] = useState(false);

  const [qrCodeData, setQRCodeData] = useState("");
  const [totalValues, setTotalValues] = useState();
  const [setting_data, setSetting_Data] = useState({});

  const profileData = Index.useSelector((store) => store?.admin?.userProfile);
  let userDetails = JSON?.parse(window?.atob(profileData));

  const token = Index.useSelector((store) => store?.admin?.loginUserToken);
  let userToken = window?.atob(token);
  console.log(userDetails, ":userDetails");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSettingOpen = (event) => setAnchorElSetting(event.currentTarget);
  const handleSettingClose = () => {
    setAnchorElSetting(null);
  };

  const openBurgerMenu = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.add("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.add("body-overflow");
  };

  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
  };

  // Get setting Data
  const getSettingDetails = async () => {
    await DataService(userToken)
      .get(API.User.GET_SETTING)
      .then((res) => {
        setSetting_Data(res.data.data);
        dispatch(getSettingData(res.data.data));
      })
      .catch((error) => {
        console.log(error, "Error");
        // Index.toast.error(error?.response?.data?.message);
      });
  };

  const getTotaltoken = async () => {
    await DataService(userToken)
      .get(API.User.GET_TOTAL_TOKENS)
      .then((res) => {
        setTotalValues(res.data.data);
      })
      .catch((error) => {
        console.log(error, "Error");
        // Index.toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getSettingDetails();
    getTotaltoken();
  }, []);

  // handle deposit QRCode
  const handleDeposite = (data) => {
    if (
      !setting_data?.tron_address?.is_disable ||
      !setting_data?.usdt_address?.disable
    ) {
      handleOpen1();
    } else {
      Index.toast.error(
        "Due to some technical errors deposit of USDT is not available right now."
      );
    }
  };

  useEffect(() => {
    if (setting_data?.usdt_address?.disable) {
      if (setting_data?.tron_address?.is_disable) {
        setOpen1(false);
      } else {
        setQRCodeData(setting_data?.tron_address?.address_tron);
      }
    } else {
      setQRCodeData(setting_data?.usdt_address?.address);
    }
  }, [setting_data]);

  const handleLogout = () => {
    naviagte("/user/login");
    localStorage.clear();
    dispatch(userLogout());
    Index.toast.success("User logout Successfully");
  };

  return (
    <>
      <Index.Box className="admin-header-main user-header-main">
        <Index.Box>
          <Index.Box className="admin-header-row admin-header-row-icon">
            <Index.Link to="/user/dashboard">
              <Index.Box className="res-logo-icon">
                <img
                  src={Index.Png.logo_new}
                  className="admin-header-logo "
                  alt="logo"
                />
              </Index.Box>
            </Index.Link>
            <Index.Box className="header-right">
              <Index.Box class="marquee-text res-none">
                <marquee> {setting_data?.marquee}</marquee>
              </Index.Box>
              <Index.Box className="res-none">
                <Index.Box className="">
                  <Index.Link
                    class="text-decoration"
                    target="_blank"
                    to="https://portal.testapp.io/apps/install/X3Gye4gjNzXK0"
                  >
                    <Index.Box class="primary-border-btn-main primary-border-btn-main-header   primary-btn-main-comman  ">
                      <Index.Button
                        class=" primary-border-btn history-btn history-btn-header deposit-btn-dark-comman-main "
                        tabindex="0"
                        type="button"
                        disableRipple
                      >
                        Download App<span class=""></span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Link>
                </Index.Box>
              </Index.Box>
              <Index.Box className="res-none">
                <Index.Box className="">
                  <Index.Link class="text-decoration">
                    <Index.Box class="primary-border-btn-main primary-border-btn-main-header  primary-btn-main-comman">
                      <Index.Button
                        onClick={() => {
                          handleDeposite();
                        }}
                        class=" primary-border-btn history-btn history-btn-header deposit-btn-dark-comman-main "
                        tabindex="0"
                        type="button"
                        disableRipple
                      >
                        Add Deposit <span class=""></span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Link>
                </Index.Box>
              </Index.Box>

              {/* Setting */}
              <Index.Box className="admin-header-profile-box ">
                <Index.Button
                  className="mobile-menu-btn display-none"
                  // onClick={openBurgerMenu}
                  id="basic-button"
                  aria-controls={settingOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={settingOpen ? "true" : undefined}
                  onClick={handleSettingOpen}
                  disableRipple
                >
                  <img
                    src={Index.Svg.settingsvg}
                    className="admin-mobile-menu-icon"
                    alt="logo"
                  />
                </Index.Button>
                <Index.Menu
                  className="drop-header-menu"
                  id="basic-menu"
                  anchorEl={anchorElSetting}
                  open={settingOpen}
                  onClose={handleSettingClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Index.Box className="resposive-dropdown">
                    <Index.Link
                      class="text-decoration"
                      to="https://portal.testapp.io/apps/install/X3Gye4gjNzXK0"
                      target="_blank"
                    >
                      <Index.Box class="primary-border-btn-main resposive-dropdown-main">
                        <Index.Button
                          class=" primary-border-btn "
                          tabindex="0"
                          type="button"
                          disableRipple
                        >
                          Download App<span class=""></span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>

                    <Index.Link class="text-decoration">
                      <Index.Box class="primary-border-btn-main resposive-dropdown-main">
                        <Index.Button
                          onClick={() => {
                            handleDeposite();
                          }}
                          class=" primary-border-btn  "
                          tabindex="0"
                          type="button"
                          disableRipple
                        >
                          Add Deposit <span class=""></span>
                        </Index.Button>
                      </Index.Box>
                    </Index.Link>
                  </Index.Box>

                  {/* <Index.Link
                    // to="/user/login"
                    className="text-decoration"
                    onClick={() => handleLogout()}
                  >
                    {" "}
                    <Index.MenuItem className="drop-header-menuitem">
                      {" "}
                      <img src={Index.Png.logout} className="drop-header" />
                      Deposite{" "}
                    </Index.MenuItem> */}
                  {/* </Index.Link> */}
                </Index.Menu>
              </Index.Box>
              <Index.Button
                className="mobile-menu-btn display-none"
                onClick={openBurgerMenu}
              >
                <img
                  src={Index.Svg.mobilemenu}
                  className="admin-mobile-menu-icon setting-iconsvg"
                  alt="logo"
                />
              </Index.Button>

              {/* Profile */}
              <Index.Box className="admin-header-profile-box ">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  disableRipple
                >
                  <Index.Box className="flex-drop-main">
                    {/* <img
                    src={Index.Png.usericon}
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img> */}
                    <Index.Box className="title-admin-drop">
                      <Index.Typography
                        variant="h5"
                        component="h5"
                        className="admin-header-drop"
                      >
                        {userDetails?.first_name}
                      </Index.Typography>
                      <Index.Typography
                        variant="h5"
                        component="h5"
                        className="admin-header-drop"
                      >
                        ({userDetails?.uid})
                      </Index.Typography>
                      <span>
                        {userDetails?.is_verified && (
                          <Index.VerifiedIcon color="primary" />
                        )}
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Button>
                <Index.Menu
                  className="drop-header-menu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Index.Link
                    to={`/user/general-setting`}
                    className="text-decoration"
                  >
                    <Index.MenuItem
                      onClick={handleClose}
                      className="drop-header-menuitem"
                    >
                      {" "}
                      <img src={Index.Png.profile} className="drop-header" />
                      Account Setting
                    </Index.MenuItem>
                  </Index.Link>

                  <Index.Link
                    // to="/user/login"
                    className="text-decoration"
                    onClick={() => handleLogout()}
                  >
                    {" "}
                    <Index.MenuItem className="drop-header-menuitem">
                      {" "}
                      <img src={Index.Png.logout} className="drop-header" />
                      Log Out{" "}
                    </Index.MenuItem>
                  </Index.Link>
                </Index.Menu>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DepositModal handleClose1={handleClose1} />
        </Index.Modal>
      </Index.Box>

      <Index.Box>
        <Index.Box class="marquee-text res-marquee-text">
          <marquee>{setting_data?.marquee}</marquee>
        </Index.Box>
      </Index.Box>
    </>
  );
}
