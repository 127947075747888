import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import EditReferral from "./EditReferral";
import CustomModal from "../../../../component/Modal/CustomModal";

export default function MainReferral() {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // All states
  const [selectedId, setSelectdId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [referralList, setReferralList] = useState([]);

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [showReferralModal, setShowReferralModal] = useState(false);

  const onOpenReferral = (data) => {
    setShowReferralModal(true);
    setSelectdId(data);
  };
  const onCloseReferral = () => {
    setShowReferralModal(false);
    setSelectdId("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getReferralList = async () => {
    await DataService(token)
      .get(API.Admin.WITHOUT_REFERRAL_LIST)
      .then((res) => {
        let temp = [];
        if (res.data.status == 200) {
          setFilterData(res.data.data);
          setReferralList(res.data.data);
          // res.data.data?.map((res) => {
          //   let referralObj = {
          //     username : res?.user_name,
          //     id : res?._id,
          //     referralId : res?.referral_id
          //   }
          //   temp.push(referralObj);
          // })
          // setNoReferralIdList(temp);
        }
      });
  };

  useEffect(() => {
    if (showReferralModal == false) {
      getReferralList();
    }
  }, [showReferralModal]);

  const handleSearch = (event) => {
    const result = referralList.filter((item) => {
      return (
        item?.user_name
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value.toLowerCase()) ||
        item?.email
          ?.toLowerCase()
          ?.toString()
          ?.includes(event?.target?.value?.toLowerCase()) ||
        item?.left_referral?.toString()?.includes(event?.target?.value) ||
        item?.right_referral?.toString()?.includes(event?.target?.value)
      );
    });

    setFilterData(result);
    setPage(0);
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Main Referral
          </Index.Typography>

          <Index.Box className="user-search-main cus-search">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e)}
                />

                <img
                  src={Index.Svg.greysearch}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main waitlist-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Created Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USERNAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Email
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Left Referral
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Right Referral
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => {
                                return (
                                  <Index.TableRow
                                    key={row?.user_name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt)
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm A")
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.user_name}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Link>
                                          {" "}
                                          {row?.user_name
                                            ? row?.user_name
                                            : "-"}
                                        </Index.Link>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        title={row?.email}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="email-ellip">
                                          <Index.Link>
                                            {" "}
                                            {row?.email ? row?.email : "-"}
                                          </Index.Link>
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip title="Copy">
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                        >
                                          {Index.ReplaceCharsWithAsterisks(
                                            row?.left_referral
                                          )}{" "}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip title="Copy">
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                        >
                                          {Index.ReplaceCharsWithAsterisks(
                                            row?.right_referral
                                          )}{" "}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.IconButton
                                        onClick={(e) =>
                                          onOpenReferral(row?._id)
                                        }
                                      >
                                        <img src={Index.Svg.blueEdit}></img>
                                      </Index.IconButton>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={6}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <CustomModal
        modalOpen={showReferralModal}
        onOpenModal={onOpenReferral}
        onCloseModal={onCloseReferral}
      >
        <Index.Box p={2}>
          <EditReferral userId={selectedId} onCloseReferral={onCloseReferral} />
        </Index.Box>
      </CustomModal>
    </div>
  );
}
