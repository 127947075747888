import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { DataService } from "../../config/DataService";
import { API } from "../../config/Api";
import {
  adminLogout,
  adminProfile,
} from "../../redux/features/slices/Admin/AdminSlice";
import { toast } from "react-toastify";
import Chatboat from "../../component/common/chatboat";

const AdminPrivateRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isValidToken = (accessToken) => {
    if (!accessToken) return false;
    return true;
  };

  useEffect(() => {
    DataService(token)
      .get(API.Admin.GET_ADMIN_PROFILE)
      .then((res) => {
        dispatch(adminProfile(res?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.data?.status == 401) {
          toast.error(error?.response?.data?.message);
          dispatch(adminLogout());

          navigate("/admin/login");
        }
      });
  }, []);

  const isAdminLogin = useSelector((state) => state.admin.adminProfile);
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  return (
    <>
      {isAdminLogin && isValidToken(token) ? (
        <Outlet />
      ) : (
        <Navigate to="/admin/login" />
      )}
    </>
  );
};

export default AdminPrivateRoutes;
