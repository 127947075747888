// New Import
import * as Yup from "yup";

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
      "Please enter valid email"
    )
    .required("Please enter an email"),
  password: Yup.string().required("Please enter a password"),
});

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
      "Please enter valid email"
    )
    .required("Please enter an email"),
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/,
      "Please enter valid password (Ex. Jhon@123)"
    )
    .required("Please enter a password"),
  confirmPassword: Yup.string()
    .required("Please enter a Confirm password")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    ),
});

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Please enter a old password")
    .matches(
      /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/,
      "Please enter valid old password"
    ),
  newPassword: Yup.string()
    .required("Please enter a new password")
    .matches(
      /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/,
      "Please Enter valid new password (Ex. Jhon@123)"
    ),
  confirmNewPassword: Yup.string()
    .required("Please enter a confirm password")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New confirm password not matched with New password"
    ),
});
export { LoginValidationSchema, ForgotPasswordSchema, ResetPasswordSchema , changePasswordSchema };
