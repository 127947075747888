import React, { useState } from "react";
import Index from "../../Index";

export default function Sidebar({ drawerOpen, setDrawerOpen }) {
  const pathname = Index.useLocation();

  const [openCMS, setOpenCMS] = useState(false);

  const removeClass = () => {
    setDrawerOpen((e) => !e);
    document.body.classList[drawerOpen ? "add" : "remove"]("body-overflow");
    document
      .getElementById("admin-sidebar")
      .classList[drawerOpen ? "add" : "remove"]("active");
  };

  const handleCMS = () => {
    setOpenCMS(!openCMS);
  };

  return (
    <>
      <Index.Box className="admin-sidebar-main scrollbar" id={"admin-sidebar"}>
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Link to="/admin/dashboard" className="redirect-home-link">
            <Index.Box className="admin-sidebar-logo-main admin-side-logo-text">
              <img
                src={Index.Png.logo_new}
                className="admin-sidebar-logo"
                alt="logo"
              />
              <Index.Typography
                variant="span"
                component="span"
                class="logo-text"
              >
                B-4 Hit Coin
              </Index.Typography>
              <Index.Box className="cross-res">
                <Index.Button onClick={removeClass}>
                  {" "}
                  <img
                    src={Index.Png.cancel}
                    className="close-icon-modal"
                    alt="cross"
                  />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Link>
          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/dashboard"
                  className={
                    pathname?.pathname === "/admin/dashboard"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.dashboard}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Dashboard
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/presale-round"
                  className={
                    pathname?.pathname === "/admin/presale-round"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.presaleadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Presale Management
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/waitlist"
                  className={
                    pathname?.pathname === "/admin/waitlist"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Waitlist User
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/main-referral-list"
                  className={
                    pathname?.pathname === "/admin/main-referral-list"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Main Referral List
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/transaction"
                  className={
                    pathname?.pathname === "/admin/transaction"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Transaction List
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/kycDetail"
                  className={
                    pathname?.pathname === "/admin/kycDetail"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  KYC List
                </Index.Link>
              </Index.ListItem>
              {/* 
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/stack"
                  className={
                    pathname?.pathname === "/admin/stack"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Stake
                </Index.Link>
              </Index.ListItem> */}

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/withdraw-request-list"
                  className={
                    pathname?.pathname === "/admin/withdraw-request-list"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Withdraw Request List
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/token-category"
                  className={
                    pathname?.pathname === "/admin/token-category"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.waitlistadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Token Category
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/setting"
                  className={
                    pathname?.pathname === "/admin/setting"
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={() => {
                    window.innerWidth <= 768 && removeClass();
                  }}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.settingadmin}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  Setting
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                onClick={handleCMS}
              >
                <Index.Link className="admin-sidebar-link">
                  <Index.Box className="align-center">
                    <img
                      src={Index.Svg.changepassword}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    <Index.Typography variant="span" component="span">
                      CMS
                    </Index.Typography>
                  </Index.Box>

                  {openCMS ? (
                    <Index.ExpandMore className="expandmore-icon" />
                  ) : (
                    <Index.ExpandLess className="expandless-icon" />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openCMS}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/privacy-policy"
                            className={`${
                              pathname.pathname == "/admin/cms/privacy-policy"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                            onClick={() => {
                              window.innerWidth <= 768 && removeClass();
                            }}
                          >
                            Privacy Policy
                          </Index.Link>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/terms-and-condition"
                            className={`${
                              pathname.pathname ==
                              "/admin/cms/terms-and-condition"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                            onClick={() => {
                              window.innerWidth <= 768 && removeClass();
                            }}
                          >
                            Terms and Condition
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
