import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";

import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { getCMSDetails } from "../../../../redux/features/slices/Admin/AdminSlice";
import { toast, ToastContainer } from "react-toastify";

export default function TermsAndCondition() {
  const dispatch = useDispatch();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);
  const cmsData = useSelector((store) => store.admin.cmsDetails);

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const validate = () => {
    if (description == "") {
      setDescriptionError("Please Enter Description");
    } else {
      setDescriptionError("");
    }
  };

  useEffect(() => {
    if (cmsData) {
      setDescription(
        cmsData?.termsAndConditionDescription
          ? cmsData?.termsAndConditionDescription
          : ""
      );
    }
  }, [cmsData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setDescriptionError(
      Index.validateInpField(description, "Please Enter Description")
    );

    if (description) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("termsAndConditionDescription", description?.trim());
      DataService(token)
        .post(API.Admin.ADD_EDIT_CMS, urlencoded)
        .then((res) => {
          dispatch(getCMSDetails(res.data.data));
          toast.success("Terms & condition Updated");
        })
        .catch((error) => {
          Index.toast.error(error?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="custom-card">
          <Index.Box className="user-list-flex mt-10">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h2"
              variant="h2"
            >
              Terms and Condition
            </Index.Typography>
          </Index.Box>
          <Index.Box>
            <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Description
              </Index.FormHelperText>
              <Index.Box className="set-ck-editor">
                <Index.CKEditor
                  editor={Index.ClassicEditor}
                  data={description ? description : ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDescription(data);
                    setDescriptionError(
                      Index.validateInpField(data, "Please Enter Description")
                    );
                  }}
                />
              </Index.Box>

              <Index.ErrorShow error={descriptionError} />
            </Index.Box>
            <Index.Box
              className="submit-btn-main border-btn-main btn-main login-btn-main primary-btn-main-comman "
              mt={2}
            >
              <Index.Button
                className="submit-user-btn border-btn add-user-btn  btn-dark-comman-main "
                disableRipple
                type="button"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Update
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
