import React, { useState } from "react";
import Index from "../../Index";
import { adminLogout } from "../../../redux/features/slices/Admin/AdminSlice";

export default function Header({ drawerOpen, setDrawerOpen }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const profile = Index.useSelector((store) => store.admin.adminProfile);
  let adminProfile = JSON.parse(window.atob(profile));

  const open = Boolean(anchorEl);
  const dispatch = Index.useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openBurgerMenu = () => {
    // var element = document.getElementById("admin-sidebar");
    // element.classList.add("active");
    // var body = document.getElementsByTagName("body");
    // body[0].classList.add("body-overflow");
    setDrawerOpen((e) => !e);
    document.body.classList[drawerOpen ? "add" : "remove"]("body-overflow");
    document
      .getElementById("admin-sidebar")
      .classList[drawerOpen ? "add" : "remove"]("active");
  };
  return (
    <>
      <Index.Box className="admin-header-main res-admin-header">
        <Index.Link to="/admin/dashboard" className="redirect-home-link">
          <Index.Box className="admin-sidebar-logo-main res-admin-logo">
            <img
              src={Index.Png.logo_new}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Typography variant="span" component="span" class="logo-text">
              B-4 Hit Coin
            </Index.Typography>
          </Index.Box>
        </Index.Link>
        <Index.Box className="admin-header-row">
          <Index.Button className="mobile-menu-btn " onClick={openBurgerMenu}>
            <img
              src={Index.Svg.mobilemenu}
              className="admin-mobile-menu-icon"
              alt="logo"
            />
          </Index.Button>

          <Index.Box className="admin-header-profile-box">
            <Index.Button
              className="drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              disableRipple
            >
              <Index.Box
                className={`flex-drop-main ${drawerOpen ? "remove" : ""}`}
              >
                <img
                  src={
                    adminProfile?.profile_image
                      ? `${process.env.REACT_APP_IMAGE_URL}/${adminProfile?.profile_image}`
                      : Index.Png.logo_new.png
                  }
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    {adminProfile?.name}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.Link to="/admin/edit-profile" className="text-decoration">
                <Index.MenuItem
                  onClick={handleClose}
                  className="drop-header-menuitem"
                >
                  {" "}
                  <img src={Index.Png.profile} className="drop-header" />
                  Edit Profile{" "}
                </Index.MenuItem>
              </Index.Link>

              <Index.Link to="/admin/login" className="text-decoration">
                {" "}
                <Index.MenuItem
                  onClick={() => {
                    handleClose();
                    dispatch(adminLogout());
                  }}
                  className="drop-header-menuitem"
                >
                  {" "}
                  <img src={Index.Png.logout} className="drop-header" />
                  Log Out{" "}
                </Index.MenuItem>
              </Index.Link>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
