import usericon from "./images/png/user.png";
// import logo from './images/png/logo.png';
import edit from "./images/png/edit.png";
import logout from "./images/png/logout.png";
import setting from "./images/png/setting.png";
import profile from "./images/png/profile.png";
import footerplaystorescanner from "./images/png/footer-playstore-scanner.png";
import lightLogo from "./images/png/logo.png";
// import herobg from '../assets/images/png/hero-bg.png'

import joyfulbg from "./images/png/joyful-bg.png";
import roadmapbg from "./images/png/roadmapbg.png";
import refrralmobile from "./images/png/refrralmobile.png";
import footerbg from "./images/png/footerbg.png";
import loginbg from "./images/png/loginbg.png";
import signupbg from "./images/png/signupbg.png";
import menuburger from "./images/png/menu-burger.png";
import herobg from "./images/png/1239296.png";
import logo_new from "./images/png/logo_new.png";
import bgimg from "./images/png/bgimg.png";
import closeicon from "./images/png/closeicon.png";

import cancel from "./images/png/cancel.png";
import upload from "./images/png/upload.png";
import close from "./images/png/close.png";

const Png = {
  logo_new,
  usericon,
  lightLogo,
  // logo,
  herobg,
  joyfulbg,
  roadmapbg,
  refrralmobile,
  footerbg,
  loginbg,
  signupbg,
  menuburger,
  edit,
  setting,
  logout,
  profile,
  footerplaystorescanner,
  bgimg,
  closeicon,
  cancel,
  upload,
  close,
};

export default Png;
