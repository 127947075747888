import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";

const menuItems = (arr) => {
  return arr.map((item) => {
    return (
      <Index.MenuItem itemID={item?.id} value={item.id}>
        {item.username}
      </Index.MenuItem>
    );
  });
};

const EditReferral = ({ userId, onCloseReferral }) => {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [allUser, setAllUser] = useState([]);
  const [inpval, setInpVal] = useState({
    referral_type: "",
    mainReferral: "",
  });

  const [typeError, setTypeError] = useState("");
  const [mainReferralIdError, setMainReferralIdError] = useState("");

  const getWaitlist = async () => {
    await DataService(token)
      .get(API.Admin.GET_WAITLIST)
      .then((res) => {
        let temp = [];

        res.data.data?.map((item) => {
          let obj = {
            username: item?.user_id?.user_name,
            id: item?.user_id?._id,
          };
          temp.push(obj);
        });
        setAllUser(temp);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    setMainReferralIdError("");
    setTypeError("");
    setInpVal({
      referral_type: "",
      mainReferral: "",
    });
    getWaitlist();
  }, [userId]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInpVal({ ...inpval, [name]: value });

    if (name == "referral_type") {
      setMainReferralIdError("");
    }
    if (name == "mainReferral") {
      setTypeError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inpval?.mainReferral && inpval?.referral_type) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("_id", userId);
      urlencoded.append("main_referral_id", inpval?.mainReferral);
      urlencoded.append("type_referral", inpval?.referral_type);
      await DataService(token)
        .post(API.Admin.UPDATE_MAIN_ID, urlencoded)
        .then((res) => {
          if (res.data.status == 200) {
            toast.success(res.data.message);
            onCloseReferral();
            setMainReferralIdError("");
            setTypeError("");
          }
        });
    } else {
      if (!inpval?.mainReferral) {
        setMainReferralIdError("Please select main referral id");
      }
      if (!inpval?.referral_type) {
        setTypeError("Please select referral type");
      }
    }
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="add-user-data-main">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Add Main Referral
            </Index.Typography>
          </Index.Box>
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              {/* <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Referral Id
                  </Index.FormHelperText>
                  <Index.Box className="waitlist-drop mr-15">
                    <Index.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                    >
                    
                    </Index.Select>
                  </Index.Box>
                </Index.Box>
              </Index.Box> */}

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Referral Type
                  </Index.FormHelperText>
                  <Index.Box className="waitlist-drop mr-15">
                    <Index.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      name="referral_type"
                      value={inpval?.referral_type}
                      onChange={(e) => handleInput(e)}
                    >
                      <Index.MenuItem value={"left"}>Left</Index.MenuItem>
                      <Index.MenuItem value={"right"}>Right</Index.MenuItem>
                    </Index.Select>
                  </Index.Box>
                  <p className="error-text">{typeError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Main Referral Id
                  </Index.FormHelperText>
                  <Index.Box className="waitlist-drop mr-15">
                    <Index.Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="mainReferral"
                      value={inpval?.mainReferral}
                      onChange={(e) => handleInput(e)}
                    >
                      {menuItems(allUser)}
                    </Index.Select>
                  </Index.Box>
                  <p className="error-text">{mainReferralIdError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-btn-flex support-btn-flex comman-btn-main">
                  <Index.Box className="discard-btn-main border-btn-main"></Index.Box>
                  <Index.Box className="save-btn-main ">
                    <Index.Button
                      className="save-user-btn primary-btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      <img
                        src={Index.Svg.save}
                        alt="save"
                        className="user-save-icon"
                      ></img>
                      Update
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default EditReferral;
