import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export default function TokenCategoryList() {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const navigate = Index.useNavigate();

  // All states
  const [active, setActive] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [tokenCategoryList, setTokenCategoryList] = useState([]);

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getCategoryTokenList = async () => {
    await DataService(token)
      .get(API.Admin.GET_CATEGORY)
      .then((res) => {
        if (res.data.status == 200) {
          setFilterData(res.data.data);
          setTokenCategoryList(res.data.data);
          // res.data.data?.map((res) => {
          //   let referralObj = {
          //     username : res?.user_name,
          //     id : res?._id,
          //     referralId : res?.referral_id
          //   }
          //   temp.push(referralObj);
          // })
          // setNoReferralIdList(temp);
        }
      });
  };

  useEffect(() => {
    getCategoryTokenList();
  }, []);

  const handleSearch = (event) => {
    const result = tokenCategoryList.filter((item) => {
      let tokenName = item?.name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value?.toLowerCase());

      let marketCap = item?.market_cap
        ?.toString()
        ?.includes(event?.target?.value);

      return tokenName || marketCap;
    });
    setFilterData(result);
    setPage(0);
  };

  const CategoryDisable = async (data) => {
    const formData = new URLSearchParams();
    formData.append("id", data?.id);
    formData.append("action", data?.status);
    await DataService(token)
      .post(API.Admin.ENABLE_DISABLE_CATEGORY, formData)
      .then((response) => {
        if (response.data.status == 200) {
          Index.toast.success(response.data.message);
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
    getCategoryTokenList();
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Token Category List
          </Index.Typography>
          <Index.Box className="flex-btn">
            <Index.Box className="user-search-main cus-search">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main waitlist-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Created Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Market cap ($)
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt)
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm A")
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.name ? row?.name : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.market_cap
                                        ? row?.market_cap?.toFixed(2)
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="right"
                                    >
                                      <Index.IconButton>
                                        <Index.FormControlLabel
                                          control={
                                            <Index.IOSSwitch
                                              checked={row?.is_active}
                                              sx={{ m: 1 }}
                                              className="MuiSwitch-track-active"
                                            />
                                          }
                                          onClick={() => {
                                            const temp = {
                                              id: row?.id,
                                              status: !row?.is_active,
                                            };
                                            CategoryDisable(temp);
                                            setActive(!active);
                                          }}
                                          className="switch-lable"
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          navigate(
                                            `/admin/token-category/${row?._id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.view}
                                          className="view-icon"
                                        ></img>
                                      </Index.IconButton>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={8}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
