import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Index from "../../Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import Chatboat from "../../../component/common/chatboat";

export default function UserResetPassword() {
  const navigate = Index.useNavigate();
  const location = Index.useLocation();

  let initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,16}$/,
        "Please enter valid password (Ex. Jhon@123)"
      )
      .required("Please enter a password"),
    confirmPassword: Yup.string()
      .required("Please enter a Confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword((show1) => !show1);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("otp", location?.state?.otp?.trim());
    urlencoded.append("newPassword", values.password?.trim());
    await DataService()
      .post(API.User.USER_RESET_PASSWORD, urlencoded)
      .then((res) => {
        if (res?.data?.status == 200) {
          Index.toast.success(res?.data?.message);
          setTimeout(() => {
            navigate("/user/login");
          }, 2000);
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="login-main-content">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content f-direction">
                  <Index.Box className="login-logo-details logo-anim position-relative">
                    <img
                      src={Index.Svg.middleround}
                      className="roundmainimg2"
                    />
                    <img src={Index.Svg.smallround} className="roundmainimg3" />

                    <Index.Box className="coin-image">
                      <img
                        src={Index.Png.logo_new}
                        className="logo-login"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="login-logo-title">
                    B-4 Hit Coin
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column  "
              >
                <Index.Box className="login-form-details">
                  <Index.Box className="login-pd-main res-login-column ">
                    <Index.Box className="login-max-content">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          Reset Password
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Please enter your New Password!
                        </Index.Typography>
                      </Index.Box>

                      <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                              className="login-grid-inner"
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText className="form-label">
                                      Enter Password{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Index.FormHelperText>
                                    <Index.Box className="input-details-main">
                                      <Index.Box className="form-group pass_group_main">
                                        <Index.OutlinedInput
                                          className="form-control custom_form_control"
                                          id="outlined-adornment-password"
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          name="password"
                                          onBlur={handleBlur}
                                          value={values.password}
                                          onChange={handleChange}
                                          helperText={
                                            touched.password && errors.password
                                          }
                                          error={Boolean(
                                            errors.password && touched.password
                                          )}
                                          inputProps={{ maxlength: 16 }}
                                          endAdornment={
                                            <Index.InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <Index.IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <Index.VisibilityOff />
                                                ) : (
                                                  <Index.Visibility />
                                                )}
                                              </Index.IconButton>
                                            </Index.InputAdornment>
                                          }
                                          label="Password"
                                        />
                                      </Index.Box>
                                      {touched.password && errors.password && (
                                        <Index.FormHelperText error>
                                          {errors.password}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.FormHelperText className="form-lable">
                                    Confirm Password{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group pass_group_main">
                                    <Index.Box className="form-group pass_group_main">
                                      <Index.OutlinedInput
                                        className="form-control custom_form_control"
                                        name="confirmPassword"
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        helperText={
                                          touched.confirmPassword &&
                                          errors.confirmPassword
                                        }
                                        error={Boolean(
                                          errors.confirmPassword &&
                                            touched.confirmPassword
                                        )}
                                        inputProps={{ maxlength: 16 }}
                                        id="outlined-adornment-password"
                                        type={
                                          showConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        endAdornment={
                                          <Index.InputAdornment
                                            position="end"
                                            className="pass_position"
                                          >
                                            <Index.IconButton
                                              className="icon_end_icon"
                                              aria-label="toggle password visibility"
                                              onClick={
                                                handleShowConfirmPassword
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showConfirmPassword ? (
                                                <Index.VisibilityOff />
                                              ) : (
                                                <Index.Visibility />
                                              )}
                                            </Index.IconButton>
                                          </Index.InputAdornment>
                                        }
                                        label="Password"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                  {touched.confirmPassword &&
                                    errors.confirmPassword && (
                                      <Index.FormHelperText error>
                                        {errors.confirmPassword}
                                      </Index.FormHelperText>
                                    )}
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="">
                                  <Index.Link
                                    // to={"/user"}
                                    className="signin-btn-main primary-btn-main-link-user"
                                  >
                                    <Index.Button
                                      type="submit"
                                      variant="contained"
                                      className="comman-primary-btn "
                                      onClick={handleSubmit}
                                    >
                                      {" "}
                                      Reset
                                    </Index.Button>
                                  </Index.Link>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Chatboat />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
