import axios from "axios";

// const API_ENDPOINT = "http://35.177.56.74:3029/api/";
// const API_ENDPOINT = "https://presale.b4hit.io/api/";
// const API_ENDPOINT = "http://localhost:3025/api/";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const DataService = (token) => {
  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      auth: token ? token : "",
    },
  });
};
