import React from "react";
import { useLocation } from "react-router-dom";
import Index from "../../Index";

export default function UserSidebar() {
  const pathname = useLocation();
  const removeClass = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.remove("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.remove("body-overflow");
  };
  return (
    <>
      <Index.Box
        className="admin-sidebar-main scrollbar user-sidebar-main"
        id={"admin-sidebar"}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Link to="/user/dashboard" className="redirect-home-link">
            <Index.Box className="admin-sidebar-logo-main user-sidebar-logo-main">
              <Index.Box className="res-set-cross-icon set-logo-text-header hit-token-box">
                <img
                  src={Index.Png.logo_new}
                  className="admin-sidebar-logo user-sidebar-logo"
                  alt="logo"
                />
                <Index.Box className="res-logo-text">
                  <Index.Typography
                    variant="span"
                    component="span"
                    class="logo-text"
                  >
                    B-4 Hit Coin
                  </Index.Typography>
                </Index.Box>
                <Index.Link className="icon-setclose" onClick={removeClass}>
                  <img
                    src={Index.Png.cancel}
                    className="cancel-btn"
                    alt="dashboard icon"
                  />
                </Index.Link>
              </Index.Box>
              {/* <Index.Button onClick={removeClass}>
              <img
                src={Index.Svg.whiteclose}
                className="admin-sidebar-close"
                alt="logo"
              />
            </Index.Button> */}
            </Index.Box>
          </Index.Link>
          <Index.Box className="admin-sidebar-list-main admin-sidebar-list-main-user">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/dashboard"
                  className={
                    pathname?.pathname === "/user/dashboard"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                    {/* <img
                      src={Index.Svg.dashboard}
                      className="admin-sidebar-icon"
                    /> */}
                  </Index.Box>
                  Dashboard
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/referral-details"
                  className={
                    pathname?.pathname === "/user/referral-details" ||
                    pathname?.pathname === "/user/mytree"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  My Referral
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/referral-user-list"
                  className={
                    pathname?.pathname === "/user/referral-user-list" ||
                    pathname?.pathname === "/user/referral-user-list"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  Referral Users
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/mytransaction"
                  className={
                    pathname?.pathname === "/user/mytransaction" ||
                    pathname?.pathname === "/user/mytransaction"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  My Transaction
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/kyc"
                  className={
                    pathname?.pathname === "/user/kyc" ||
                    pathname?.pathname === "/user/kyc"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  KYC
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/stak"
                  className={
                    pathname?.pathname === "/user/stak" ||
                    pathname?.pathname === "/user/stak"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  Stake
                </Index.Link>
              </Index.ListItem>
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/myIncome"
                  className={
                    pathname?.pathname === "/user/myIncome" ||
                    pathname?.pathname === "/user/myIncome"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  My Income
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/user/withdraw"
                  className={
                    pathname?.pathname === "/user/withdraw" ||
                    pathname?.pathname === "/user/withdraw"
                      ? "admin-sidebar-link user-sidebar-link active"
                      : "dashboard-header user-dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons"></Index.Box>
                  Withdraw List
                </Index.Link>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
