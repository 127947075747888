import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { LoginAdmin } from "../../../redux/features/slices/Admin/AdminService";
import { adminProfile } from "../../../redux/features/slices/Admin/AdminSlice";
import { Formik, Form } from "formik";
import { LoginValidationSchema } from "../../../validations/ValidationSchema";

export default function Login() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const hasVisited = localStorage.getItem("visitedHomePage");

  let initialValues = {
    email: "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [visitedHomePage, setVisitedHomePage] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => event.preventDefault();

  // Check if the user has visited before
  useEffect(() => {
    if (hasVisited) {
      setVisitedHomePage(true);
    }
  }, [hasVisited]);

  const handleFormSubmit = async (values) => {
    const formData = new URLSearchParams();
    formData.append("email", values?.email?.trim());
    formData.append("password", values?.password?.trim());
    dispatch(LoginAdmin(formData))
      .then((response) => {
        dispatch(adminProfile(response?.payload?.data));
        if (response.payload) {
          localStorage.setItem("visitedHomePage", true);
          Index.toast.success("Admin logged in successfully");
          setTimeout(() => {
            navigate("/admin/dashboard");
          }, 2000);
        } else {
          Index.toast.error("Invalid Credentails.");
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Png.logo_new}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>

              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={LoginValidationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form>
                    <Index.Box className="card-login-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        {visitedHomePage ? "Welcome back!" : "Welcome"}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your credentials to Log in!
                      </Index.Typography>
                      <Index.Box className="input-box input-box-admin">
                        <Index.FormHelperText className="form-lable">
                          Email <span style={{ color: "red" }}>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            name="email"
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                            className="form-control"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box input-box-admin">
                        <Index.FormHelperText className="form-lable">
                          Password <span style={{ color: "red" }}>*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group pass_group_main">
                          <Index.Box className="form-group pass_group_main">
                            <Index.OutlinedInput
                              className="form-control custom_form_control"
                              id="outlined-adornment-password"
                              name="password"
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              inputProps={{ maxlength: 16 }}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment
                                  position="end"
                                  className="pass_position"
                                >
                                  <Index.IconButton
                                    className="icon_end_icon"
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                              label="Password"
                            />
                            {touched.password && errors.password && (
                              <Index.FormHelperText error>
                                {errors.password}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main">
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Remember Me"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="admin-forgot-para common-para"
                        >
                          <Index.Link
                            className="signup-redirect"
                            to="/admin/forgot-password"
                          >
                            Forgot Password ?
                          </Index.Link>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                        <Index.Button
                          className="btn login-btn btn-dark-comman-main"
                          onClick={handleSubmit}
                          type="submit"
                          disableRipple
                        >
                          Login
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
