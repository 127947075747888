import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import PhotoIcon from "@mui/icons-material/Photo";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import Index from "../../../Index";
import CustomModal from "../../../../component/Modal/CustomModal";
import KycResponse from "./KycResponse";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Kyc() {
  const details = Index.useSelector((store) => store.admin.userDetail);
  let userDetails = JSON.parse(window.atob(details));
  const token = Index.useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  // Hide show States
  const [showFrontDoc, setShowFrontDoc] = useState(false); // For Front Address Proof Show
  const [showBackDoc, setShowBackDoc] = useState(false); // For Back Address Proof Show
  const [showPhotoId, setShowPhotoId] = useState(false);
  // const [showPancard, setShowPancard] = useState(false);
  const [showImg, setShowImg] = useState({});
  const [kycData, setKycData] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // End Hide show states

  const [photo, setPhoto] = useState({
    img: "",
    imgUrl: "",
  });

  // const [pancard, setPancard] = useState({
  //   img: "",
  //   imgUrl: "",
  // });

  const [addressProof, setAddressProof] = useState({
    frontSide: { img: "", imgUrl: "" },
    backSide: { img: "", imgUrl: "" },
  });

  const [docDetails, setDocDetails] = useState({
    photoIdName: "",
    pancardNo: "",
    AddressProof: "",
  });

  // SetDefault Value while Edit

  const getKycDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", userDetails?._id);
    await DataService(userToken)
      .post(API.User.KYC_DETAILS, urlencoded)
      .then((res) => {
        setIsSubmit(true);
        if (res.data.status == 200) {
          setKycData(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getKycDetails();
  }, []);

  useEffect(() => {
    if (kycData) {
      if (kycData?.status == "Reject") {
        setIsSubmit(false);
      }

      let docDetials = {
        photoIdName: kycData?.name,
        // pancardNo: kycData?.pancard_number,
        AddressProof: kycData?.address_proof,
      };

      let photoDetails = {
        img: kycData?.photo_id_image,
        imgUrl: `${process.env.REACT_APP_IMAGE_URL}/${kycData?.photo_id_image}`,
      };

      // let pancardDetails = {
      //   img: kycData?.pancard_image,
      //   imgUrl: `${process.env.REACT_APP_IMAGE_URL}/${kycData?.pancard_image}`,
      // };

      let frontSide = {
        img: kycData?.address_front,
        imgUrl: `${process.env.REACT_APP_IMAGE_URL}/${kycData?.address_front}`,
      };
      let backSide = {
        img: kycData?.address_back,
        imgUrl: `${process.env.REACT_APP_IMAGE_URL}/${kycData?.address_back}`,
      };
      setPhoto(photoDetails);
      // setPancard(pancardDetails);
      setDocDetails(docDetials);
      setShowFrontDoc(kycData?.address_front ? true : false);
      setShowBackDoc(kycData?.address_back ? true : false);
      setShowPhotoId(kycData?.photo_id_image ? true : false);
      // setShowPancard(kycData?.pancard_image ? true : false);
      setAddressProof({ frontSide, backSide });
    }
  }, [kycData]);
  // HanldeImage

  const handleImage = (e) => {
    if (e?.target?.files[0]) {
      let url = URL.createObjectURL(e?.target?.files[0]);
      let obj = {
        img: e.target.files[0],
        imgUrl: url,
      };
      setPhoto(obj);
    }
  };

  // const handlePancard = (e) => {
  //   let url = URL.createObjectURL(e.target.files[0]);
  //   let obj = {
  //     img: e.target.files[0],
  //     imgUrl: url,
  //   };
  //   setPancard(obj);
  // };

  const handleAddressProof = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    let obj = {
      img: e.target.files[0],
      imgUrl: url,
    };
    setAddressProof({ ...addressProof, [e.target.name]: obj });
  };

  // Handle Inputs

  const handleDocInputs = (e) => {
    const { name, value } = e.target;
    setDocDetails({ ...docDetails, [name]: value });
  };

  // Handle Submit

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      photo?.imgUrl &&
      addressProof?.frontSide?.imgUrl &&
      addressProof?.backSide?.imgUrl &&
      docDetails?.AddressProof &&
      docDetails?.photoIdName
    ) {
      const formData = new FormData();
      formData.append("user_id", userDetails?._id);
      formData.append("photo", photo?.img);
      formData.append("name", docDetails?.photoIdName);
      // formData.append("pancard", pancard?.img);
      // formData.append("pancard_number", docDetails?.pancardNo);
      formData.append("addr_proof", docDetails?.AddressProof);
      formData.append("addr_front", addressProof?.frontSide?.img);
      formData.append("addr_back", addressProof?.backSide?.img);

      await DataService(userToken)
        .post(API.User.ADD_EDIT_KYC, formData)
        .then((res) => {
          Index.toast.success(
            "Your Request Has been sent to Admin. Once its Approved wee will notify you"
          );
          setIsSubmit(true);
        })
        .catch((error) => {
          Index.toast.error(error?.response?.data?.message);
        });
    } else {
      Index.toast.error("Please Fill The Data");
    }
  };

  return (
    <>
      {isSubmit ? (
        <KycResponse />
      ) : (
        <Index.Box className="setting-card kyc-card-hgt">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              KYC
            </Index.Typography>
          </Index.Box>

          <Index.Box className="kyc-user-list">
            <Index.Box className="kyc-row-details">
              <Index.Box className="row">
                <Index.Box sx={{ width: 1 }} className="grid-main kyc-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    className="kyc-row-main"
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="kyc-input-details">
                          <Index.Box className="edit_profile_field">
                            <Index.Box className="kyc-input-details">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="form-lable">
                                  Fullname As per upload ID
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  name="photoIdName"
                                  value={docDetails.photoIdName}
                                  className="form-control"
                                  placeholder=" Fullname as per photoId"
                                  onChange={(e) => handleDocInputs(e)}
                                />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="form-group from_login_details">
                              <Index.FormHelperText className="form-lable">
                                Photo Id Upload
                              </Index.FormHelperText>
                              {!showPhotoId ? (
                                <Index.Box className="title-name-upload">
                                  <Index.Button
                                    variant="contained"
                                    component="label"
                                    className="upload-sign-btn"
                                    disableRipple
                                  >
                                    <img
                                      src={Index.Png.upload}
                                      class="upload image"
                                    />
                                    <span>Photo Id Upload</span>
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      name="frontSide"
                                      className="inputfile-upload"
                                      onChange={(e) => {
                                        handleImage(e);
                                        setShowPhotoId(true);
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>
                              ) : (
                                <Index.Box className="upload-img-content">
                                  <img
                                    src={photo?.imgUrl && photo?.imgUrl}
                                    alt="front"
                                    className="upload-kyc-img"
                                  />
                                  <Index.Box
                                    className="close-kyc-main"
                                    onClick={(e) => setShowPhotoId(false)}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-kyc-icon"
                                      alt="icon"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box>

                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="kyc-input-details">
                          <Index.Box className="edit_profile_field">
                            <Index.Box className="form-group">
                              <Index.FormHelperText className="form-lable">
                                Pan Number
                              </Index.FormHelperText>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                name="pancardNo"
                                className="form-control"
                                inputProps={{ maxLength: 10 }}
                                placeholder=" Pan Number"
                                value={docDetails.pancardNo}
                                onChange={(e) => handleDocInputs(e)}
                              />
                            </Index.Box>

                            <Index.Box className="form-group from_login_details">
                              <Index.FormHelperText className="form-lable">
                                Pancard Upload
                              </Index.FormHelperText>
                              {!showPancard ? (
                                <Index.Box className="title-name-upload">
                                  <Index.Button
                                    variant="contained"
                                    component="label"
                                    className="upload-sign-btn"
                                    disableRipple
                                  >
                                    <img
                                      src={Index.Png.upload}
                                      class="upload image"
                                    />
                                    <span>Pancard Upload</span>
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      className="inputfile-upload"
                                      name="backSide"
                                      onChange={(e) => {
                                        handlePancard(e);
                                        setShowPancard(true);
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>
                              ) : (
                                <Index.Box className="upload-img-content">
                                  <img
                                    src={pancard?.imgUrl && pancard?.imgUrl}
                                    alt="front"
                                    className="upload-kyc-img"
                                  />
                                  <Index.Box
                                    className="close-kyc-main"
                                    onClick={(e) => setShowPancard(false)}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-kyc-icon"
                                      alt="icon"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box> */}

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 12",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="kyc-input-details">
                          <Index.Box className="edit_profile_field">
                            <Index.Box className="form-group from_login_details">
                              <Index.FormHelperText className="form-lable">
                                Address Proof
                              </Index.FormHelperText>

                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control">
                                  <Index.Select
                                    className="dropdown-select "
                                    name="AddressProof"
                                    value={docDetails?.AddressProof}
                                    onChange={(e) => handleDocInputs(e)}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <Index.MenuItem
                                      value="Aadhar Card"
                                      className="menuitem"
                                    >
                                      Aadhar Card
                                    </Index.MenuItem>

                                    <Index.MenuItem
                                      value={"Passport"}
                                      className="menuitem"
                                    >
                                      Passport
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={"VoterId"}
                                      className="menuitem"
                                    >
                                      VoterId
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={"Driving Licence"}
                                      className="menuitem"
                                    >
                                      Driving licence
                                    </Index.MenuItem>
                                    <Index.MenuItem
                                      value={"Other"}
                                      className="menuitem"
                                    >
                                      Other government approved Id
                                    </Index.MenuItem>
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="kyc-input-details">
                          <Index.Box className="edit_profile_field">
                            <Index.Box className="form-group from_login_details">
                              <Index.FormHelperText className="form-lable">
                                Front Upload
                              </Index.FormHelperText>
                              {!showFrontDoc ? (
                                <Index.Box className="title-name-upload">
                                  <Index.Button
                                    variant="contained"
                                    component="label"
                                    className="upload-sign-btn"
                                    disableRipple
                                  >
                                    <img
                                      src={Index.Png.upload}
                                      class="upload image"
                                    />
                                    <span>Frontside Image Upload</span>
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      name="frontSide"
                                      className="inputfile-upload"
                                      onChange={(e) => {
                                        handleAddressProof(e);
                                        setShowFrontDoc(true);
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>
                              ) : (
                                <Index.Box className="upload-img-content">
                                  <img
                                    src={
                                      addressProof?.frontSide?.imgUrl &&
                                      addressProof?.frontSide?.imgUrl
                                    }
                                    alt="front"
                                    className="upload-kyc-img"
                                  />
                                  <Index.Box
                                    className="close-kyc-main"
                                    onClick={(e) => setShowFrontDoc(false)}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-kyc-icon"
                                      alt="icon"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 6",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="kyc-input-details">
                          <Index.Box className="edit_profile_field">
                            <Index.Box className="form-group from_login_details">
                              <Index.FormHelperText className="form-lable">
                                Back Upload
                              </Index.FormHelperText>
                              {!showBackDoc ? (
                                <Index.Box className="title-name-upload">
                                  <Index.Button
                                    variant="contained"
                                    component="label"
                                    className="upload-sign-btn"
                                    disableRipple
                                  >
                                    <img
                                      src={Index.Png.upload}
                                      class="upload image"
                                    />
                                    <span>Backside Image Upload</span>
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      className="inputfile-upload"
                                      name="backSide"
                                      onChange={(e) => {
                                        handleAddressProof(e);
                                        setShowBackDoc(true);
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>
                              ) : (
                                <Index.Box className="upload-img-content">
                                  <img
                                    src={
                                      addressProof?.backSide?.imgUrl &&
                                      addressProof?.backSide?.imgUrl
                                    }
                                    alt="front"
                                    className="upload-kyc-img"
                                  />
                                  <Index.Box
                                    className="close-kyc-main"
                                    onClick={(e) => setShowBackDoc(false)}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-kyc-icon"
                                      alt="icon"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 12",
                      }}
                      className="grid-column kyc-grid-column"
                    >
                      <Item className="grid-item">
                        <Index.Box className="right-submit-btn">
                          <Index.Box class="primary-border-btn-main primary-border-btn-main-header  primary-btn-main-comman">
                            <Index.Button
                              class="primary-border-btn history-btn history-btn-header deposit-btn-dark-comman-main "
                              type="submit"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Submit
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Item>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
