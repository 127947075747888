import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import copyTextToClipboard from "copy-text-to-clipboard";
import { Export_excel } from "../../../../component/common/ExportAll";
import Loader from "../../../../component/common/Loader";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function TransactionList() {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [transactions, setTransactions] = useState([]);
  const [exportDetails, setExportDetails] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [datewiseFilterData, setDateWiseFilterData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [loader, setLoader] = useState(false);
  const [activeClass, setActiveClass] = useState(null);
  const [toggleArrow, setToggleArrow] = useState({
    createdAt: false,
    user_name: false,
    email: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const exportData = (arr1) => {
    let tempArr = [];
    arr1.map((item) => {
      let obj = {
        Date: item?.createdAt
          ? Index.moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")
          : "-",
        UserName: item?.user_id ? item?.user_id?.user_name : "-",
        Email: item?.user_id ? item?.user_id?.email : "-",
        MobileNo: item?.user_id ? item?.user_id?.mobile_number : "-",
        Amount: item?.value ? item?.value : 0,
        HashKey: item?.transactionHash ? item?.transactionHash : "-",
      };
      tempArr.push(obj);
    });
    setExportDetails(tempArr);
  };

  const getTransactionList = async () => {
    setLoader(true);
    await DataService(token)
      .get(API.Admin?.GET_TRANSCATOIN_LIST)
      .then((res) => {
        let temArr = [];

        exportData(res.data.data);
        res.data.data.map((item) => {
          let obj = {
            _id: item?._id,
            date: item?.createdAt
              ? Index.moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")
              : "-",
            user_name: item?.user_id ? item?.user_id?.user_name : "-",
            email: item?.user_id ? item?.user_id?.email : "-",
            mobileNo: item?.user_id ? item?.user_id?.mobile_number : "-",
            amount: item?.value ? item?.value : 0,
            network_type: item?.network_type ? item?.network_type : "-",
            transactionHash: item?.transactionHash
              ? item?.transactionHash
              : "-",
          };
          temArr.push(obj);
        });
        setTransactions(temArr);
        setFilterData(temArr);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "Error");

        // Index.toast.error(error?.response?.data?.message);
      });

    setLoader(false);
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  const handleChange = (e) => {
    setSelectedDate(e);
    if (e == null) {
      setFilterData(transactions);
      setSearchVal("");
    } else {
      const result = transactions.filter((item) => {
        return item?.date
          .toString()
          ?.includes(Index.moment(new Date(e)).format("DD-MM-YYYY"));
      });

      setFilterData(result);
      setDateWiseFilterData(result);
    }

    setPage(0);
  };

  const handleSearch = (event) => {
    console.log(selectedDate, ":selectedDate", datewiseFilterData);
    const result = (selectedDate ? datewiseFilterData : transactions).filter(
      (item) => {
        let network = Index.selectNetwork(item?.network_type);
        setSearchVal(event?.target?.value);
        return (
          item?.user_name
            ?.toLowerCase()
            ?.toString()
            ?.includes(event?.target?.value?.toLowerCase()) ||
          item?.email
            ?.toLowerCase()
            ?.toString()
            ?.includes(event?.target?.value?.toLowerCase()) ||
          item?.mobileNo?.toString()?.includes(event?.target?.value) ||
          item?.amount?.toString()?.includes(event?.target?.value) ||
          item?.date?.toString()?.includes(event?.target?.value) ||
          network?.toString()?.toLowerCase()?.includes(event?.target?.value)
        );
      }
    );
    console.log(result, ":result");
    setFilterData(result);
    setPage(0);
  };

  const handleCopy = (data) => {
    copyTextToClipboard(data);
    toast.success("Hash Key Copied");
  };

  const handleClick = (divIndex) => {
    setActiveClass(divIndex);
  };

  const handleDescending = (arr, field, subField) => {
    setToggleArrow({ ...toggleArrow, [subField]: !toggleArrow[subField] });
    let res = arr.sort(Index.sortFieldInDescending(subField));
    setFilterData(res);
  };

  const handleAscending = (arr, field, subField) => {
    setToggleArrow({ ...toggleArrow, [subField]: !toggleArrow[subField] });
    let res = arr.sort(Index.sortFieldInAscending(subField));
    setFilterData(res);
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Index.Box className="dashboard-content add-user-container">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title user-list-page-title res-admin-page-title-set"
              component="h2"
              variant="h2"
            >
              Transaction List
            </Index.Typography>

            <Index.Box className="transaction-right">
              <Index.Box className="admin-text-field-main presale-input admin-text-field-main-set">
                <Index.Box className="input-box input-box-admin input-box-admin2">
                  <Index.Box className="form-group">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.Stack className="sale-field date-picker-mui">
                        <Index.DatePicker
                          className="privat-sale-datepicker"
                          format="DD-MM-YYYY"
                          // defaultValue={dayjs(
                          //   editId !== undefined ? startValue : ""
                          // )}

                          componentsProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          onAccept={(newDate) => {
                            // console.log(newDate);
                          }}
                          disableFuture
                          onChange={handleChange}
                          renderInput={(params) => (
                            <Index.TextField {...params} />
                          )}
                        />
                      </Index.Stack>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                {/* <p className="error-text">{startDateError}</p> */}
              </Index.Box>

              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        value={searchVal}
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary exp-btn exp-btn2 ">
                <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                  <Index.Link
                    className="add-user-btn  btn-dark-comman-main"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      Export_excel(
                        exportDetails,
                        `Transaction List (${curDate})`
                      );
                    }}
                  >
                    Export CSV
                  </Index.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main transaction-table-main ">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(1)}
                              >
                                <Index.Box className="create-hover">
                                  Created Date
                                  <span
                                    class={
                                      activeClass == 1
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {" "}
                                    {toggleArrow?.createdAt ? (
                                      <ArrowUpwardIcon
                                        onClick={() => {
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "date"
                                          );
                                        }}
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "date"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(2)}
                              >
                                <Index.Box className="create-hover">
                                  User name{" "}
                                  <span
                                    class={
                                      activeClass == 2
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {" "}
                                    {toggleArrow?.user_name ? (
                                      <ArrowUpwardIcon
                                        onClick={() => {
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "user_name"
                                          );
                                        }}
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "user_name"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(3)}
                              >
                                <Index.Box className="create-hover">
                                  Email{" "}
                                  <span
                                    class={
                                      activeClass == 3
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {toggleArrow?.email ? (
                                      <ArrowUpwardIcon
                                        onClick={() =>
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "email"
                                          )
                                        }
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "email"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Hash Key
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Network
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Amount($)
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  let network = Index.selectNetwork(
                                    row?.network_type
                                  );
                                  let networkLink = Index.NetworkLink(network);

                                  let val = Number(row?.amount);

                                  return (
                                    <Index.TableRow
                                      key={row.name}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.date ? row?.date : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_name}
                                          arrow
                                          placement="top"
                                        >
                                          <Index.Box className="username-bold">
                                            {row?.user_name
                                              ? row?.user_name
                                              : "-"}
                                          </Index.Box>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.email}
                                          arrow
                                          placement="top"
                                        >
                                          <span>
                                            {row?.email ? row?.email : "-"}
                                          </span>
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td set-hover-text"
                                        align="right"
                                        onClick={() =>
                                          handleCopy(row?.transactionHash)
                                        }
                                      >
                                        <Index.Tooltip
                                          title={row?.transactionHash}
                                          arrow
                                          placement="top"
                                        >
                                          <span>
                                            {" "}
                                            {row?.transactionHash
                                              ? `${row?.transactionHash?.slice(
                                                  0,
                                                  5
                                                )}.....${row?.transactionHash?.slice(
                                                  -5
                                                )}`
                                              : "-"}
                                          </span>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={network}
                                          arrow
                                          placement="top"
                                        >
                                          <span>
                                            {" "}
                                            {row?.network_type
                                              ? network
                                              : "-"}{" "}
                                          </span>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.amount ? val.toFixed(2) : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td email-ellip"
                                        align="right"
                                      >
                                        <Index.Link
                                          to={networkLink}
                                          target="_blank"
                                        >
                                          <Index.Tooltip
                                            title={"More Details"}
                                            arrow
                                            placement="top"
                                          >
                                            <span>More Details</span>
                                          </Index.Tooltip>
                                        </Index.Link>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={7}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={filterData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </div>
  );
}
