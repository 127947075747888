import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Index from "../../../../Index";
import GeneralSetting from "../../../../user/pages/userProfile/GeneralSetting";
import WaitlistGeneralSetting from "./GeneralSetting";
import WaitlistTotalTranaction from "./TotalTranaction";
import WaitlistTotalDeposit from "./TotalDeposit";
import WaitlistTotalPurchase from "./TotalPurchase";
import WaitlistKycDetail from "./KycDetail";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API } from "../../../../../config/Api";
import { DataService } from "../../../../../config/DataService";
import ReferralUser from "./ReferralUser";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WaitlistTab() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [user, setUser] = useState({});
  const [transaction, setTransaction] = useState({});
  const [kycDetails, setKycDetails] = useState({});
  const [ReferralList, setReferralList] = useState([]);

  const params = useParams();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const getUserDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", params?.userId?.trim());
    await DataService(token)
      .post(API.Admin.USER_ALL_DETAILS, urlencoded)
      .then((res) => {
        setUser(res?.data?.data?.user);
        setTransaction(res?.data?.data?.transaction);
        setKycDetails(res?.data?.data?.kycDetails);
        setReferralList(res?.data?.data?.referralList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title mb-0"
            component="h2"
            variant="h2"
          >
            {user?.user_name}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="General Profile"
                  {...a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="Transaction"
                  {...a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="deposit"
                  {...a11yProps(2)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="Purchase"
                  {...a11yProps(3)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="KYC"
                  {...a11yProps(4)}
                  className="admin-tab"
                  disableRipple
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="admin-tabpanel">
              {/* general setting tab start */}
              <Index.Box className="tabpanel-main">
                <Index.Box className="tabpanelinner-body ">
                  <WaitlistGeneralSetting data={user} />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="admin-tabpanel">
              {/*Total purchase tab start */}

              <Index.Box className="tabpanel-main">
                <Index.Box className="">
                  <WaitlistTotalTranaction data={transaction} />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={2} className="admin-tabpanel">
              {/*Total transaction tab start */}

              <Index.Box className="tabpanel-main">
                <Index.Box className="">
                  <WaitlistTotalDeposit data={transaction} />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={3} className="admin-tabpanel">
              {/*Total deposit tab start */}

              <Index.Box className="tabpanel-main">
                <Index.Box className="">
                  <WaitlistTotalPurchase />
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={4} className="admin-tabpanel">
              {/*Total deposit tab start */}

              <Index.Box className="tabpanel-main">
                <Index.Box className="tabpanelinner-body">
                  <WaitlistKycDetail
                    data={kycDetails}
                    userid={params?.userId?.trim()}
                  />
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
