import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useParams } from "react-router-dom";

import { TailSpin } from "react-loader-spinner";
import CustomModal from "../../../../component/Modal/CustomModal";
import UpdateTokenDetails from "./UpdateTokenDetails";

export default function TokenCategoryDetails() {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const params = useParams();

  // All states
  const [selectedId, setSelectdId] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [tokenCategoryList, setTokenCategoryList] = useState([]);

  const [loader, setLoader] = useState(false);

  const [showReferralModal, setShowReferralModal] = useState(false);

  const onOpenRequestModal = (data) => {
    setShowReferralModal(true);
    setSelectdId(data);
  };
  const onCloseRequestModal = () => {
    setSelectdId("");
    setShowReferralModal(false);
  };

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getCategoryTokenList = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("category_id", params?.id);
    await DataService(token)
      .post(API.Admin.GET_CATEGORY_DATA, urlencoded)
      .then((res) => {
        if (res.data.status == 200) {
          setFilterData(res.data.data);
          setTokenCategoryList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    if (!showReferralModal) {
      getCategoryTokenList();
    }
  }, [showReferralModal]);

  const handleSearch = (event) => {
    const result = tokenCategoryList.filter((item) => {
      let date = Index.moment(item?.createdAt)
        .format("DD-MM-YYYY hh:mm A")
        .toString()
        ?.includes(event?.target?.value);
      let tokenName = item?.name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value.toLowerCase());

      let tokenSymbol = item?.symbol
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value.toLowerCase());

      let tradingSymbol = item?.trading_view_symbol
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value.toLowerCase());

      let tokenPrice = item?.token_price
        ?.toString()
        ?.includes(event?.target?.value);

      let marketCap = item?.market_cap
        ?.toString()
        ?.includes(event?.target?.value);

      return (
        date ||
        tokenName ||
        tokenSymbol ||
        tradingSymbol ||
        tokenPrice ||
        marketCap
      );
    });
    setFilterData(result);
    setPage(0);
  };

  // Token Disable
  const TokenDisable = async (data) => {
    const formData = new URLSearchParams();
    formData.append("id", data?.id);
    formData.append("action", data?.status);
    await DataService(token)
      .post(API.Admin.ENABLE_DISABLE_TOKEN, formData)
      .then((response) => {
        if (response.data.status == 200) {
          console.log("reRender");
          console.log(response.data, ":response.data");
          Index.toast.success(response.data.message);
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
    getCategoryTokenList();
  };

  const syncToken = async () => {
    setLoader(true);
    await DataService(token)
      .get(API.Admin.SYNC_TOKEN)
      .then((response) => {
        if (response.data.status == 200) {
          setTimeout(() => {
            Index.toast.success(response.data.message);
            setLoader(false);
          }, 1000);
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Token Category Details
          </Index.Typography>

          <Index.Box className="user-search-main cus-search">
            <Index.Box className="flex-btn">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className="btn-main login-btn-main primary-btn-main-comman"
                onClick={() => syncToken()}
              >
                <Index.Link className="add-user-btn  btn-dark-comman-main">
                  {loader ? (
                    <TailSpin
                      height="20"
                      width="20"
                      radius="3"
                      color="green"
                      ariaLabel="loading"
                      wrapperStyle
                      wrapperClass
                    />
                  ) : (
                    "Sync Token"
                  )}{" "}
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main waitlist-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Created Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Trading View Symbol
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Price ($)
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Market Cap ($)
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt)
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm A")
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.name ? row?.name : "-"} (
                                      {row?.symbol ? row?.symbol : "-"})
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.trading_view_symbol
                                        ? row?.trading_view_symbol
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.token_price
                                        ? row?.token_price?.toFixed(2)
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.market_cap
                                        ? Number(row?.market_cap)?.toFixed(2)
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.IconButton>
                                        <Index.FormControlLabel
                                          control={
                                            <Index.IOSSwitch
                                              checked={row?.is_active}
                                              sx={{ m: 1 }}
                                              className="MuiSwitch-track-active"
                                            />
                                          }
                                          onClick={() => {
                                            const temp = {
                                              id: row?.id,
                                              status: !row?.is_active,
                                            };
                                            TokenDisable(temp);
                                            // setActive(!active);
                                          }}
                                          className="switch-lable"
                                        />
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={(e) => onOpenRequestModal(row)}
                                      >
                                        <img src={Index.Svg.blueEdit}></img>
                                      </Index.IconButton>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={6}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <CustomModal
        modalOpen={showReferralModal}
        onOpenModal={onOpenRequestModal}
        onCloseModal={onCloseRequestModal}
      >
        <Index.Box p={2}>
          <UpdateTokenDetails
            onCloseRequestModal={onCloseRequestModal}
            selectedId={selectedId}
          />
        </Index.Box>
      </CustomModal>
    </div>
  );
}
