import React, { useState } from "react";
import Index from "../../Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { validateEmail } from "../../../component/common/AuthValidation";
import { MuiOtpInput } from "mui-one-time-password-input";
import ErrorShow from "../../../component/common/ErrorShow";

export default function OtpVerify() {
  const [value, setValue] = useState("");
  const [errorOtp, setErrorOtp] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value == "") {
      setErrorOtp("Please Enter OTP");
    } else if (value.length !== 4) {
      setErrorOtp("Please Enter valid OTP");
    } else {
      setErrorOtp("");
    }

    if (value) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("otp", value);
    }
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Png.logo_new}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>
              <Index.Box className="admin-login-inner card-login-main">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Verify OTP
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  We dent you an OTP to your registered Email
                </Index.Typography>
                <Index.Box className="otp-flex-main otp-flex-main2 form-group">
                  <MuiOtpInput
                    length={4}
                    value={value}
                    className="form-control"
                    onChange={(e) => {
                      setValue(e);
                    }}
                    name="otp"
                  />
                </Index.Box>

                <ErrorShow error={errorOtp} />

                <Index.Box className="btn-main login-btn-main">
                  <Index.Button
                    className="btn login-btn"
                    onClick={handleSubmit}
                    type="button"
                    disableRipple
                  >
                    Verify OTP
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
