import Routers from "./routes/Routes";

import "./assets/style/admin.css";
import "./assets/style/style.css";
import "./assets/style/responsive.css";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Index from "./container/Index";

function App() {
  {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Index.ToastContainer />
        <Routers />
      </BrowserRouter>
    </div>
  );
}

export default App;
