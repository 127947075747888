import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

function ReferralUserList() {
  const token = Index.useSelector((store) => store?.admin?.loginUserToken);
  let userToken = window?.atob(token);

  const [filterData, setFilterData] = useState([]);
  const [referralUserList, setReferralUserlist] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getDirectReferralUserList = async () => {
    await DataService(userToken)
      .get(API.User.DIRECT_REFERR_USER_LIST)
      .then((res) => {
        if (res.data.status == 200) {
          setFilterData(res.data.data);
          setReferralUserlist(res.data.data);
        }
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getDirectReferralUserList();
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;

    const result = referralUserList.filter((item) => {
      let username = item?.user_name?.toString()?.includes(value);
      let email = item?.email?.toString()?.includes(value);
      let date = Index.moment(item?.createdAt)
        .format("DD-MM-YYYY hh:mm A")
        .toString()
        ?.includes(value);

      return username || email || date;
    });
    setFilterData(result);
    setPage(0);
  };

  const handleCopy = (data, type) => {
    Index.copyTextToClipboard(
      `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?${type}-Referral=${data}`
    );
    Index.toast.success("Referral link Copied");
  };

  return (
    <>
      <Index.Box className="tabpanel-main">
        <Index.Box className="">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title res-referral-list-tree-btn"
              component="h2"
              variant="h2"
            >
              {" "}
              Referral User List
            </Index.Typography>
            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box ">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.Box className="page-table-main table-design-main transaction-table-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Date
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Username
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        email
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Left Referral
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Right Referral
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {filterData.length > 0 ? (
                      filterData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          let network = Index.selectNetwork(row?.network_type);
                          let networkLink = Index.NetworkLink(network);
                          return (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.createdAt
                                  ? Index.moment(row?.createdAt).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                // onClick={() => handleCopy(row?.transactionHash)}
                              >
                                <Index.Tooltip
                                  title={row?.user_name}
                                  arrow
                                  placement="top"
                                >
                                  <span>
                                    {" "}
                                    {row?.user_name ? row?.user_name : "-"}
                                  </span>
                                </Index.Tooltip>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Tooltip
                                  title={row?.email}
                                  arrow
                                  placement="top"
                                >
                                  <span>{row?.email ? row?.email : "-"}</span>
                                </Index.Tooltip>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Tooltip title="Copy">
                                  <Index.Typography
                                    className="set-hover-text"
                                    variant="p"
                                    onClick={() =>
                                      handleCopy(row?.left_referral, "Left")
                                    }
                                  >
                                    {Index.ReplaceCharsWithAsterisks(
                                      row?.left_referral
                                    )}{" "}
                                  </Index.Typography>
                                </Index.Tooltip>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Tooltip title="Copy">
                                  <Index.Typography
                                    className="set-hover-text"
                                    variant="p"
                                    onClick={() =>
                                      handleCopy(row?.right_referral, "Right")
                                    }
                                  >
                                    {Index.ReplaceCharsWithAsterisks(
                                      row?.right_referral
                                    )}{" "}
                                  </Index.Typography>
                                </Index.Tooltip>
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                          colSpan={5}
                        >
                          No Record found
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>{" "}
          <Index.Box className="pagination-main">
            <Index.TablePagination
              component="div"
              page={page}
              count={filterData?.length}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[15, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={(page) =>
                `Records ${page.from} to ${
                  page.to === -1 ? page.count : page.to
                } of ${page.count}`
              }
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ReferralUserList;
