import React, { useState } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

const ApprovedRejectModal = ({ selectedId, onCloseRequestModal, status }) => {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [transactionId, setTransactionId] = useState("");
  const [transactionIdError, setTransactionIdError] = useState("");

  const handleTransactionId = (e) => {
    setTransactionId(e.target?.value);
    setTransactionIdError("");
  };

  const validate = () => {
    if (!transactionId) {
      setTransactionIdError("Please enter transaction Id");
    } else {
      setTransactionIdError("");
    }
  };

  const ApprovedRejectRequest = async (e, status) => {
    e.preventDefault();

    if (transactionId) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("_id", selectedId);
      urlencoded.append("transaction_id", transactionId?.trim());
      urlencoded.append("status", status);
      await DataService(token)
        .post(API.Admin.ACCEPT_REJECT_WITHDRAW_REQUEST, urlencoded)
        .then((res) => {
          if (res?.data?.status == 200) {
            Index.toast.success(res?.data?.message);
            setTransactionId("");
            onCloseRequestModal();
          }
        })
        .catch((error) => {
          Index.toast.error(error?.response?.data?.message);
        });
    } else {
      status !== "Reject" && validate();
    }
  };

  return (
    <div>
      <Index.Box>
        <Index.Typography variant="p" component={"p"}>
          Are you sure you have to approved or reject withdraw request ?
        </Index.Typography>
        <Index.Box mt={2}>
          <Index.Box className="input-box add-user-input">
            <Index.FormHelperText className="form-lable">
              Transaction Id
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                value={transactionId}
                name="email"
                onChange={(e) => handleTransactionId(e)}
              />
            </Index.Box>
            <p className="error-text">{transactionIdError}</p>
          </Index.Box>
        </Index.Box>
        <Index.Box className="user-btn-flex withdraw-request-btn comman-btn-main">
          <Index.Box className="discard-btn-main border-btn-main">
            <Index.Button
              className="discard-user-btn border-btn"
              onClick={(e) => ApprovedRejectRequest(e, "Approved")}
            >
              Approved
            </Index.Button>
          </Index.Box>
          <Index.Box className="save-btn-main ">
            <Index.Button
              className="save-user-btn primary-btn"
              onClick={(e) => ApprovedRejectRequest(e, "Reject")}
            >
              <img
                src={Index.Svg.save}
                alt="save"
                className="user-save-icon"
              ></img>
              Reject
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default ApprovedRejectModal;
