import React, { useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";

const UpdateTokenDetails = ({ selectedId, onCloseRequestModal }) => {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [tradingSymbol, setTradingSymbol] = useState();
  const [tradingSymbolError, setTradingSymbolError] = useState("");

  console.log(selectedId?.trading_view_symbol, 13);

  const handleTransactionId = (e) => {
    setTradingSymbol(e.target?.value);
    setTradingSymbolError("");
  };

  useEffect(() => {
    setTradingSymbol("");
    setTradingSymbolError("");
    setTradingSymbol(selectedId?.trading_view_symbol);
  }, [selectedId]);

  const validate = () => {
    if (!tradingSymbol) {
      setTradingSymbolError("Please enter trading symbol");
    } else {
      setTradingSymbolError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tradingSymbol) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("id", selectedId.id);
      urlencoded.append("trading_view_symbol", tradingSymbol?.trim());

      await DataService(token)
        .post(API.Admin.UPDATE_TOKEN_TRADING_SYMBOL, urlencoded)
        .then((res) => {
          if (res?.data?.status == 200) {
            Index.toast.success(res?.data?.message);
            setTradingSymbol("");
            onCloseRequestModal();
          }
        })
        .catch((error) => {
          console.log(error, "Error");
          Index.toast.error(error?.response?.data?.message);
        });
    } else {
      validate();
    }
  };

  console.log(tradingSymbol, "tradingSymbol", selectedId?.trading_view_symbol);

  return (
    <div>
      <Index.Box sx={{ width: "350px" }}>
        <Index.Typography variant="h6" component={"h6"}>
          Update trading view symbol
        </Index.Typography>
        <Index.Box mt={2}>
          <Index.Box className="input-box add-user-input">
            <Index.FormHelperText className="form-lable">
              Trading View Symbol
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                value={tradingSymbol != undefined ? tradingSymbol : ""}
                name="email"
                onChange={(e) => handleTransactionId(e)}
              />
            </Index.Box>
            <p className="error-text">{tradingSymbolError}</p>
          </Index.Box>
        </Index.Box>
        <Index.Box className="user-btn-flex withdraw-request-btn comman-btn-main">
          <Index.Box className="save-btn-main ">
            <Index.Button
              className="save-user-btn primary-btn"
              onClick={(e) => handleSubmit(e)}
            >
              <img
                src={Index.Svg.save}
                alt="save"
                className="user-save-icon"
              ></img>
              Update
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default UpdateTokenDetails;
