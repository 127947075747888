import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { API } from "../../../../config/Api";
import { useSelector, useDispatch } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { toast, ToastContainer } from "react-toastify";
import { getSettingData } from "../../../../redux/features/slices/Admin/AdminSlice";

export default function AdminSetting() {
  const [inpval, setInpval] = useState({
    usdtAddress: "",
    tronAddress: "",
    marquee: "",
  });
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const dispatch = useDispatch();

  const [usdtAddressError, setUsdtAddressError] = useState("");
  const [tronAddressError, setTronAddressError] = useState("");
  const [usdtDisable, setUsdtDisable] = useState(false);
  const [tronDisable, setTronDisable] = useState(false);

  const getSettingDetails = () => {
    DataService(token)
      .get(API.Admin.GET_SETTING)
      .then((res) => {
        dispatch(getSettingData(res.data.data));
        let obj = {
          usdtAddress: res.data.data.usdt_address.address,
          tronAddress: res.data.data.tron_address.address_tron,
          marquee: res.data.data?.marquee,
        };
        setInpval(obj);
        setUsdtDisable(res.data.data.usdt_address.disable);
        setTronDisable(res.data.data.tron_address.is_disable);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getSettingDetails();
  }, []);

  const handleInput = (e) => {
    const { value, name } = e.target;
    setInpval({ ...inpval, [name]: value });
    if (name == "usdtAddress") {
      setUsdtAddressError("");
    }
    if (name == "tronAddress") {
      setTronAddressError("");
    }
  };

  const validate = () => {
    if (inpval.usdtAddress !== "") {
      setUsdtAddressError("");
    } else {
      setUsdtAddressError("Please enter USDT address.");
    }

    if (inpval.tronAddress !== "") {
      setTronAddressError("");
    } else {
      setTronAddressError("Please enter TRON address.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validate();

    if (inpval?.usdtAddress && inpval?.tronAddress) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("address", inpval.usdtAddress);
      urlencoded.append("address_tron", inpval.tronAddress);
      urlencoded.append("disable", usdtDisable);
      urlencoded.append("is_disable", tronDisable);
      urlencoded.append("marquee", inpval?.marquee);
      await DataService(token)
        .post(API.Admin.ADMIN_SETTING, urlencoded)
        .then((res) => {
          if (res.status == 201) {
            toast.success("Add Setting.");
          } else if (res.status == 200) {
            toast.success("Update Setting.");
          }
          getSettingDetails();
        })
        .catch((error) => Index.toast.error(error?.response?.data?.message));
    }
  };

  return (
    <div>
      <Index.Box className="custom-card">
        <Index.Box className="dashboard-content add-user-containt">
          <Index.Box className="dashboard-content">
            <Index.Box className="user-head-title">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Setting
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="setting-content">
            <Index.Grid container spacing={2} className="grid-100">
              <Index.Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className="set-grid-modal"
              >
                <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input join-wait-input-set res-join-wait-input">
                  <Index.FormHelperText className="form-lable">
                    USD Deposit Address
                  </Index.FormHelperText>
                  <Index.Box className="flex-input">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id=""
                        className="form-control"
                        value={inpval.usdtAddress}
                        name="usdtAddress"
                        disabled={usdtDisable}
                        onChange={(e) => handleInput(e)}
                      />
                      <p className="error-text">{usdtAddressError}</p>
                    </Index.Box>
                    <Index.Box className="submit-btn-main border-btn-main set-join-btn-box">
                      <Index.Button
                        className="submit-user-btn border-btn"
                        disableRipple
                        type="button"
                        onClick={() => setUsdtDisable(!usdtDisable)}
                      >
                        {usdtDisable ? "Enable" : "Disable"}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
            <Index.Grid container spacing={2} className="grid-100">
              <Index.Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className="set-grid-modal"
              >
                <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                  <Index.FormHelperText className="form-lable">
                    USDT Deposit Address - Tron
                  </Index.FormHelperText>
                  <Index.Box className="flex-input">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id=""
                        className="form-control"
                        value={inpval.tronAddress}
                        name="tronAddress"
                        disabled={tronDisable}
                        onChange={(e) => handleInput(e)}
                      />
                      <p className="error-text">{tronAddressError}</p>
                    </Index.Box>
                    <Index.Box className="submit-btn-main border-btn-main set-join-btn-box">
                      <Index.Button
                        className="submit-user-btn border-btn"
                        disableRipple
                        type="button"
                        onClick={() => setTronDisable(!tronDisable)}
                      >
                        {tronDisable ? "Enable" : "Disable"}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
            <Index.Grid container spacing={2} className="grid-100">
              <Index.Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className="set-grid-modal"
              >
                <Index.Box className="input-box input-box-admin  join-wait-input join-wait-input-set res-join-wait-input">
                  <Index.FormHelperText className="form-lable">
                    Marquee
                  </Index.FormHelperText>
                  <Index.Box className="flex-input">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id=""
                        className="form-control"
                        value={inpval.marquee}
                        name="marquee"
                        disabled={tronDisable}
                        onChange={(e) => handleInput(e)}
                      />
                    </Index.Box>
                    {/* <Index.Box className="submit-btn-main border-btn-main set-join-btn-box">
                      <Index.Button
                        className="submit-user-btn border-btn"
                        disableRipple
                        type="button"
                        onClick={() => setTronDisable(!tronDisable)}
                      >
                        {tronDisable ? "Enable" : "Disable"}
                      </Index.Button>
                    </Index.Box> */}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
        <Index.Box
          className="btn-main login-btn-main primary-btn-main-comman "
          mt={2}
        >
          <Index.Button
            className="add-user-btn  btn-dark-comman-main "
            disableRipple
            type="button"
            onClick={(e) => handleSubmit(e)}
          >
            Update
          </Index.Button>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
