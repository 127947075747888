import React, { useState } from "react";
import Index from "../../../../Index";

const PresaleTotalPurchase = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  return (
    <div>
      <Index.Box className="tabpanel-main">
        <Index.Box className="">
          {/* add-user-data-main */}
          <Index.Box className="user-head-title mt-0">
            <Index.Box></Index.Box>
            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box mr-0">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        // onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.Box className="page-table-main table-design-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Created Date
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        USERNAME
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Email
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Amount
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        colSpan={5}
                      >
                        No Record found
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>{" "}
          <Index.Box className="pagination-main">
            {/* <TablePagination
            component="div"
            rowsPerPageOptions={[filterData?.length % 10]}
            count={filterData?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
             labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
          /> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default PresaleTotalPurchase;
