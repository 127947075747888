import React from 'react'
import Index from "../../../Index";


export default function Setting() {
    return (
        <>
            <Index.Box class="">
                <Index.Box className="setting-card ">
                    <Index.Box className="dashboard-content">
                        <Index.Typography className='admin-page-title' component='h2' variant='h2'>Setting</Index.Typography>
                    </Index.Box>
                    <Index.Box sx={{ flexGrow: 1 }} className="setting-content">
                        <Index.Grid container spacing={2} className="grid-100">
                            <Index.Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                className="set-grid-modal"
                            >
                                <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                                    <Index.FormHelperText className="form-lable">
                                        USD Deposit Address
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group" mb={2}>
                                        <Index.TextField
                                            fullWidth
                                            id=""
                                            className="form-control"
                                        />
                                    </Index.Box>
                                </Index.Box>

                            </Index.Grid>
                        </Index.Grid>
                        <Index.Grid container spacing={2} className="grid-100">
                            <Index.Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                className="set-grid-modal"
                            >
                                <Index.Box className="input-box input-box-admin join-wait-input ">
                                    <Index.FormHelperText className="form-lable">
                                        USDT Deposit Address - Tron
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group" mb={2}>
                                        <Index.TextField
                                            fullWidth
                                            id=""
                                            className="form-control"
                                        />
                                    </Index.Box>
                                </Index.Box>

                            </Index.Grid>
                        </Index.Grid>
                    </Index.Box>


                </Index.Box>
            </Index.Box>
        </>
    )
}
