import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { useSelector } from "react-redux";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import Withdraw from "../myIncome/myIncomeTab/withdraw/Withdraw";

function WithdrawList() {
  const token = useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);
  const details = useSelector((store) => store?.admin?.userDetail);
  let userDetails = JSON?.parse(window?.atob(details));

  const [withdrawList, setWithdrawList] = useState([]);
  const [allAmount, setAllAmounts] = useState({});

  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // For Withdraw Modal
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const handleOpenWithdrawModal = () => setOpenWithdraw(true);
  const handleCloseWithdrawModal = () => setOpenWithdraw(false);

  const getWithDrawList = async () => {
    await DataService(userToken)
      .get(API.User.WITHDRAW_LIST)
      .then((res) => {
        console.log(res.data.data, "res.data.data");
        setWithdrawList(res.data.data);
        setFilterData(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  const getAllWalletCounts = async () => {
    await DataService(userToken)
      .get(API.User.USER_WALLET)
      .then((res) => {
        setAllAmounts(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getAllWalletCounts();
    getWithDrawList();
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    let sender = false;
    let receiver = false;

    const result = withdrawList.filter((item) => {
      let date = Index.moment(item?.date)
        .format("DD-MM-YYYY hh:mm A")
        .toString()
        ?.includes(value);
      if (item?.sender != undefined) {
        sender = item?.sender?.user_name
          ?.toLowerCase()
          ?.toString()
          .includes(value?.toLowerCase());
      }
      if (item?.receiver != undefined) {
        receiver = item?.receiver?.user_name
          ?.toLowerCase()
          ?.toString()
          .includes(value?.toLowerCase());
      }
      let tokenType = item?.token
        ?.toLowerCase()
        .toString()
        .includes(value?.toLowerCase());
      let token = item?.token_value?.toString().includes(value);
      let amount = item?.amount?.toString().includes(value);

      return date || sender || receiver || tokenType || token || amount;
    });
    setFilterData(result);
    setPage(0);
  };

  return (
    <>
      <Index.Box class="">
        <Index.Box className="setting-card">
          <Index.Box className="dashboard-content referral-list-tree-btn">
            <Index.Typography
              className="admin-page-title res-referral-list-tree-btn"
              component="h2"
              variant="h2"
            >
              Withdraw List
            </Index.Typography>
            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                <Index.Button
                  class=" primary-border-btn withdraw-amount-btn"
                  tabindex="0"
                  type="button"
                  disableRipple
                  // onClick={(e) => handleOpenWithdrawModal(e)}
                >
                  Total Amount :{" "}
                  {allAmount?.withdraw_amount ? allAmount?.withdraw_amount : 0}{" "}
                  USDT
                  <span class=""></span>
                </Index.Button>
              </Index.Box>

              <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                <Index.Button
                  class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main"
                  tabindex="0"
                  type="button"
                  disableRipple
                  // onClick={(e) => handleOpenWithdrawModal(e)}

                  onClick={(e) => {
                    userDetails?.is_verified
                      ? handleOpenWithdrawModal(e)
                      : Index.toast.error(
                          "You are not verified for access withdraw"
                        );
                  }}
                >
                  Withdraw
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main transaction-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Sender
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Receiver
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Token type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Token
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Withdraw Amount (USDT)
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <Index.TableRow
                                      key={index}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.date
                                          ? Index.moment(row?.date).format(
                                              "DD-MM-YYYY hh:mm A"
                                            )
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.sender?.user_name
                                          ? row?.sender?.user_name
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.receiver?.user_name
                                          ? row?.receiver?.user_name
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.token ? row?.token : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.token_value
                                          ? row?.token_value?.toFixed(2)
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.amount ? row?.amount : "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={6}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={filterData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openWithdraw}
        onClose={handleCloseWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Withdraw
          handleCloseWithdrawModal={handleCloseWithdrawModal}
          totalBalance={allAmount?.total_amount}
        />
      </Index.Modal>
    </>
  );
}

export default WithdrawList;
