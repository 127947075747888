import React from "react";
import Index from "../../../../component/Index";
import { Outlet } from "react-router-dom";
import UserSidebar from "../../../../component/defaulLayout/user/UserSidebar";
import UserHeader from "../../../../component/defaulLayout/user/UserHeader";
import Chatboat from "../../../../component/common/chatboat";

export default function UserDashboard() {
  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <UserSidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <UserHeader />
          <Index.Box className="dashboard-containt-main login-user-form ">
            <Outlet />
            <Chatboat />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
