import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import CustomModal from "../../../../component/Modal/CustomModal";
import ApprovedRejectModal from "./ApprovedRejectModal";

export default function WithdrawRequestList() {
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // All states
  const [selectedId, setSelectdId] = useState();
  const [filterData, setFilterData] = useState([]);
  const [withdrawRequestList, setWithdrawRequestList] = useState([]);

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [showReferralModal, setShowReferralModal] = useState(false);

  const onOpenRequestModal = (data) => {
    setShowReferralModal(true);
    setSelectdId(data);
  };
  const onCloseRequestModal = () => {
    setShowReferralModal(false);
    setSelectdId("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getWithdrawRequestList = async () => {
    await DataService(token)
      .get(API.Admin.WITHDRAW_REQUEST_LIST)
      .then((res) => {
        let temp = [];
        if (res.data.status == 200) {
          setFilterData(res.data.data);
          setWithdrawRequestList(res.data.data);
          // res.data.data?.map((res) => {
          //   let referralObj = {
          //     username : res?.user_name,
          //     id : res?._id,
          //     referralId : res?.referral_id
          //   }
          //   temp.push(referralObj);
          // })
          // setNoReferralIdList(temp);
        }
      });
  };

  useEffect(() => {
    if (showReferralModal == false) {
      getWithdrawRequestList();
    }
  }, [showReferralModal]);

  const handleSearch = (event) => {
    const result = withdrawRequestList.filter((item) => {
      let userName = item?.user_id?.user_name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value.toLowerCase());

      let tokenName = item?.token_name
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value?.toLowerCase());

      let Network = item?.network
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value?.toLowerCase());

      let status = item?.status
        ?.toLowerCase()
        ?.toString()
        ?.includes(event?.target?.value?.toLowerCase());

      return userName || tokenName || Network || status;
    });
    setFilterData(result);
    setPage(0);
  };

  const handleCopy = (data) => {
    Index.copyTextToClipboard(data);
    Index.toast.success("Wallet address coiped");
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Withdraw Request List
          </Index.Typography>

          <Index.Box className="user-search-main cus-search">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e)}
                />

                <img
                  src={Index.Svg.greysearch}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main waitlist-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Created Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USERNAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Token Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Network
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Withdraw Tokens
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Wallet Address
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Status
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <Index.TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt)
                                            .utcOffset("+05:30")
                                            .format("DD-MM-YYYY hh:mm A")
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        // title={row?.user_name}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Link>
                                          {" "}
                                          {row?.user_id?.user_name
                                            ? row?.user_id?.user_name
                                            : "-"}
                                        </Index.Link>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip
                                        // title={row?.email}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.Box className="email-ellip">
                                          <Index.Link>
                                            {row?.token_name
                                              ? row?.token_name
                                              : "-"}
                                          </Index.Link>
                                        </Index.Box>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip>
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                        >
                                          {row?.network ? row?.network : "-"}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip>
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                        >
                                          {row?.token_withdraw
                                            ? row?.token_withdraw
                                            : "-"}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip title="Copy">
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                          onClick={(e) =>
                                            handleCopy(row?.wallet_address)
                                          }
                                        >
                                          {row?.wallet_address
                                            ? Index.ReplaceCharsWithAsterisks(
                                                row?.wallet_address
                                              )
                                            : "-"}{" "}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.Tooltip>
                                        <Index.Typography
                                          className="set-hover-text"
                                          variant="p"
                                        >
                                          {row?.status ? row?.status : "-"}
                                        </Index.Typography>
                                      </Index.Tooltip>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {!["Approved", "Completed"].includes(
                                        row?.status
                                      ) ? (
                                        <Index.IconButton
                                          onClick={(e) =>
                                            onOpenRequestModal(row?._id)
                                          }
                                        >
                                          <img src={Index.Svg.blueEdit}></img>
                                        </Index.IconButton>
                                      ) : (
                                        "-"
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={8}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <CustomModal
        modalOpen={showReferralModal}
        onOpenModal={onOpenRequestModal}
        onCloseModal={onCloseRequestModal}
      >
        <Index.Box p={2}>
          <ApprovedRejectModal
            onCloseRequestModal={onCloseRequestModal}
            selectedId={selectedId}
          />
        </Index.Box>
      </CustomModal>
    </div>
  );
}
