import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import AllInCome from "./AllIncome";
import StakIncome from "./StakIncome";
import BonusIncome from "./BonusIncome";
import WorkingIncome from "./WorkingIncome";
import { API } from "../../../../../config/Api";
import { DataService } from "../../../../../config/DataService";
import Withdraw from "./withdraw/Withdraw";

export default function MyIncomeTab() {
  const token = Index.useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  const profileData = Index.useSelector((store) => store?.admin?.userProfile);
  let userDetails = JSON?.parse(window?.atob(profileData));

  const [stakIncomeList, setStakIncomeList] = useState([]);
  const [allIncomeDetails, setAllIncomeDetails] = useState({});
  const [value, setValue] = useState(0);

  // For Withdraw Modal
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const handleOpenWithdrawModal = () => setOpenWithdraw(true);
  const handleCloseWithdrawModal = () => setOpenWithdraw(false);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const getStakIncomeList = async () => {
    await DataService(userToken)
      .get(API.User.STACK_INCOME_LIST)
      .then((res) => {
        setStakIncomeList(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getStakIncomeList();
  }, []);

  const getAllIncomeDetails = async () => {
    await DataService(userToken)
      .get(API.User.USER_WALLET)
      .then((res) => {
        setAllIncomeDetails(res?.data?.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getAllIncomeDetails();
  }, [openWithdraw]);

  return (
    <div>
      <Index.Box className="dashboard-content referral-list-tree-btn">
        <Index.Typography
          className="admin-page-title res-referral-list-tree-btn"
          component="h2"
          variant="h2"
        >
          My Income
        </Index.Typography>
        <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
          <Index.Button
            class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main "
            tabindex="0"
            type="button"
            disableRipple
            onClick={(e) =>
              userDetails?.is_verified
                ? handleOpenWithdrawModal(e)
                : Index.toast.error("You are not verified for access withdraw")
            }
          >
            Withdraw <span class=""></span>
          </Index.Button>
        </Index.Box>
      </Index.Box>
      <Index.Box className="stak-main tab-label-dash ">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleTabs}
              aria-label="basic tabs example"
            >
              <Index.Tab label="All" {...Index.a11yProps(0)} />
              <Index.Tab label="Stake Income" {...Index.a11yProps(1)} />
              <Index.Tab label="Bonus Income" {...Index.a11yProps(2)} />
              <Index.Tab label="Working Income" {...Index.a11yProps(2)} />
            </Index.Tabs>
          </Index.Box>
          <Index.CustomTabPanel
            value={value}
            index={0}
            className="main-stack-tab-one"
          >
            <AllInCome data={allIncomeDetails} />
          </Index.CustomTabPanel>
          <Index.CustomTabPanel
            value={value}
            index={1}
            className="main-stack-tab-one"
          >
            <StakIncome data={stakIncomeList} />
          </Index.CustomTabPanel>
          <Index.CustomTabPanel
            value={value}
            index={2}
            className="main-stack-tab-one"
          >
            <BonusIncome />
          </Index.CustomTabPanel>
          <Index.CustomTabPanel
            value={value}
            index={3}
            className="main-stack-tab-one"
          >
            <WorkingIncome />
          </Index.CustomTabPanel>
        </Index.Box>
      </Index.Box>

      {/* Withdraw Modal */}
      <Index.Modal
        open={openWithdraw}
        onClose={handleCloseWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Withdraw
          handleCloseWithdrawModal={handleCloseWithdrawModal}
          totalBalance={allIncomeDetails?.total_amount}
        />
      </Index.Modal>
    </div>
  );
}
