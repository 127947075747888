import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { useParams } from "react-router-dom";
import RoiTable from "./RoiTable";

function StakingDetails() {
  const token = Index.useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  const params = useParams();
  const location = Index.useLocation();

  const [filterData, setFilterData] = useState([]);
  const [stakingDetails, setStakingDetails] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roiDetails, setRoiDetails] = useState(location.state.roiData);

  // Pagination state
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // End region

  const getStakingDetails = async () => {
    setFilterData([]);
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", params?.stakId);
    urlencoded.append("pageNumber", page + 1);
    urlencoded.append("pageSize", rowsPerPage);
    await DataService(userToken)
      .post(API.User.GET_STACKING_LIST, urlencoded)
      .then((res) => {
        setFilterData(res.data.data?.data);
        setStakingDetails(res.data.data?.data);
        setCount(res.data.data?.totalPages);
        setTotalRecords(res.data.data?.recordCount);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getStakingDetails();
  }, [page, rowsPerPage]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const result = stakingDetails.filter((item) => {
      let date = Index.moment(item?.date)
        .format("DD-MM-YYYY")
        .toString()
        ?.includes(value);
      let ROI = item?.roi?.toString()?.includes(value);
      let stakId = item?.stack_id?.toString()?.includes(value);
      let dailyProfit = item?.daily_profit?.toString()?.includes(value);
      return date || ROI || stakId || dailyProfit;
    });
    setFilterData(result);
    setPage(0);
  };

  return (
    <Index.Box class="">
      <Index.Box className="setting-card">
        <Index.Box className="dashboard-content referral-list-tree-btn">
          <Index.Typography
            className="admin-page-title res-referral-list-tree-btn"
            component="h2"
            variant="h2"
          >
            Staking Details
          </Index.Typography>
        </Index.Box>

        <Index.Box className="admin-dash-box user-dash-box stack-details">
          <Index.Box className="referral-box">
            <Index.Box className="admin-sub-title-main referral-box-code admin-sub-title-main-user ">
              <Index.Typography
                className="admin-sub-title"
                component="h3"
                variant="h3"
              >
                Stake ID
              </Index.Typography>
            </Index.Box>
            <Index.Box className=" dash-token-value-box dash-token-box-set ">
              <Index.Typography
                className="user-value-box"
                component="h3"
                variant="h3"
              >
                {location?.state?.stakId}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="referral-box">
            <Index.Box className="admin-sub-title-main referral-box-code admin-sub-title-main-user ">
              <Index.Typography
                className="admin-sub-title"
                component="h3"
                variant="h3"
              >
                Amount
              </Index.Typography>
            </Index.Box>
            <Index.Box className=" dash-token-value-box dash-token-box-set">
              <Index.Typography
                className="user-value-box"
                component="h3"
                variant="h3"
              >
                {roiDetails?.from} - {roiDetails?.to}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="referral-box">
            <Index.Box className="admin-sub-title-main referral-box-code admin-sub-title-main-user ">
              <Index.Typography
                className="admin-sub-title"
                component="h3"
                variant="h3"
              >
                ROI (Yearly)
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <RoiTable data={roiDetails?.interest} />
          </Index.Box>
        </Index.Box>
        <Index.Box className="preasale-search stak-search-set">
          <Index.Box className="user-search-main cus-search">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => handleSearch(e)}
                />

                <img
                  src={Index.Svg.greysearch}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main ">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Stake Id
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              ROI (%)
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Daily Profit (USDT)
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            filterData.map((row, index) => {
                              return (
                                <Index.TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {row?.date
                                      ? Index.moment(row?.date).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td set-hover-text"
                                    align="right"
                                  >
                                    {row?.stack_id ? row?.stack_id : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td set-hover-text"
                                    align="right"
                                  >
                                    {row?.roi ? row?.roi : 0}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td set-hover-text"
                                    align="right"
                                  >
                                    {row?.daily_profit
                                      ? row?.daily_profit?.toFixed(2)
                                      : 0}
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={4}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={count}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(row) =>
                      // `Records: ${row.from} - ${
                      //   row.to === -1 ? row.count : row.to
                      // } of ${totalRecords}
                      //  Page: ${page + 1} of ${count}`
                      `Page: ${page + 1} of ${count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default StakingDetails;
