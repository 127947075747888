import React, { useState } from "react";
import Index from "../../../../Index";
import { ToastContainer, toast } from "react-toastify";
import copyTextToClipboard from "copy-text-to-clipboard";

const WaitlistTotalDeposit = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [filterData, setFilterData] = useState(data || []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSearch = (event) => {
    const result = data.filter((item) => {
      let network = Index.selectNetwork(item?.network_type);
      return (
        item?.transactionHash?.toString()?.includes(event?.target?.value) ||
        item?.value?.toString()?.includes(event?.target?.value) ||
        Index.moment(item?.createdAt)
          .format("DD-MM-YYYY hh:mm A")
          .toString()
          ?.includes(event?.target?.value) ||
        network?.toString()?.toLowerCase()?.includes(event?.target?.value)
      );
    });

    setFilterData(result);
    setPage(0);
  };

  const handleCopy = (data) => {
    copyTextToClipboard(data);

    toast.success("Hash Key Copied");
  };

  return (
    <div>
      <Index.Box className="tabpanel-main">
        <Index.Box className="">
          <Index.Box className="user-head-title mt-0">
            <Index.Box></Index.Box>
            <Index.Box className="transaction-right">
              <Index.Box className="preasale-search">
                <Index.Box className="user-search-main cus-search">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />

                      <img
                        src={Index.Svg.greysearch}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.Box className="page-table-main table-design-main transaction-table-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Date
                      </Index.TableCell>

                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Hash Key
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Network
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Amount($)
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {filterData.length > 0 ? (
                      filterData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          let network = Index.selectNetwork(row?.network_type);
                          let networkLink = Index.NetworkLink(network);
                          return (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.createdAt
                                  ? Index.moment(row?.createdAt).format(
                                      "DD-MM-YYYY hh:mm A"
                                    )
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                onClick={() => handleCopy(row?.transactionHash)}
                              >
                                <Index.Tooltip
                                  title={row?.transactionHash}
                                  arrow
                                  placement="top"
                                >
                                  <span>
                                    {" "}
                                    {row?.transactionHash
                                      ? `${row?.transactionHash?.slice(
                                          0,
                                          5
                                        )}.....${row?.transactionHash?.slice(
                                          -5
                                        )}`
                                      : "-"}
                                  </span>
                                </Index.Tooltip>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                <Index.Tooltip
                                  title={network}
                                  arrow
                                  placement="top"
                                >
                                  <span>
                                    {row?.network_type ? network : "-"}
                                  </span>
                                </Index.Tooltip>
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.value ? Number(row?.value).toFixed(2) : 0}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td email-ellip"
                                align="right"
                              >
                                <Index.Link to={networkLink} target="_blank">
                                  <Index.Tooltip
                                    title={"More Details"}
                                    arrow
                                    placement="top"
                                  >
                                    <span>More Details</span>
                                  </Index.Tooltip>
                                </Index.Link>
                              </Index.TableCell>
                            </Index.TableRow>
                          );
                        })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="table-td"
                          colSpan={5}
                        >
                          No Record found
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>{" "}
          <Index.Box className="pagination-main">
            <Index.TablePagination
              component="div"
              page={page}
              count={filterData?.length}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[15, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={(page) =>
                `Records ${page.from} to ${
                  page.to === -1 ? page.count : page.to
                } of ${page.count}`
              }
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default WaitlistTotalDeposit;
