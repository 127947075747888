import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginAdmin, getPresaleDetails } from "./AdminService";
import { registerAdmin, LoginUser } from "./AdminService";
import { getUser } from "./AdminService";

export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    admintoken: "",
    adminProfile: {},
    isAuthenticated: false,
    isUserAuthenticated: false,
    userToken: "",
    usersList: [],
    adminData: {},
    presaleList: [],
    getSettingDetails: {},
    userDetail: {},
    loginUserToken: "",
    userProfile: {},
    cmsDetails: {},
  },
  reducers: {
    adminProfile: (state, action) => {
      state.adminProfile = window.btoa(JSON.stringify(action.payload));
    },
    getSettingData: (state, action) => {
      state.getSettingDetails = window.btoa(JSON.stringify(action.payload));
    },
    getCMSDetails: (state, action) => {
      state.cmsDetails = action.payload;
    },
    adminLogout: (state, action) => {
      state.admintoken = "";
      state.isAuthenticated = false;
      state.adminData = {};
      state.adminProfile = {};
    },
    userProfile: (state, action) => {
      state.userProfile = window.btoa(JSON.stringify(action.payload));
    },
    userLogin: (state, action) => {
      state.loginUserToken = window.btoa(action?.payload?.data?.token);
      state.isUserAuthenticated = true;
      state.userDetail = window.btoa(JSON.stringify(action?.payload?.data));
    },
    userLogout: (state, action) => {
      state.loginUserToken = "";
      state.isUserAuthenticated = false;
      state.userDetail = {};
      state.userProfile = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginAdmin.fulfilled, (state, action) => {
      console.log(action.payload.data, "actions");
      state.isAuthenticated = true;
      state.admintoken = window.btoa(action.payload.data.token);
      state.adminData = window.btoa(JSON.stringify(action.payload.data));
    });
    builder.addCase(registerAdmin.fulfilled, (state, action) => {});
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.usersList.push(action.payload);
    });
    builder.addCase(getPresaleDetails.fulfilled, (state, action) => {
      state.presaleList = action.payload;
    });
    // builder.addCase(LoginUser.fulfilled, (state, action) => {
    //   state.isUserAuthenticated = true;
    //   state.loginUserToken = action.payload.data.token;
    //   state.userDetail = action.payload.data
    // });
  },
});

export const {
  adminProfile,
  // getUserData,
  userLogin,
  userProfile,
  getCMSDetails,
  adminLogout,
  getSettingData,
  userLogout,
} = AdminSlice.actions;

export default AdminSlice.reducer;
