import React, { useState } from "react";
import Index from "../../../../../component/Index";

import PropTypes from "prop-types";

import StakingDashboard from "./Dashboard";
import Staking from "./Staking";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StakingTab() {
  const [value, setValue] = useState(0);

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Index.Box className="stak-main tab-label-dash">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange2}
              aria-label="basic tabs example"
            >
              <Index.Tab label="Dashboard" {...a11yProps(0)} />
              <Index.Tab label="Stake" {...a11yProps(1)} />
              <Index.Tab label="Unstake" {...a11yProps(2)} />
            </Index.Tabs>
          </Index.Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="main-stack-tab-one"
          >
            <StakingDashboard />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="main-stack-tab-one"
          >
            <Staking />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="main-stack-tab-one"
          >
            <Index.Box className="stack-tab-two"></Index.Box>
          </CustomTabPanel>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
