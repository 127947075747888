import React from "react";
import Index from "../../../../Index";
import VerifiedIcon from "@mui/icons-material/Verified";

function WaitlistGeneralSetting({ data }) {
  let profileImg = data?.profile_image
    ? `${process.env.REACT_APP_IMAGE_URL}/${data?.profile_image}`
    : Index.Png.logo_new.png;
  return (
    <>
      <Index.Box
        className="userdetail-profilesec mt-25"
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
        }}
        // add-user-data-main
        noValidate
        autoComplete="off"
      >
        <Index.Box
          display="grid"
          className=""
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 1, sm: 1, md: 1, lg: 1 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 3",
              lg: "span 3",
            }}
            className="grid-column"
          >
            <Index.Box className="user-detail-profile-section">
              <Index.Box className="user-detail-profile-main">
                <img
                  src={profileImg ? profileImg : Index?.Png?.logo_new}
                  className="user-detail-profile"
                />
              </Index.Box>
              <Index.Box>
                <Index.Box className="user-details-name">
                  {/* <Index.Typography variant="p" component="p" className="detialform-title">Name :</Index.Typography> */}
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="verified-user"
                  >
                    {data?.first_name + " " + data?.last_name}{" "}
                    {data?.is_verified && <VerifiedIcon color="primary" />}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 9",
              lg: "span 9",
            }}
            className="grid-column"
          >
            <Index.Box
              display="grid"
              className=""
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 1, sm: 1, md: 1, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box ">
                  <Index.Box className="detailform-row">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detialform-title"
                    >
                      Email :{" "}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detailform-content"
                    >
                      {data?.email ? data?.email : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box ">
                  <Index.Box className="detailform-row">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detialform-title"
                    >
                      {" "}
                      Username :{" "}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detailform-content"
                    >
                      {data?.user_name ? data?.user_name : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box ">
                  <Index.Box className="detailform-row">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detialform-title"
                    >
                      {" "}
                      Mobile No :{" "}
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detailform-content"
                    >
                      {data?.mobile_number ? data?.mobile_number : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box input-box-admin input-box-admin2">
                  <Index.Box className="detailform-row">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detialform-title"
                    >
                      {" "}
                      Left Referral :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detailform-content"
                    >
                      {data?.left_referral
                        ? Index.ReplaceCharsWithAsterisks(data?.left_referral)
                        : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box input-box-admin input-box-admin2">
                  <Index.Box className="detailform-row">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detialform-title"
                    >
                      {" "}
                      Right Referral :
                    </Index.Typography>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="detailform-content"
                    >
                      {" "}
                      {data?.right_referral
                        ? Index.ReplaceCharsWithAsterisks(data?.right_referral)
                        : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default WaitlistGeneralSetting;
