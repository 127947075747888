import React from 'react'
import Index from "../Index";


export default function Loader() {
    return (
        <div>
            <Index.Box className="loader-bg-main">
                {/* <Index.Box className="loader-inner-bg-main">
                    <Index.Box className="loader-inner-border">
                    </Index.Box>
                    <img src={Index.Png.logo_new} className='logo-small-loader'></img>
                </Index.Box> */}
                <Index.Box className="f-direction">
                    <Index.Box className="login-logo-details logo-anim position-relative">
                        {/* <img src={Index.Svg.biground} className="roundmainimg" /> */}
                        <img src={Index.Svg.middleround} className="headerroundmainimg2 roundmainimg2" />
                        <img src={Index.Svg.smallround} className="headerroundmainimg3 roundmainimg3" />

                        <Index.Box className="coin-image">
                            <img src={Index.Png.logo_new} className="headerlogo-login logo-login"></img>
                        </Index.Box>

                    </Index.Box>

                </Index.Box>
            </Index.Box>

        </div>
    )
}
