import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import Loader from "../../../../component/common/Loader";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function Waitlist() {
  const navigate = useNavigate();
  const location = useLocation();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  // All states
  const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [ExportDetails, setExportDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [transactionType, setTransactionType] = useState(2);
  const [activeClass, setActiveClass] = useState(null);
  const [toggleArrow, setToggleArrow] = useState({
    createdAt: false,
    user_name: false,
    email: false,
  });

  // Start Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // End Pagination

  const handleClick = (divIndex) => {
    setActiveClass(divIndex);
  };

  let matchedId = (arr, Id) => {
    let userId = {};
    arr.map((row, index) => {
      if (row?.user_id == Id) {
        userId = {
          Id: Id,
          index: index,
        };
      }
    });
    return userId;
  };

  const exportData = (arr1, arr2) => {
    let tempArr = [];
    arr1?.map((ele, index) => {
      let waitlistObj = {};

      let matchUser = matchedId(arr2, ele?.user_id?._id);

      if (ele?.user_id?._id == matchUser.Id) {
        waitlistObj = {
          Date: Index.moment(ele?.user_id?.createdAt)
            .utcOffset("+05:30")
            .format("DD-MM-YYYY hh:mm A"),
          Email: ele?.user_id?.email ? ele?.user_id?.email : "-",
          UserName: ele?.user_id?.user_name ? ele?.user_id?.user_name : "-",
          Left_Referral: ele?.user_id?.left_referral
            ? ele?.user_id?.left_referral
            : "-",
          Right_Referral: ele?.user_id?.right_referral
            ? ele?.user_id?.right_referral
            : "-",
          Transaction: arr2[matchUser?.index]?.transaction
            ? arr2[matchUser?.index]?.transaction
            : 0,
          Deposit: arr2[matchUser?.index]?.count
            ? arr2[matchUser?.index]?.count
            : 0,
          Members:
            ele?.left || ele?.right
              ? ele?.left?.length + ele?.right?.length
              : 0,
        };
      } else {
        waitlistObj = {
          Date: Index.moment(ele?.user_id?.createdAt)
            .utcOffset("+05:30")
            .format("DD-MM-YYYY hh:mm A"),
          Email: ele?.user_id?.email ? ele?.user_id?.email : "-",
          UserName: ele?.user_id?.user_name ? ele?.user_id?.user_name : "-",
          Left_Referral: ele?.user_id?.left_referral
            ? ele?.user_id?.left_referral
            : "-",
          Right_Referral: ele?.user_id?.right_referral
            ? ele?.user_id?.right_referral
            : "-",
          Transaction: arr2[matchUser?.index]?.transaction
            ? arr2[matchUser?.index]?.transaction
            : 0,
          Deposit: arr2[matchUser?.index]?.count
            ? arr2[matchUser?.index]?.count
            : 0,
          Members:
            ele?.left || ele?.right
              ? ele?.left?.length + ele?.right?.length
              : 0,
        };
      }
      tempArr.push(waitlistObj);
    });
    console.log(tempArr, ":tempArr");
    setExportDetails(tempArr);
  };

  const getWaitlist = async () => {
    setLoader(true);
    await DataService(token)
      .get(API.Admin.GET_WAITLIST)
      .then((res) => {
        let temArr = [];

        // Modify Data for Export
        exportData(res.data.data, res?.data?.transaction);

        // Modify Data for Show Transaction as per User
        res.data.data?.map((ele, index) => {
          let waitlistObj = {};
          let matchUser = matchedId(res?.data?.transaction, ele?.user_id?._id);

          if (ele?.user_id?._id == matchUser.Id) {
            waitlistObj = {
              ...ele,
              transactionAmount: res?.data?.transaction[matchUser?.index],
            };
          } else {
            waitlistObj = {
              ...ele,
              transactionAmount: "",
            };
          }
          temArr.push(waitlistObj);
        });

        setUsers(temArr);
        setFilterData(temArr);
        setLoader(false);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");

        setLoader(false);
      });
    setLoader(false);
  };

  useEffect(() => {
    getWaitlist();
  }, []);

  // Filter with transaction Type
  const handleFilter = (val) => {
    setTransactionType(val);

    let tempArr = [];

    users?.filter((item) => {
      if (item?.transactionAmount?.count == undefined && val == 4) {
        tempArr.push(item);
      } else if (item?.transactionAmount?.count !== undefined && val == 1) {
        tempArr.push(item);
      } else if (val == 2) {
        tempArr.push(item);
      } else if (
        Index.moment(item?.user_id?.createdAt).format("DD-MM-YYYY") ==
          Index.moment().format("DD-MM-YYYY") &&
        val == 3
      ) {
        tempArr.push(item);
      }
    });
    setFilterData(tempArr);
  };

  useEffect(() => {
    if (location?.state?.filterValue && users?.length > 0) {
      setTransactionType("");
      handleFilter(location?.state?.filterValue);
    }
  }, [location?.state?.filterValue, users]);

  // Copy Referral code
  const handleCopy = (data, type) => {
    Index.copyTextToClipboard(
      `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?${type}-Referral=${data}`
    );
    Index.toast.success("Referral link Copied");
  };

  // Sent mail to user
  const handleSend = async (userid) => {
    const urlendcoded = new URLSearchParams();
    urlendcoded.append("id", userid);
    await DataService(token)
      .post(API.Admin.SEND_MAIL, urlendcoded)
      .then((res) => {
        Index.toast.success(res.data.message);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  // User Disable
  const userDisable = async (data) => {
    console.log(data, ":datadatadata");
    const formData = new URLSearchParams();
    formData.append("_id", data?.id);
    formData.append("flag", data?.status);
    await DataService(token)
      .post(API.Admin.DISABLE_USER, formData)
      .then((response) => {
        if (response.data.status == 200) {
          console.log("reRender");
          Index.toast.success(response.data.message);
        }
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
    getWaitlist();
  };

  // Search Functionality
  const handleSearch = (event) => {
    const { value } = event.target;

    const result = users.filter((item) => {
      let userName = item?.user_id?.user_name
        ?.toLowerCase()
        ?.toString()
        ?.includes(value.toLowerCase());
      let uid = item?.user_id?.uid?.toString()?.includes(value);
      let email = item?.user_id?.email
        ?.toLowerCase()
        ?.toString()
        ?.includes(value?.toLowerCase());
      let leftReferral = item?.user_id?.left_referral
        ?.toString()
        ?.includes(value);
      let rightReferral = item?.user_id?.right_referral
        ?.toString()
        ?.includes(value);
      let transactionAmount = item?.transactionAmount?.count
        ?.toString()
        ?.includes(value);

      return (
        userName ||
        uid ||
        email ||
        leftReferral ||
        rightReferral ||
        transactionAmount
      );
    });
    setFilterData(result);
    setPage(0);
  };

  const handleDescending = (arr, field, subField) => {
    setToggleArrow({ ...toggleArrow, [subField]: !toggleArrow[subField] });
    let res = arr.sort(Index.sortInDescending(field, subField));
    setFilterData(res);
  };

  const handleAscending = (arr, field, subField) => {
    setToggleArrow({ ...toggleArrow, [subField]: !toggleArrow[subField] });
    let res = arr.sort(Index.sortInAscending(field, subField));
    setFilterData(res);
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Index.Box className="dashboard-content add-user-containt">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              Waitlist User
            </Index.Typography>

            <Index.Box className="preasale-search">
              <Index.Box className="waitlist-drop mr-15">
                <Index.Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={transactionType}
                  label="Age"
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  <Index.MenuItem value={2}>All</Index.MenuItem>
                  <Index.MenuItem value={1}>Deposit</Index.MenuItem>
                  <Index.MenuItem value={4}>Non-Deposit</Index.MenuItem>
                  <Index.MenuItem value={3}>Today's New Joining</Index.MenuItem>
                </Index.Select>
              </Index.Box>
              <Index.Box className="user-search-main cus-search">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <img
                      src={Index.Svg.greysearch}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                className="adduser-btn-main btn-main-primary exp-btn exp-btn2 "
                mr={3}
              >
                <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                  <Index.Link
                    className="add-user-btn  btn-dark-comman-main"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      Index.Export_excel(
                        ExportDetails,
                        `Waitlist List (${curDate})`
                      );
                    }}
                  >
                    Export CSV
                  </Index.Link>
                </Index.Box>
              </Index.Box>
              {/* <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
                <Index.Link
                  to="/admin/add-user"
                  className="add-user-btn  btn-dark-comman-main"
                >
                  <Index.AddIcon /> Add
                </Index.Link>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="">
                    <Index.Box className="page-table-main table-design-main waitlist-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th "
                                onClick={() => handleClick(1)}
                              >
                                <Index.Box className="create-hover">
                                  Created Date{" "}
                                  <span
                                    class={
                                      activeClass == 1
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {" "}
                                    {toggleArrow?.createdAt ? (
                                      <ArrowUpwardIcon
                                        onClick={() => {
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "createdAt"
                                          );
                                        }}
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "createdAt"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                UID
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(2)}
                              >
                                <Index.Box className="create-hover">
                                  User name{" "}
                                  <span
                                    class={
                                      activeClass == 2
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {" "}
                                    {toggleArrow?.user_name ? (
                                      <ArrowUpwardIcon
                                        onClick={() => {
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "user_name"
                                          );
                                        }}
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "user_name"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                onClick={() => handleClick(3)}
                              >
                                <Index.Box className="create-hover">
                                  Email{" "}
                                  <span
                                    class={
                                      activeClass == 3
                                        ? ""
                                        : "create-hover-span"
                                    }
                                  >
                                    {toggleArrow?.email ? (
                                      <ArrowUpwardIcon
                                        onClick={() =>
                                          handleDescending(
                                            filterData,
                                            "user_id",
                                            "email"
                                          )
                                        }
                                      />
                                    ) : (
                                      <ArrowDownwardIcon
                                        onClick={() =>
                                          handleAscending(
                                            filterData,
                                            "user_id",
                                            "email"
                                          )
                                        }
                                      />
                                    )}
                                  </span>
                                </Index.Box>
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Left Referral
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Right Referral
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Transaction
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Deposit
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Member
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="right"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData?.length > 0 ? (
                              filterData
                                ?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                  return (
                                    <Index.TableRow
                                      key={row?.user_id?.user_name}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        {row?.user_id?.createdAt
                                          ? Index.moment(
                                              row?.user_id?.createdAt
                                            )
                                              .utcOffset("+05:30")
                                              .format("DD-MM-YYYY hh:mm A")
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_id?.uid}
                                          arrow
                                          placement="top"
                                        >
                                          <Index.Box className="email-ellip">
                                            <Index.Link
                                              to={`/admin/waitlist-details/${row?.user_id?._id}`}
                                            >
                                              {" "}
                                              {row?.user_id?.uid
                                                ? row?.user_id?.uid
                                                : "-"}
                                            </Index.Link>
                                          </Index.Box>
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_id?.user_name}
                                          arrow
                                          placement="top"
                                        >
                                          <Index.Link
                                            to={`/admin/waitlist-details/${row?.user_id?._id}`}
                                          >
                                            {row?.user_id?.user_name
                                              ? row?.user_id?.user_name
                                              : "-"}
                                          </Index.Link>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip
                                          title={row?.user_id?.email}
                                          arrow
                                          placement="top"
                                        >
                                          <Index.Box className="email-ellip">
                                            <Index.Link
                                              to={`/admin/waitlist-details/${row?.user_id?._id}`}
                                            >
                                              {" "}
                                              {row?.user_id?.email
                                                ? row?.user_id?.email
                                                : "-"}
                                            </Index.Link>
                                          </Index.Box>
                                        </Index.Tooltip>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip title="Copy">
                                          <Index.Typography
                                            className="set-hover-text"
                                            variant="p"
                                            onClick={() =>
                                              handleCopy(
                                                row?.user_id?.left_referral,
                                                "Left"
                                              )
                                            }
                                          >
                                            {Index.ReplaceCharsWithAsterisks(
                                              row?.user_id?.left_referral
                                            )}{" "}
                                          </Index.Typography>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                      >
                                        <Index.Tooltip title="Copy">
                                          <Index.Typography
                                            className="set-hover-text"
                                            variant="p"
                                            onClick={() =>
                                              handleCopy(
                                                row?.user_id?.right_referral,
                                                "Right"
                                              )
                                            }
                                          >
                                            {Index.ReplaceCharsWithAsterisks(
                                              row?.user_id?.right_referral
                                            )}{" "}
                                          </Index.Typography>
                                        </Index.Tooltip>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.transactionAmount
                                          ? row?.transactionAmount?.transaction
                                          : 0}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.transactionAmount
                                          ? row?.transactionAmount?.count?.toFixed(
                                              2
                                            )
                                          : 0}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        {row?.left?.length + row?.right?.length}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="right"
                                      >
                                        <Index.IconButton
                                          onClick={() => {
                                            navigate(
                                              `/admin/edit-user/${row?.user_id?._id}`
                                            );
                                          }}
                                        >
                                          <img src={Index.Svg.blueEdit}></img>
                                        </Index.IconButton>
                                        <Index.IconButton
                                          onClick={() => {
                                            handleSend(row?.user_id?._id);
                                          }}
                                        >
                                          <Index.MailOutlineIcon className="email-icon" />
                                        </Index.IconButton>

                                        <Index.IconButton>
                                          <Index.FormControlLabel
                                            control={
                                              <Index.IOSSwitch
                                                checked={
                                                  !row?.user_id?.is_disable
                                                }
                                                sx={{ m: 1 }}
                                                className="MuiSwitch-track-active"
                                              />
                                            }
                                            onClick={() => {
                                              const temp = {
                                                id: row?.user_id._id,
                                                status:
                                                  !row?.user_id?.is_disable,
                                              };

                                              userDisable(temp);
                                              // setActive(!active);
                                            }}
                                            className="switch-lable"
                                          />
                                        </Index.IconButton>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  colSpan={9}
                                >
                                  No Record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>{" "}
                  <Index.Box className="pagination-main">
                    <Index.TablePagination
                      component="div"
                      page={page}
                      count={filterData?.length}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[15, 25, 50]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelDisplayedRows={(page) =>
                        `Records ${page.from} to ${
                          page.to === -1 ? page.count : page.to
                        } of ${page.count}`
                      }
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </div>
  );
}
