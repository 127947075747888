import React, { useState } from "react";
import Index from "../../../../component/Index";
import Referral from "./Referral";
import MyTeamReferral from "./MyTeamReferral";



export default function ReferralTab() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="My Team Referrals"
                  {...Index.a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="My Referrals"
                  {...Index.a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
              </Index.Tabs>
            </Index.Box>
            <Index.CustomTabPanel value={value} index={0} className="admin-tabpanel">
              <Referral />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel value={value} index={1} className="admin-tabpanel">
              <MyTeamReferral />
            </Index.CustomTabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
