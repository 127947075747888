import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const UserDetails = () => {
  const [user, setUser] = useState({});

  const params = useParams();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const getUserDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", params?.userId?.trim());
    await DataService(token)
      .post(API.Admin.GET_USER, urlencoded)
      .then((res) => {
        setUser(res?.data?.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <Index.Box className="custom-card">
        <Index.Box className="dashboard-content add-user-containt">
          <Index.Box className="user-head-title">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              User Details
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box
          className="userdetail-profilesec"
          component="form"
          sx={{
            "& > :not(style)": { m: 1 },
          }}
          // add-user-data-main
          noValidate
          autoComplete="off"
        >
          <Index.Box
            display="grid"
            className=""
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 1, sm: 1, md: 1, lg: 1 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 3",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="user-detail-profile-section">
                <Index.Box className="user-detail-profile-main">
                  <img
                    src={Index.Png.usericon}
                    className="user-detail-profile"
                  />
                </Index.Box>
                <Index.Box>
                  <Index.Box className="user-details-name">
                    {/* <Index.Typography variant="p" component="p" className="detialform-title">Name :</Index.Typography> */}
                    <Index.Typography variant="p" component="p" className="">
                      {user?.first_name ? user?.first_name : "-"}{" "}
                      {user?.last_name ? user?.last_name : "-"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 9",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box
                display="grid"
                className=""
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 1, sm: 1, md: 1, lg: 1 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box ">
                    <Index.Box className="detailform-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detialform-title"
                      >
                        Email :{" "}
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detailform-content"
                      >
                        {user?.email ? user?.email : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box ">
                    <Index.Box className="detailform-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detialform-title"
                      >
                        {" "}
                        Username :{" "}
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detailform-content"
                      >
                        {user?.user_name ? user?.user_name : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box ">
                    <Index.Box className="detailform-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detialform-title"
                      >
                        {" "}
                        Mobile No :{" "}
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detailform-content"
                      >
                        {user?.mobile_number ? user?.mobile_number : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box input-box-admin input-box-admin2">
                    <Index.Box className="detailform-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detialform-title"
                      >
                        {" "}
                        Left Referral :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detailform-content"
                      >
                        {user?.left_referral ? user?.left_referral : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box input-box-admin input-box-admin2">
                    <Index.Box className="detailform-row">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detialform-title"
                      >
                        {" "}
                        Right Referral :
                      </Index.Typography>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="detailform-content"
                      >
                        {" "}
                        {user?.right_referral ? user?.right_referral : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 9",
            }}
            className="grid-column"
          >
            <Index.Box className="mt-25">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        USERNAME
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                      >
                        Email
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    <Index.TableRow
                      // key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    ></Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      xxcv
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="table-td"
                    >
                      dfsd
                    </Index.TableCell>
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserDetails;
