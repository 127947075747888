import React from "react";
import Index from "../../Index";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { Formik, Form } from "formik";
import { ForgotPasswordSchema } from "../../../validations/ValidationSchema";

export default function ForgotPassword() {
  const initialValues = {
    email: "",
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email?.trim());
    await DataService()
      .post(API.Admin.FORGOT_PASSWORD, urlencoded)
      .then((response) => {
        Index.toast.success(" Mail has been sent to your registered account");
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Png.logo_new}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>

              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={ForgotPasswordSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Index.Box className="card-login-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Forgot Password
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          className="form-lable"
                          style={{ marginTop: 36 }}
                        >
                          Enter your email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="email"
                            onBlur={handleBlur}
                            value={values.email}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main login-btn-main">
                        <Index.Button
                          className="btn login-btn"
                          onClick={handleSubmit}
                          type="button"
                          disableRipple
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
