import React, { useState, useEffect } from "react";
import Index from "../../../../Index";
import PresaleDashboard from "./Dashboard";
import PresaleTotalPurchase from "./TotalPurchase";
import PresaleTotalSale from "./TotalSale";
import { useParams } from "react-router-dom";
import { API } from "../../../../../config/Api";
import { DataService } from "../../../../../config/DataService";
import PresaleTotalTransction from "./TotalTransaction";
import PresaleTotalDeposit from "./TotalDeposit";

export default function PresaleTab() {
  const params = useParams();
  const AdminToken = Index.useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [value, setValue] = useState(0);
  const [presaleDetail, setPresaleDetail] = useState({});
  const [transactionList, setTransactionList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSingleData = async () => {
    const formData = new URLSearchParams();
    formData.append("id", params?.presaleId?.trim());
    await DataService(token)
      .post(API.Admin.GET_SINGLE_PRIVATE_SALE, formData)
      .then((response) => {
        setPresaleDetail(response?.data?.data);
        setTransactionList(response?.data?.data?.transactionList);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getSingleData();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="Dashboard"
                  {...Index.a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label=" Transaction"
                  {...Index.a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label=" Deposit"
                  {...Index.a11yProps(2)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label=" Purchase"
                  {...Index.a11yProps(3)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label=" Sale"
                  {...Index.a11yProps(4)}
                  className="admin-tab"
                  disableRipple
                />
              </Index.Tabs>
            </Index.Box>
            <Index.CustomTabPanel
              value={value}
              index={0}
              className="admin-tabpanel"
            >
              <PresaleDashboard data={presaleDetail} />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel
              value={value}
              index={1}
              className="admin-tabpanel"
            >
              <PresaleTotalTransction data={transactionList} />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel
              value={value}
              index={2}
              className="admin-tabpanel"
            >
              <PresaleTotalDeposit data={transactionList} />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel
              value={value}
              index={3}
              className="admin-tabpanel"
            >
              <PresaleTotalPurchase />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel
              value={value}
              index={4}
              className="admin-tabpanel"
            >
              <PresaleTotalSale />
            </Index.CustomTabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
