import React, { useEffect, useState } from "react";
import Index from "../../../../../Index";
import { DataService } from "../../../../../../config/DataService";
import { API } from "../../../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { getSettingData } from "../../../../../../redux/features/slices/Admin/AdminSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const Withdraw = ({ handleCloseWithdrawModal, totalBalance }) => {
  const token = useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  const dispatch = useDispatch();

  const [userType, setUserType] = useState("self");
  const [username, setUserName] = useState("");
  const [amount, setAmount] = useState("");
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [verifiedUserId, setVerifiedUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [convertTokenUsdt, setConvertTokenUsdt] = useState(0);

  const [userNameError, setUserNameError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [settingDetails, setSettingDetails] = useState("");
  const [showWithdrawFeesMsg, setShowWithdrawFeesMsg] = useState("");

  const getSettingDetails = async () => {
    await DataService(userToken)
      .get(API.User.GET_SETTING)
      .then((res) => {
        setSettingDetails(res?.data?.data);
        dispatch(getSettingData(res.data.data));
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getSettingDetails();
  }, []);

  const handleUserName = (e) => {
    setUserName(e.target.value);
    setIsUserVerified(false);
    setUserNameError("");
    setIsUserVerified("");
  };

  const handleAmount = (e) => {
    const { value } = e.target;

    let withdrawFees = settingDetails?.withdraw_fees;
    let amountCut = (Number(value) * withdrawFees) / 100;
    if (value > totalBalance) {
      setAmountError("You do not have sufficient balance to withdraw");
    } else {
      setAmount(value);
      if (userType == "self") {
        setConvertTokenUsdt(
          (Number(value) - amountCut) / settingDetails?.coin_price
        );
      } else {
        setConvertTokenUsdt(Number(value) / settingDetails?.coin_price);
      }

      setAmountError("");
    }
  };

  const VerifyUser = async () => {
    setLoading(true);
    if (username) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("user_name", username);
      await DataService(userToken)
        .post(API.User.WITHDRAW_VERIFY_USER, urlencoded)
        .then((response) => {
          setTimeout(() => {
            if (response.data.status == 200) {
              setVerifiedUserId(response?.data?.data?._id);
              setIsUserVerified(true);
              setLoading(false);
            }
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          setIsUserVerified(false);
          setUserNameError("Username is not Verified");
          setLoading(false);
          Index.toast.error(error?.response?.data?.message);
        });
    } else {
      setUserNameError("Please Enter UserName");
    }
  };

  const handleSelfSubmit = () => {
    if (amount) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("type", userType);
      urlencoded.append("withdraw_amount", amount);
      DataService(userToken)
        .post(API.User.WITHDRAW_AMOUNT, urlencoded)
        .then((res) => {
          if ((res.data.status = 201)) {
            Index.toast.success(res.data.message);
            handleCloseWithdrawModal();
          }
        })
        .catch((error) => {
          Index.toast.error(error.response?.data?.message);

          handleCloseWithdrawModal();
        });
    } else {
      if (!amount) {
        setAmountError("Please Enter Amount");
      }
    }
  };

  const handleOtherSubmit = () => {
    if (amount && verifiedUserId) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("type", userType);
      if (userType == "other") {
        urlencoded.append("transfer_to", verifiedUserId);
      }
      urlencoded.append("withdraw_amount", amount);
      DataService(userToken)
        .post(API.User.WITHDRAW_AMOUNT, urlencoded)
        .then((res) => {
          if ((res.data.status = 201)) {
            Index.toast.success(res.data.message);
            handleCloseWithdrawModal();
          }
        })
        .catch((error) => {
          Index.toast.error(error.response?.data?.message);

          handleCloseWithdrawModal();
        });
    } else {
      if (!amount) {
        setAmountError("Please Enter Amount");
      }
      if (!verifiedUserId) {
        setUserNameError("Please verify user first");
      }
    }
  };
  return (
    <div>
      <Index.Box sx={style} className="stack-modal-wid">
        <Index.Box className="close-modal">
          <Index.CloseIcon onClick={(e) => handleCloseWithdrawModal()} />
        </Index.Box>
        <Index.Box className="Withdraw-header">
          <Index.Box className="with-head-both">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h3"
                variant="h3"
              >
                {" "}
                Withdraw
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="Withdraw-para-price">
            <Index.Typography
              className="Withdraw-para-price-set withdraw-main-text"
              variant="p"
              component="p"
            >
              1 Token = {Number(settingDetails?.coin_price).toFixed(2)} USDT
            </Index.Typography>
            <Index.Typography
              className="withdraw-main-text withdraw-main-line"
              variant="p"
              component="p"
            >
              Total Balance = {totalBalance.toFixed(2)} USDT
            </Index.Typography>
          </Index.Box>
          <Index.Box className="Withdraw-para-price">
            <Index.FormControl>
              <Index.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userType}
                onChange={(e) => {
                  setUserType(e.target.value);
                  setAmountError("");
                }}
              >
                <Index.FormControlLabel
                  value="self"
                  control={<Index.Radio />}
                  label="Self"
                />
                <Index.FormControlLabel
                  value="other"
                  control={<Index.Radio />}
                  label="Others"
                />
              </Index.RadioGroup>
            </Index.FormControl>
          </Index.Box>
          <Index.Box className="check-one-main">
            <Index.Box className="Withdraw-modal">
              {userType == "other" && (
                <Index.Box>
                  <Index.Box className="withdraw-main">
                    <Index.Box className="">
                      <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                        <Index.Box className="verify-user">
                          <Index.FormHelperText className="modal-text mb-15">
                            User
                          </Index.FormHelperText>
                          {username && (
                            <a
                              href="#"
                              className={
                                isUserVerified
                                  ? "modal-text verify-success mb-15"
                                  : "modal-text verify-link mb-15"
                              }
                              onClick={(e) => VerifyUser()}
                            >
                              {loading ? (
                                <TailSpin
                                  height="20"
                                  width="20"
                                  radius="9"
                                  color="green"
                                  ariaLabel="loading"
                                  wrapperStyle
                                  wrapperClass
                                />
                              ) : (
                                <span>
                                  {isUserVerified ? "Verified" : "Verify"}
                                </span>
                              )}
                            </a>
                          )}
                        </Index.Box>
                        <Index.Box className="form-group" mb={2}>
                          <Index.TextField
                            fullWidth
                            className="form-control"
                            name="username"
                            type="text"
                            value={username}
                            onChange={(e) => handleUserName(e)}
                          />
                        </Index.Box>
                        <Index.ErrorShow error={userNameError} />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )}

              <Index.Box className="withdraw-main">
                <Index.Box className="">
                  <Index.Box className="input-box input-box-admin input-box-admin2 join-wait-input ">
                    <Index.Box className="verify-user">
                      <Index.FormHelperText className="modal-text mb-15">
                        Amount (USDT)
                      </Index.FormHelperText>
                    </Index.Box>
                    <Index.Box className="form-group" mb={2}>
                      <Index.TextField
                        fullWidth
                        className="form-control"
                        name="amount"
                        type="number"
                        value={amount}
                        onChange={(e) => handleAmount(e)}
                      />
                    </Index.Box>
                    <Index.ErrorShow error={amountError} />
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {userType == "self" && (
                <Index.Box className="verify-user show-balance" mt={2}>
                  <Index.Typography
                    className="admin-sub-title  withdraw-note"
                    variant="p"
                    component={"p"}
                  >
                    Withdrawal Fees ({settingDetails?.withdraw_fees}%)
                  </Index.Typography>
                </Index.Box>
              )}

              <Index.Box className="verify-user show-balance">
                <Index.Typography
                  className="admin-sub-title  withdraw-note"
                  variant="p"
                  component={"p"}
                >
                  Transfer Tokens : {Number(convertTokenUsdt)?.toFixed(2)}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="content btn-m-auto">
                <Index.Box className="primary-border-btn-main  primary-btn-main-comman">
                  <Index.Button
                    className="primary-border-btn history-btn deposit-link-btn-dark-comman-main"
                    onClick={(e) =>
                      userType == "other"
                        ? handleOtherSubmit(e)
                        : handleSelfSubmit(e)
                    }
                  >
                    {userType == "other" ? "Transfer" : "Withdraw"}
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Withdraw;
