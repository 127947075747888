export const NetworkLink = (key) => {
  let scannerLink = "";
  switch (key) {
    case "Polygon":
      scannerLink = "https://polygonscan.com/";
      break;

    case "Ethereum":
      scannerLink = "https://etherscan.io/";
      break;

    case "BNB Smart Chain":
      scannerLink = "https://bscscan.com/";
      break;

    case "TRON":
      scannerLink = "https://tronscan.org/#/";
      break;

    default:
      return false;
  }

  return scannerLink ;
};


export const selectNetwork = (value) => {
  let name = "";
  switch (value) {
    case '137':
      name = "Polygon";
      break;
    case '1':
      name = "Ethereum";
      break;
    case '56':
      name = "BNB Smart Chain";
      break;
    case '5':
      name = "TRON";
      break;
    default:
      name = value
      break ;
  }
  return name ;
};