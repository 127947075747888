let admin = "/admin";
let user = "/user";
const API = {
  Admin: {
    ADMIN_LOGIN: `${admin}/login`,
    FORGOT_PASSWORD: `${admin}/forgot-password`,
    ADMIN_RESET_PASSWORD: `${admin}/reset-password`,

    GET_USER_LIST: `${admin}/getuser-list`,
    GET_SINGLE_USER_INFO: `${admin}/fetch-personal-info`,
    USER_REGISTER: `${user}/register`,
    USER_ADD_EDIT: `${admin}/add-edit-user`,
    USER_REMOVE: `${admin}/remove-user`,
    CHANGE_PASSWORD: `${admin}/change-password`,
    PROFILE_DATA: `${admin}/get-profile`,
    COUNTRY_LIST: `${admin}/get-country-list`,

    ADD_EDIT_PRIVATE_SALE: `${admin}/add-edit-private-sale`,
    GET_PRIVATE_SALE_LIST: `${admin}/get-private-sale`,
    GET_SINGLE_PRIVATE_SALE: `${admin}/get-private-sale-id`,
    REMOVE_PRIVATE_SALE: `${admin}/remove-Private-Sale`,
    GET_WAITLIST: `${admin}/get-waitlist`,
    SEND_MAIL: `${admin}/mail-send`,
    EDIT_PROFILE: `${admin}/edit-details`,
    GET_ADMIN_PROFILE: `${admin}/details`,
    ADD_USER: `${admin}/add-user`,
    EDIT_USER: `${admin}/edit-user`,
    GET_USER: `${admin}/get-user`,
    DISABLE_USER: `${admin}/delete-user`,
    ADMIN_SETTING: `${admin}/setting`,
    GET_SETTING: `${admin}/get-setting`,
    GET_TOTAL_TOKENS: `${admin}/total-value`,
    GET_TRANSCATOIN_LIST: `${admin}/transaction`,
    ADD_EDIT_CMS: `${admin}/add-edit-cms`,
    GRAPH_DETAILS: `${admin}/graph`,
    USER_ALL_DETAILS: `${admin}/user-details`,
    KYC_LIST: `${admin}/kyc-list`,
    ADD_EDIT_KYC: `${admin}/add-edit-kyc`,
    KYC_DETAILS: `${admin}/kyc-details`,
    KYC_VERIFICATION: `${admin}/kyc-verification`,
    WITHOUT_REFERRAL_LIST: `${admin}/without-referral-list`,
    UPDATE_MAIN_ID: `${admin}/update-main-id`,
    ALL_STACK_LIST: `${admin}/staking-list`,
    WITHDRAW_REQUEST_LIST: `${admin}/withdraw-list`,
    ACCEPT_REJECT_WITHDRAW_REQUEST: `${admin}/action-withdraw`,
    ENABLE_DISABLE_TOKEN: `${admin}/tokens-action`,
    ENABLE_DISABLE_CATEGORY: `${admin}/category-action`,
    GET_CATEGORY: `${admin}/get-category`,
    GET_CATEGORY_DATA: `${admin}/get-tokens`,
    SYNC_TOKEN: `${admin}/sync-tokens`,
    UPDATE_TOKEN_TRADING_SYMBOL: `${admin}/update-token`,
  },
  User: {
    USER_FORGOT_PASSWORD: `${user}/forgot-password`,
    USER_RESET_PASSWORD: `${user}/reset-password`,
    USER_OTP: `${user}/verify-otp`,
    USER_CHANGE_PASSWORD: `${user}/change-password`,
    GET_WAITLIST_USER: `${admin}/get-private-sale-user`,
    JOIN_WAITLIST: `${user}/sign-up`,
    USER_LOGIN: `${user}/login`,
    GET_SETTING: `${user}/get-setting`,
    GET_REFERRAL_COUNT: `${user}/referral-count`,
    VERIFY_TRANSACTION: `${user}/transaction`,
    GET_USER: `${user}/details`,
    GET_TREE_DATA: `${user}/get-data`,
    GET_TOTAL_TOKENS: `${user}/total-value`,
    GET_REFERRAL_LIST: `${user}/referral-list`,
    GET_TOTAL_REFERRAL: `user/referral-total`,
    USER_PROFILE: `${user}/edit-profile`,
    USRT_MAIN_REFERRAL: `${user}/main-referral`,
    ADD_EDIT_KYC: `${user}/add-edit-kyc`,
    KYC_DETAILS: `${user}/kyc-details`,
    DIRECT_REFERR_USER_LIST: `${user}/direct-refer-user`,
    GET_STACKING_LIST: `${user}/staking-data`,
    STACK_INCOME_LIST: `${user}/stak-data`,
    GET_BONUS_LIST: `${user}/bonus-list`,
    GET_ALL_INCOME_LIST: `${user}/wallet`,
    WITHDRAW_VERIFY_USER: `${user}/verify-user`,
    WITHDRAW_AMOUNT: `${user}/withdraw`,
    WITHDRAW_LIST: `${user}/withdraw-history`,
    USER_WALLET: `${user}/wallet`,
    WORKING_INCOME_LIST: `${user}/working-income`,
  },
  Exchange: {
    GET_WALLET: "/get-wallet",
  },
};
export { API };
