import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CustomModal from "../../../../component/Modal/CustomModal";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import copyTextToClipboard from "copy-text-to-clipboard";

const ITEM_HEIGHT = 48;

const User = () => {
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const adminType = useSelector((store) => store.admin.adminType);

  const [isFetching, setIsFetching] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersList, setUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const open1 = Boolean(anchorEl);

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };

  const checklevel = (item) => {
    let res = "";

    if (item === true) {
      res = "Purchase";
      return res;
    } else if (item === false) {
      res = "Non Purchase";
      return res;
    } else {
      res = "-";
      return res;
    }
  };

  const onDelete = async (id) => {
    setIsFetching(true);
    const formData = new URLSearchParams();
    formData.append("_id", id?.trim());
    await DataService(token)
      .post(API.Admin.DELETE_USER, formData)
      .then((response) => {
        if (response.data.status == 200) {
          toast.success(response.data.message);
        }
        getUserList();
        setModalOpen(false);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  const handleCopy = (data, type) => {
    copyTextToClipboard(
      `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?${type}-Referral=${data}`
    );
    toast.success("Referral link Copied");
  };

  const getUserList = async () => {
    await DataService(token)
      .post(API.Admin.GET_USER, {})
      .then((res) => {
        const rows = res.data.data.map((item) => {
          return {
            id: item?._id,
            first_name: item?.first_name ? item?.first_name : "-",
            last_name: item?.last_name ? item?.last_name : "-",
            user_name: item?.user_name ? item?.user_name : "-",
            email: item?.email ? item?.email : "-",
            mobile_number: item?.mobile_number ? item?.mobile_number : "-",
            left_referral: item?.left_referral ? item?.left_referral : "-",
            right_referral: item?.right_referral ? item?.right_referral : "-",
          };
        });
        setUserList(rows);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
      });
  };

  const options = [
    // <Index.Button
    //   className="admin-menu-btn"
    //   variant="contained"
    //   onClick={() => {
    //     navigate(`/admin/view`, { state: { id: editId } });
    //   }}
    // >
    //   View
    // </Index.Button>,
    <>
      <Index.Button
        className="admin-menu-btn"
        variant="contained"
        onClick={() => {
          navigate(`/admin/edit-user/${editId}`);
        }}
      >
        Edit
      </Index.Button>
    </>,
    <>
      <Index.Button
        className="admin-menu-btn"
        variant="contained"
        onClick={() => {
          onOpenModal();
        }}
      >
        Delete
      </Index.Button>
    </>,
  ];

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      width: "150",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: "150",
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: "200",
    },
    {
      field: "email",
      headerName: "Email",
      width: "180",
    },

    {
      field: "mobile_number",
      headerName: "Contact Number",
      width: "150",
    },
    {
      field: "left_referral",
      headerName: "Left Referral Code",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <Index.Tooltip title="Copy">
              <Index.Typography
                className="set-hover-text"
                variant="p"
                onClick={() => handleCopy(params?.row?.left_referral, "Left")}
              >
                {params?.row?.left_referral}{" "}
              </Index.Typography>
            </Index.Tooltip>
          </>
        );
      },
    },
    {
      field: "right_referral",
      headerName: "Right Referral Code",
      width: "180",
      renderCell: (params) => {
        return (
          <>
            <Index.Tooltip title="Copy">
              <Index.Typography
                className="set-hover-text"
                variant="p"
                onClick={() => handleCopy(params?.row?.right_referral, "Right")}
              >
                {params?.row?.right_referral}{" "}
              </Index.Typography>
            </Index.Tooltip>
          </>
        );
      },
    },

    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setEditId(params?.id);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Box className="admin-menu-box">
              <Index.Menu
                className="admin-menu-main"
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "100px",
                    boxShadow: "none",
                    border: "1px solid rgba(224, 224, 224, 1)",
                    marginTop: "6px",
                  },
                }}
              >
                {options.map((option) => (
                  <Index.MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleClose1}
                    className="menu-option-li"
                  >
                    {option}
                  </Index.MenuItem>
                ))}
              </Index.Menu>
            </Index.Box>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <div>
      <CustomModal
        modalOpen={modalOpen}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
      >
        <Index.DialogTitle
          style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
        >
          Are you sure want to delete the user?
        </Index.DialogTitle>
        <Index.DialogActions>
          <Index.Button
            onClick={() => {
              onCloseModal();
              onDelete(editId);
            }}
            style={{
              color: "white",
              backgroundColor: "#00235f",
              fontFamily: "system-ui",
              fontSize: 13,
              padding: 5,
            }}
          >
            Confirm
          </Index.Button>
          <Index.Button
            onClick={onCloseModal}
            style={{
              color: "white",
              backgroundColor: "red",
              fontSize: 12,
              fontFamily: "system-ui",
            }}
          >
            Cancel
          </Index.Button>
        </Index.DialogActions>
      </CustomModal>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ height: 530, width: "100%" }}>
          <Index.DataGrid
            className="user-data-head"
            rows={usersList}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            // loading={isFetching}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default User;
