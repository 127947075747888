import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Index from "../../Index";
import { API } from "../../../config/Api";
import { DataService } from "../../../config/DataService";
import Chatboat from "../../../component/common/chatboat";
import {
  userLogin,
  userProfile,
} from "../../../redux/features/slices/Admin/AdminSlice";

export default function Login() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  let initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
        "Please enter valid email"
      )
      .required("Please enter an email"),
    password: Yup.string().required("Please enter a password"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email?.trim());
    urlencoded.append("password", values.password?.trim());

    await DataService()
      .post(API.User.USER_LOGIN, urlencoded)
      .then((res) => {
        if (res?.data?.status == 200) {
          dispatch(userLogin(res.data));
          dispatch(userProfile(res.data?.data));
          setTimeout(() => {
            navigate("/user/dashboard");
            Index.toast.success(res?.data?.message);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status == 401) {
          Index.toast.error(error?.response?.data?.message);
        } else {
          Index.toast.error(error?.response?.data?.message);
        }
      });
    Formik.resetForm();
  };

  return (
    <>
      <Index.Box className="login-main-content">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content f-direction">
                  <Index.Box className="login-logo-details logo-anim position-relative">
                    <img
                      src={Index.Svg.middleround}
                      className="roundmainimg2"
                    />
                    <img src={Index.Svg.smallround} className="roundmainimg3" />

                    <Index.Box className="coin-image">
                      <img
                        src={Index.Png.logo_new}
                        className="logo-login"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="login-logo-title">
                    B-4 Hit Coin
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column login-user-form  "
              >
                <form onSubmit={handleFormSubmit}>
                  <Index.Box className="login-form-details">
                    <Index.Box className="login-pd-main res-login-column ">
                      <Index.Box className="login-max-content">
                        <Index.Box className="login-details-title">
                          <Index.Typography
                            className="login-title"
                            component="h5"
                            variant="h5"
                          >
                            Login
                          </Index.Typography>
                          <Index.Typography
                            className="login-desc"
                            component="p"
                            variant="p"
                          >
                            Enter your email and password
                          </Index.Typography>
                        </Index.Box>

                        <Formik
                          onSubmit={handleFormSubmit}
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                className="login-grid-inner"
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column login-column mb-20px"
                                >
                                  <Index.Box className="input-box custom-form-details login-input">
                                    <Index.Box className="form-group custom-group">
                                      <Index.FormHelperText className="form-label">
                                        Enter Email{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Index.FormHelperText>
                                      <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        name="email"
                                        className="form-control custom-control"
                                        onBlur={handleBlur}
                                        value={values.email}
                                        onChange={handleChange}
                                        helperText={
                                          touched.email && errors.email
                                        }
                                        error={Boolean(
                                          errors.email && touched.email
                                        )}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column login-column mb-20px"
                                >
                                  <Index.Box className="input-box custom-form-details login-input">
                                    <Index.Box className="form-group custom-group">
                                      <Index.FormHelperText className="form-label">
                                        Enter Password{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Index.FormHelperText>
                                      <Index.Box className="input-details-main">
                                        <Index.Box className="form-group pass_group_main">
                                          <Index.OutlinedInput
                                            className="form-control custom_form_control"
                                            id="outlined-adornment-password"
                                            type={
                                              showPassword ? "text" : "password"
                                            }
                                            name="password"
                                            onBlur={handleBlur}
                                            value={values.password}
                                            onChange={handleChange}
                                            helperText={
                                              touched.password &&
                                              errors.password
                                            }
                                            error={Boolean(
                                              errors.password &&
                                                touched.password
                                            )}
                                            inputProps={{ maxlength: 16 }}
                                            endAdornment={
                                              <Index.InputAdornment
                                                position="end"
                                                className="pass_position"
                                              >
                                                <Index.IconButton
                                                  className="icon_end_icon"
                                                  aria-label="toggle password visibility"
                                                  onClick={
                                                    handleClickShowPassword
                                                  }
                                                  onMouseDown={
                                                    handleMouseDownPassword
                                                  }
                                                  edge="end"
                                                >
                                                  {showPassword ? (
                                                    <Index.VisibilityOff />
                                                  ) : (
                                                    <Index.Visibility />
                                                  )}
                                                </Index.IconButton>
                                              </Index.InputAdornment>
                                            }
                                            label="Password"
                                          />
                                          {touched.password &&
                                            errors.password && (
                                              <Index.FormHelperText error>
                                                {errors.password}
                                              </Index.FormHelperText>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column login-column"
                                >
                                  <Index.Box className="flex-check-login comman-flex-justify">
                                    <Index.Box className="check-remeber-main">
                                      <Index.Box className="checkbox-main">
                                        <Index.BpCheckbox className="custom-check" />{" "}
                                        <span>Remember Me</span>
                                      </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="create-account-text">
                                      <Index.Link
                                        className="signup-redirect"
                                        to="/user/forgot-password"
                                      >
                                        Forgot Password ?
                                      </Index.Link>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                  }}
                                  className="grid-column login-column"
                                >
                                  <Index.Box className="">
                                    <Index.Link
                                      // to={"/user"}
                                      className="signin-btn-main primary-btn-main-link-user"
                                    >
                                      <Index.Button
                                        type="submit"
                                        variant="contained"
                                        className="comman-primary-btn "
                                        onClick={handleSubmit}
                                      >
                                        {" "}
                                        Sign in
                                      </Index.Button>
                                    </Index.Link>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Form>
                          )}
                        </Formik>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
                <Chatboat />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
