import React from "react";
import Index from "../../../Index";

const KycResponse = () => {
  return (
    <div>
      <div className="page-content">
        <div className="response-main-card">
          <div className="page-content response-img-part">
            <img src={Index.Jpg.ResponseImg}></img>
            <h3 className="tablHeader kra-form-heading">
              Your Kyc has been Submitted.
            </h3>
            <div className="group-btn"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KycResponse;
