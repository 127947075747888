import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

function StakingList() {
  const token = Index.useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  const navigate = Index.useNavigate();
  const [filterData, setFilterData] = useState([]);
  const [stakingList, setStakingList] = useState([]);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Paginations

  // get Staking List
  const getStakingList = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", "");
    await DataService(userToken)
      .post(API.User.GET_STACKING_LIST, urlencoded)
      .then((res) => {
        setFilterData(res.data.data);
        setStakingList(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  // Search
  const handleSearch = (event) => {
    const { value } = event?.target;

    const result = stakingList.filter((item) => {
      let date = Index.moment(item?.createdAt)
        .format("DD-MM-YYYY")
        .toString()
        ?.includes(value);

      let stakId = item?.stak_id.toString()?.includes(value);
      return date || stakId;
    });
    setFilterData(result);
    setPage(0);
  };

  useEffect(() => {
    getStakingList();
  }, []);

  return (
    <Index.Box class="">
      <Index.Box className="setting-card">
        <Index.Box className="dashboard-content referral-list-tree-btn">
          <Index.Typography
            className="admin-page-title res-referral-list-tree-btn"
            component="h2"
            variant="h2"
          >
            Staking
          </Index.Typography>
          <Index.Box className="preasale-search">
            <Index.Box className="user-search-main cus-search">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="page-table-main table-design-main ">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Stake Id
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Staking Amount (USDT)
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            filterData
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => {
                                let network = Index.selectNetwork(
                                  row?.network_type
                                );
                                let networkLink = Index.NetworkLink(network);
                                return (
                                  <Index.TableRow
                                    key={row.createdAt}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.createdAt
                                        ? Index.moment(row?.createdAt).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td set-hover-text"
                                      align="right"
                                    >
                                      {row?.stak_id ? row?.stak_id : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td set-hover-text"
                                      align="right"
                                    >
                                      {row?.total_amount
                                        ? row?.total_amount
                                        : 0}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td email-ellip"
                                      align="right "
                                    >
                                      <Index.Tooltip
                                        title={"More Details"}
                                        arrow
                                        placement="top"
                                      >
                                        <Index.IconButton
                                          onClick={() => {
                                            navigate(
                                              `/user/stak-details/${row?._id}`,
                                              {
                                                state: {
                                                  roiData: row?.roi_id,
                                                  stakId: row?.stak_id,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          <img
                                            src={Index.Svg.view}
                                            className="view-icon"
                                          ></img>
                                        </Index.IconButton>
                                      </Index.Tooltip>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                colSpan={4}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default StakingList;
