import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import PropTypes from "prop-types";
import User from "./User";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserList() {

  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  return (
    <div>

      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title user-title-row">
          <Index.Typography
            className="admin-page-title admin-sub-titles"
            component="h2"
            variant="h2"
          >
            User
          </Index.Typography>


          <Index.Box className="btn-main login-btn-main primary-btn-main-comman">

            <Index.Link to="/admin/add-user" className="add-user-btn  btn-dark-comman-main">
              <Index.AddIcon /> Add
            </Index.Link>
          </Index.Box>

        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "10px" }}>
              <User />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </div>
  );
}
