import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { ToastContainer, toast } from "react-toastify";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector, useDispatch } from "react-redux";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobileNo,
  validateName,
} from "../../../../component/common/AuthValidation";
import UserDetails from "../../../admin/pages/user/UserDetails";
import {
  userLogin,
  userProfile,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import MuiPhoneInput from "material-ui-phone-number";
import * as Yup from "yup";
import { Formik, Form } from "formik";

const UserEditProfile = () => {
  const dispatch = useDispatch();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const user_token = useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(user_token);

  // Input States

  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");

  const [userData, setUserData] = useState({});
  const [countryCode, setCountryCode] = useState("");

  let initialValues = "";

  if (Object.keys(userData).length > 0) {
    initialValues = {
      email: userData?.email ? userData?.email : "-",
      firstName: userData?.first_name ? userData?.first_name : "-",
      lastName: userData?.last_name ? userData?.last_name : "-",
      userName: userData?.user_name ? userData?.user_name : "-",
      mobileNo: userData?.mobile_number
        ? userData?.mobile_number.replaceAll("-", "")
        : "-",
    };
  } else {
    initialValues = {
      email: "",
      firstName: "",
      lastName: "",
      userName: "",
      mobileNo: "",
    };
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .matches(
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/,
        "Please enter valid email"
      )
      .required("Please enter an email"),

    firstName: Yup.string()
      .required("Please enter firstname")
      .min(2, "Firstname should be 2-20 characters")
      .matches(/^[a-zA-Z\s]+$/, "Enter only alphabet character"),

    lastName: Yup.string()
      .required("Please enter lastname")
      .min(2, "Lastname should be 2-20 characters")
      .matches(/^[a-zA-Z\s]+$/, "Enter only alphabet character"),

    userName: Yup.string()
      .required("Please enter username")
      .min(2, "Username should be 2-20 characters")
      .matches(
        /^(?![0-9]+$)(?![!@#$%^&*()_+<>?]+$)[a-zA-Z0-9!@#$%^&*()_+<>?]+$/,
        "Please enter valid username"
      ),

    mobileNo: Yup.string()
      .required("Please enter mobile number")
      .matches(
        /^[\+]?[(]?[0-9]{1,4}[)]?\s?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,}$/,
        "Please enter valid mobile number"
      ),
  });

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    let imgurl = URL.createObjectURL(e.target.files[0]);

    setUrl(imgurl);
  };
  const handleContactNo = (data) => {
    setCountryCode(data?.dialCode);
  };

  useEffect(() => {
    if (userData) {
      setUrl(
        userData?.profile_image != undefined
          ? `${process.env.REACT_APP_IMAGE_URL}/${userData?.profile_image}`
          : Index.Png.logo_new
      );
    }
  }, [userData]);

  const getProfile = async () => {
    await DataService(userToken)
      .get(API.User.GET_USER)
      .then((res) => {
        setUserData(res?.data?.data);

        // setAdminDetails(res?.data?.data);
        setUrl(
          res?.data?.data?.profile_image != undefined
            ? `${process.env.REACT_APP_BASE_URL}/image/${res?.data?.data?.profile_image}`
            : Index.Png.logo_new
        );
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getProfile();
  }, [token]);

  const onDiscard = () => {
    setUrl(
      userData?.profile_image != undefined
        ? `${process.env.REACT_APP_IMAGE_URL}/${userData?.profile_image}`
        : Index.Png.logo_new
    );
  };

  const handleFormSubmit = async (values) => {
    const urlencoded = new FormData();
    urlencoded.append("first_name", values?.firstName?.trim());
    urlencoded.append("last_name", values?.lastName?.trim());
    urlencoded.append("email", values?.email?.trim());
    urlencoded.append("user_name", values?.userName?.trim());
    urlencoded.append("mobile_number", values?.mobileNo);
    urlencoded.append("country_code", countryCode);
    urlencoded.append("profile", image);
    await DataService(userToken)
      .post(API.User.USER_PROFILE, urlencoded)
      .then((res) => {
        if (res?.data?.status == 200) {
          dispatch(userProfile(res?.data?.data));
          toast.success(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
    getProfile();
  };

  const handleFormReset = (values) => {
    console.log(values, ":values");
  };

  return (
    <Index.Box className="tabpanel-main">
      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onReset={handleFormReset}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="add-user-data-main">
              <Index.Box className="edit-profile-flex">
                <Index.Box className="file-upload-btn-main">
                  <img
                    src={url ? url : Index.Png.logo_new}
                    className="upload-profile-img"
                    alt="upload img"
                  ></img>
                  <Index.Button
                    variant="contained"
                    component="label"
                    className="file-upload-btn"
                  >
                    <img
                      src={Index.Svg.edit}
                      className="upload-icon-img"
                      alt="img"
                    ></img>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => handleImage(e)}
                    />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        First Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          inputProps={{ maxlength: 20 }}
                          name="firstName"
                          onBlur={handleBlur}
                          value={values.firstName}
                          onChange={handleChange}
                          helperText={touched.firstName && errors.firstName}
                          error={Boolean(errors.firstName && touched.firstName)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Last Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          inputProps={{ maxlength: 20 }}
                          name="lastName"
                          onBlur={handleBlur}
                          value={values.lastName}
                          onChange={handleChange}
                          helperText={touched.lastName && errors.lastName}
                          error={Boolean(errors.lastName && touched.lastName)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="email"
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={handleChange}
                          helperText={touched.email && errors.email}
                          error={Boolean(errors.email && touched.email)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Phone Number
                      </Index.FormHelperText>
                      <Index.Box className="form-group country-code-main">
                        <MuiPhoneInput
                          defaultCountry="in"
                          onChange={(e, countryDetails) => {
                            handleContactNo(countryDetails);
                            setFieldValue("mobileNo", e.replaceAll("-", ""));
                          }}
                          className="country-code-details"
                          name="mobileNo"
                          onBlur={handleBlur}
                          value={values.mobileNo}
                          error={Boolean(errors.mobileNo && touched.mobileNo)}
                        />
                        {touched.mobileNo && errors.mobileNo && (
                          <Index.FormHelperText error>
                            {errors.mobileNo}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        User Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          inputProps={{ maxlength: 15 }}
                          name="userName"
                          onBlur={handleBlur}
                          value={values.userName}
                          onChange={handleChange}
                          helperText={touched.userName && errors.userName}
                          error={Boolean(errors.userName && touched.userName)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="user-btn-flex comman-btn-main">
                      <Index.Box className="discard-btn-main border-btn-main">
                        <Index.Button
                          className="discard-user-btn border-btn"
                          onClick={() => {
                            handleReset();
                            onDiscard();
                          }}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="save-btn-main ">
                        <Index.Button
                          className="save-user-btn primary-btn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          <img
                            src={Index.Svg.save}
                            alt="save"
                            className="user-save-icon"
                          ></img>
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </Index.Box>
  );
};

export default UserEditProfile;
