import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import {
  validateAffilateCode,
  validateConfirmPassword,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobileNo,
  validateName,
  validatePassword,
} from "../../../../component/common/AuthValidation";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import { useSelector } from "react-redux";
import MuiPhoneInput from "material-ui-phone-number";

export default function AddUser() {
  const { editId } = useParams();

  const navigate = useNavigate();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const [user, setUser] = useState({});
  const [flag, setFlag] = useState(false);

  // input States
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [username, setUsername] = useState("");
  const [affilatecode, setAffilatecode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Error States
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [contactNoError, setContactNoError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passWordError, setPassWordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // handle States
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show1) => !show1);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handlePassword = (e) => {
    setPassWordError(validatePassword(e.target.value));
    setPassword(e.target.value);
    if (confirmPassword !== "") {
      setConfirmPasswordError(
        validateConfirmPassword(confirmPassword, e.target.value)
      );
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordError(validateConfirmPassword(e.target.value, password));
    setConfirmPassword(e.target.value);
  };
  const handleFirstName = (e) => {
    setFirstNameError(validateFirstName(e.target.value));
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastNameError(validateLastName(e.target.value));
    setLastName(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailError(validateEmail(e.target.value.toLowerCase()));
    setEmail(e.target.value.toLowerCase());
  };

  const handleUserName = (e) => {
    setUsernameError(validateName(e.target.value));
    setUsername(e.target.value);
  };

  const handleContactNo = (e, data) => {
    setContactNoError(validateMobileNo(e));
    setContactNo(e);
    setCountryCode(data?.dialCode);
  };

  const validateInputs = () => {
    setConfirmPasswordError(validatePassword(confirmPassword, password));
    setFirstNameError(validateFirstName(firstname));
    setLastNameError(validateLastName(lastname));
    setEmailError(validateEmail(email));
    setUsernameError(validateName(username));
    setContactNoError(validateMobileNo(contactNo));
    setPassWordError(validatePassword(password));
  };

  const getUserDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", editId?.trim());
    await DataService(token)
      .post(API.Admin.GET_USER, urlencoded)
      .then((res) => {
        setUser(res?.data?.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    if (editId) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFirstName(user?.first_name);
      setLastName(user?.last_name);
      setEmail(user?.email);
      // setAffilatecode(user?.referral_code)
      setUsername(user?.user_name);
      setContactNo(user?.mobile_number);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFlag(true);
    if (
      firstname &&
      !firstNameError &&
      lastname &&
      !lastNameError &&
      email &&
      !emailError &&
      username &&
      !usernameError &&
      contactNo &&
      !contactNoError &&
      password &&
      !passWordError &&
      confirmPassword &&
      !confirmPasswordError &&
      password == confirmPassword
    ) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("email", email.trim());
      urlencoded.append("first_name", firstname.trim());
      urlencoded.append("last_name", lastname.trim());
      urlencoded.append("user_name", username.trim());
      urlencoded.append("mobile_number", contactNo);
      urlencoded.append("country_code", countryCode);
      // urlencoded.append("affilate_code", affilatecode);
      urlencoded.append("password", password);
      if (editId) {
        urlencoded.append("_id", editId);
      }
      await DataService(token)
        .post(editId ? API.Admin.EDIT_USER : API.Admin.ADD_USER, urlencoded)
        .then((res) => {
          setFlag(false);
          if (res.data.status == 200 || res.data.status == 201) {
            toast.success(res?.data?.message);

            setTimeout(() => {
              navigate("/admin/waitlist");
            }, 2000);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => {
          setFlag(false);

          if (error.response.data.message == "Email already exist" && !editId) {
            setEmail("");
          }
          if (
            error.response.data.message == "Mobile number already exist" &&
            !editId
          ) {
            setContactNo("");
          }
          toast.error(error.response.data.message);
        });
    }
    setFlag(false);

    validateInputs();
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setFlag(true);
    if (
      firstname &&
      !firstNameError &&
      lastname &&
      !lastNameError &&
      email &&
      !emailError &&
      username &&
      !usernameError &&
      contactNo &&
      !contactNoError
    ) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("email", email);
      urlencoded.append("first_name", firstname);
      urlencoded.append("last_name", lastname);
      urlencoded.append("user_name", username);
      urlencoded.append("mobile_number", contactNo);
      urlencoded.append("country_code", countryCode);

      if (editId) {
        urlencoded.append("_id", editId);
      }
      await DataService(token)
        .post(editId ? API.Admin.EDIT_USER : API.Admin.ADD_USER, urlencoded)
        .then((res) => {
          setFlag(false);
          if (res.data.status == 200 || res.data.status == 201) {
            toast.success("User Update successfully");

            setTimeout(() => {
              navigate("/admin/waitlist");
            }, 2000);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => {
          setFlag(false);

          if (error.response.data.message == "Email already exist" && !editId) {
            setEmail("");
          }
          if (
            error.response.data.message == "Mobile number already exist" &&
            !editId
          ) {
            setContactNo("");
          }
          toast.error(error.response.data.message);
        });
    }
    setFlag(false);

    validateInputs();
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Typography
          className="admin-page-title  add-user-title add-user-page-title"
          component="h2"
          variant="h2"
        >
          {editId === undefined ? "Add" : "Edit"} User
        </Index.Typography>
        <Index.Box className="add-user-data-main">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable" for="user">
                    First Name
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      name="firstname"
                      id="user"
                      className="form-control"
                      value={firstname}
                      inputProps={{ maxlength: 20 }}
                      onChange={(e) => handleFirstName(e)}
                      placeholder=""
                    />
                  </Index.Box>
                  <p className="error-text">{firstNameError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Last Name
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      name="lastmame"
                      className="form-control"
                      inputProps={{ maxlength: 20 }}
                      onChange={(e) => handleLastName(e)}
                      value={lastname}
                      placeholder=""
                    />
                  </Index.Box>
                  <p className="error-text">{lastNameError}</p>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable" for="user">
                    Email <span style={{ color: "red" }}>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      name="email"
                      id="user"
                      className="form-control"
                      value={email}
                      onChange={(e) => handleEmail(e)}
                    />
                  </Index.Box>
                  <p className="error-text">{emailError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable" for="user">
                    Username <span style={{ color: "red" }}>*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="user"
                      className="form-control"
                      value={username}
                      inputProps={{ maxlength: 15 }}
                      onChange={(e) => handleUserName(e)}
                    />
                  </Index.Box>
                  <p className="error-text">{usernameError}</p>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable" for="user">
                    Mobile No
                  </Index.FormHelperText>
                  <Index.Box className="form-group country-code-main">
                    {/* <Index.TextField
                      fullWidth
                      id="user"
                      type="number"
                      className="form-control"
                      value={contactNo}
                      onChange={(e) => handleContactNo(e)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    /> */}
                    <MuiPhoneInput
                      value={contactNo}
                      defaultCountry="in"
                      onChange={(e, countryDetails) => {
                        handleContactNo(e, countryDetails);
                      }}
                      className="country-code-details country-code-add-user"
                    />
                  </Index.Box>
                  <p className="error-text">{contactNoError}</p>
                </Index.Box>
              </Index.Box>

              {/* <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Referral Code
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            onChange={(e) => handleAffilatedCode(e)}
                            value={affilatecode}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{affilatecodeError}</p>
                      </Index.Box> 
              </Index.Box> */}

              {!editId && (
                <>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable">
                        Password <span style={{ color: "red" }}>*</span>
                      </Index.FormHelperText>
                      <Index.Box className="form-group pass_group_main">
                        <Index.Box className="form-group pass_group_main">
                          <Index.OutlinedInput
                            className="form-control custom_form_control"
                            value={password}
                            onChange={handlePassword}
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            inputProps={{ maxlength: 16 }}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="icon_end_icon"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            label="Password"
                          />
                        </Index.Box>
                      </Index.Box>
                      <p className="error-text">{passWordError}</p>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box input-box-admin input-box-admin2">
                      <Index.FormHelperText className="form-lable">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </Index.FormHelperText>
                      <Index.Box className="form-group pass_group_main">
                        <Index.Box className="form-group pass_group_main">
                          <Index.OutlinedInput
                            className="form-control custom_form_control"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                            inputProps={{ maxlength: 16 }}
                            id="outlined-adornment-password"
                            type={showPassword1 ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="icon_end_icon"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword1 ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            label="Password"
                          />
                        </Index.Box>
                      </Index.Box>
                      <p className="error-text">{confirmPasswordError}</p>
                    </Index.Box>
                  </Index.Box>
                </>
              )}

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  className="submit-btn-main border-btn-main  btn-main login-btn-main primary-btn-main-comman "
                  mt={2}
                >
                  {/* <Index.Box className="discard-btn-main border-btn-main">
                          <Index.Button
                            className="discard-user-btn border-btn"
                            // onClick={onDiscard}
                          >
                            Discard
                          </Index.Button>
                        </Index.Box> */}

                  <Index.Button
                    className="submit-user-btn border-btn add-user-btn  btn-dark-comman-main "
                    disableRipple
                    type="button"
                    onClick={(e) => {
                      if (flag == false) {
                        if (editId) {
                          handleEditSubmit(e);
                        } else {
                          handleSubmit(e);
                        }
                      }
                    }}
                  >
                    {editId ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              ></Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
