import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../component/Modal/CustomModal";
import { adminLogout } from "../../../../redux/features/slices/Admin/AdminSlice";
import { getPresaleDetails } from "../../../../redux/features/slices/Admin/AdminService";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";

export default function PresaleRound() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AdminToken = useSelector((store) => store.admin.admintoken);
  let token = window.atob(AdminToken);

  const presaleList = useSelector((store) => store.admin.presaleList);

  const [editId, setEditId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onOpenModal = () => {
    setModalOpen(true);
  };
  const onCloseModal = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    dispatch(getPresaleDetails(token))
      .then((res) => {
        let tempArr = [];

        res?.payload?.data?.presale?.map((item, index) => {
          console.log(
            res.payload?.data?.userCount[index].count,
            "res.payload.userCount"
          );
          let obj = {
            _id: item?._id,
            phaseName: item?.phaseName ? item?.phaseName : "-",
            startDate: item?.startDate ? item?.startDate : "-",
            endDate: item?.endDate ? item?.endDate : "-",
            tokenPrice: item?.tokenPrice ? item?.tokenPrice : "-",
            membersCount: res.payload?.data?.userCount[index].count
              ? res.payload?.data?.userCount[index].count
              : 0,
            totalSale: 0,
            totalRefferal: res.payload.userCount ? res.payload.userCount : 0,
            vestingPeriod: item?.vestingPeriod ? item?.vestingPeriod : "-",
          };
          tempArr.push(obj);
        });
        console.log(tempArr, ":tempArr");
        setUsers(tempArr);
        setFilterData(tempArr);
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
        if (error?.response?.data?.message === "Token not authorized") {
          dispatch(adminLogout(""));
          navigate("/admin/login");
        }
      });
  };

  const handleSearch = (event) => {
    const result = users.filter((item) => {
      return (
        item?.phaseName?.toString()?.includes(event?.target?.value) ||
        Index.moment(item?.startDate)
          .format("DD-MM-YYYY")
          ?.toString()
          ?.includes(event?.target?.value) ||
        Index.moment(item?.endDate)
          .format("DD-MM-YYYY")
          ?.toString()
          ?.includes(event?.target?.value) ||
        item?.membersCount?.toString()?.includes(event?.target?.value) ||
        item?.totalRefferal?.toString()?.includes(event?.target?.value) ||
        item?.vestingPeriod?.toString()?.includes(event?.target?.value)
      );
    });

    setFilterData(result);
    setPage(0);
  };

  const onDelete = async (id) => {
    const formData = new URLSearchParams();
    formData.append("id", id?.trim());
    await DataService(token)
      .post(API.Admin.REMOVE_PRIVATE_SALE, formData)
      .then((response) => {
        toast.success("Private sale Deleted");
        fetchData();
      })
      .catch((error) => {
        Index.toast.error(error?.response?.data?.message);
        if (error.response.data.message === "Token not authorized") {
          dispatch(adminLogout(""));
          navigate("/admin/login");
        }
      });
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/admin/login"></Navigate>;
    }
  });

  useEffect(() => {
    fetchData();
  }, []);

  const FilterDate = (e) => {
    let startDate = e[0]?.$d;
    let endDate = e[1]?.$d;

    const result = users.filter((item) => {
      return (
        Index.moment(item?.startDate)
          .format("DD-MM-YYYY")
          ?.toString()
          ?.includes(Index.moment(startDate).format("DD-MM-YYYY")) ||
        Index.moment(item?.endDate)
          .format("DD-MM-YYYY")
          ?.toString()
          ?.includes(Index.moment(endDate).format("DD-MM-YYYY"))
      );
    });

    setFilterData(result);
    setPage(0);
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="user-head-title">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Presale Management
          </Index.Typography>

          <Index.Box className="preasale-search">
            <Index.Box className="presale-input mr-15">
              <Index.Box className="input-box input-box-admin input-box-admin2">
                <Index.Box className="form-group date-range-picker">
                  <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                    <Index.Stack className="sale-field date-picker-mui">
                      {/* <Index.DatePicker
                        className="privat-sale-datepicker"
                        format="DD-MM-YYYY"
                       
                        renderInput={(params) => (
                          <Index.TextField {...params} />
                        )}
                      /> */}
                      {/* <DateRangePicker onChange={(e) => FilterDate(e)}/> */}

                      <DemoContainer components={["SingleInputDateRangeField"]}>
                        <DateRangePicker
                          format="DD-MM-YYYY"
                          slots={{ field: SingleInputDateRangeField }}
                          onChange={(e) => FilterDate(e)}
                          shouldDisableDate={(date) =>
                            new Date(date).getTime() >=
                            new Date(
                              presaleList?.data?.presale?.slice(-1)[0]?.endDate
                            )?.getTime()
                          }
                        />
                      </DemoContainer>
                    </Index.Stack>
                  </Index.LocalizationProvider>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="user-search-main cus-search">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search presale"
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.greysearch}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
              <Index.Link
                to="/admin/privateadminsale"
                className="add-user-btn btn-dark-comman-main"
                style={{
                  textDecoration: "none",
                  fontSize: 15,
                  fontFamily: "Poppins-SemiBold",
                }}
              >
                <Index.AddIcon /> Add
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="">
                  <Index.Box className="userlist-table-main table-design-main presale-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              PhaseName
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              StartDate
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              EndDate
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Member
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Total Referral
                            </Index.TableCell>
                            {/* <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Vesting Period
                            </Index.TableCell> */}
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            filterData
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row) => {
                                let username = row.user_name;
                                return (
                                  <Index.TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.phaseName ? row?.phaseName : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.startDate
                                        ? Index.moment(row?.startDate).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.endDate
                                        ? Index.moment(row?.endDate).format(
                                            "DD-MM-YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.membersCount
                                        ? row?.membersCount
                                        : "0"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.totalRefferal
                                        ? row?.totalRefferal
                                        : "0"}
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.vestingPeriod
                                        ? row?.vestingPeriod
                                        : "0"}
                                    </Index.TableCell> */}

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      <Index.IconButton
                                        onClick={() => {
                                          navigate(
                                            `/admin/privateadminsale/${row?._id}`
                                          );
                                        }}
                                      >
                                        <img src={Index.Svg.blueEdit}></img>
                                      </Index.IconButton>
                                      <Index.IconButton
                                        onClick={() => {
                                          navigate(
                                            `/admin/presale-details/${row?._id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.view}
                                          className="view-icon"
                                        ></img>
                                      </Index.IconButton>
                                      {/* <Index.IconButton
                                        onClick={() => {
                                          onOpenModal();
                                          setEditId(row?._id);
                                        }}
                                      >
                                        <img src={Index.Svg.trash}></img>
                                      </Index.IconButton> */}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td "
                                colSpan={6}
                              >
                                No Record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <Index.TablePagination
                    component="div"
                    page={page}
                    count={filterData?.length}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={(page) =>
                      `Records ${page.from} to ${
                        page.to === -1 ? page.count : page.to
                      } of ${page.count}`
                    }
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <CustomModal
        modalOpen={modalOpen}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
      >
        <Index.DialogTitle
          style={{ fontFamily: "Poppins-Medium", fontSize: 17 }}
        >
          Are you sure want to delete the user?
        </Index.DialogTitle>
        <Index.DialogActions>
          <Index.Button
            onClick={() => {
              onCloseModal();
              onDelete(editId);
            }}
            style={{
              color: "white",
              backgroundColor: "#00235f",
              fontFamily: "system-ui",
              fontSize: 13,
              padding: 5,
            }}
          >
            Confirm
          </Index.Button>
          <Index.Button
            onClick={onCloseModal}
            style={{
              color: "white",
              backgroundColor: "red",
              fontSize: 12,
              fontFamily: "system-ui",
            }}
          >
            Cancel
          </Index.Button>
        </Index.DialogActions>
      </CustomModal>
    </div>
  );
}
