import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

// cart start
// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};
// chart end

const AdminDashboard = () => {
  const token = useSelector((store) => store?.admin?.admintoken);
  let AdminToken = window.atob(token);

  const navigate = Index.useNavigate();

  const [totalValue, setTotalValues] = useState({});
  const [currentPhase, setCurrentPhase] = useState([]);
  const [days, setDays] = useState(1);
  const [selecteddays, setSelectedDays] = useState(1);

  const getTotaltoken = async () => {
    await DataService(AdminToken)
      .get(API.Admin.GET_TOTAL_TOKENS)
      .then((res) => {
        setTotalValues(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getTotaltoken();
    getGraphDetails();
  }, []);

  useEffect(() => {
    getGraphDetails();
  }, [days]);

  const getGraphDetails = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("currentType", days);
    await DataService(AdminToken)
      .post(API.Admin.GRAPH_DETAILS, urlencoded)
      .then((res) => {
        let tempArr = [];
        res.data.data?.current?.map((item) => {
          let obj = {
            Name: Index.moment(item?.createdAt).format("MMM Do"),
            Transaction: item?.total?.toFixed(2),
            USDT: item?.usdt,
          };
          tempArr.push(obj);
        });
        setCurrentPhase(tempArr);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  const handleNavigate = (id) => {
    navigate(`/admin/waitlist`, {
      state: {
        filterValue: id,
      },
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="admin-dash-main">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="tab-label-dash"
            >
              <Index.Tab label="Dashboard" {...Index.a11yProps(0)} />
              <Index.Tab label="Graph" {...Index.a11yProps(1)} />
            </Index.Tabs>
          </Index.Box>
          <Index.CustomTabPanel
            value={value}
            index={0}
            className="tab-one-dash"
          >
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="custom-card">
                <Index.Box className="user-head-title user-title-row">
                  <Index.Typography
                    className="admin-page-title admin-sub-titles"
                    component="h2"
                    variant="h2"
                  >
                    Dashboard
                  </Index.Typography>

                  {/* <Index.Box className="btn-main login-btn-main primary-btn-main-comman">
            <Index.Link
              to="/admin/add-user"
              className="add-user-btn  btn-dark-comman-main"
            >
              <Index.AddIcon /> Add
            </Index.Link>
          </Index.Box> */}
                </Index.Box>

                <Index.Box className="admin-dashboad-row">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() => navigate("/admin/transaction")}
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Total Transaction
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.totalHash
                                  ? totalValue?.totalHash
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Total Purchase USDT
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.totalUsdt > 999999
                                  ? (totalValue?.totalUsdt / 1000000)?.toFixed(
                                      2
                                    ) + " M"
                                  : totalValue?.totalUsdt
                                  ? totalValue?.totalUsdt?.toFixed(2)
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Svg.usdtlogo}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Total Token
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.totalToken > 999999
                                  ? (
                                      parseInt(totalValue?.totalToken) / 1000000
                                    )?.toFixed(2) + " M"
                                  : totalValue?.totalToken
                                  ? parseInt(totalValue?.totalToken)?.toFixed(2)
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.depositUser && handleNavigate(1)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Total Deposit User
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.depositUser
                                  ? totalValue?.depositUser
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.nonDepositUser && handleNavigate(4)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Total Non-Deposit User
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.nonDepositUser
                                  ? totalValue?.nonDepositUser
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Today's New Joining User
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Stake Token
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Unstake Token
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                My Income
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Withdrawal
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 3",
                          lg: "span 4",
                        }}
                        className="grid-column"
                        onClick={() =>
                          totalValue?.newJoining && handleNavigate(3)
                        }
                      >
                        <Index.Box className="admin-dashboard-box admin-dashboard-box2">
                          <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                            <Index.Box className="admin-dash-left">
                              <Index.Typography
                                className="admin-dash-text user-dash-text"
                                variant="p"
                                component="p"
                              >
                                Bonus
                              </Index.Typography>
                              <Index.Typography
                                className="admin-dash-price user-dash-price "
                                variant="h1"
                                component="h1"
                              >
                                {totalValue?.newJoining
                                  ? totalValue?.newJoining
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="admin-dash-right dash-icon-main">
                              <img
                                src={Index.Png.logo_new}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box>
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      ></Index.Box>
                      {/* <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="card-main chart-main">
                    <Index.Box className="charttitle">
                      <Index.Box className="set-chart-drop">
                        Phase 1
                        <Index.Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="chart-drop"
                          label="Age"
                          value={Aselecteddays}
                          onChange={(e) => setSelectedDays(e?.target?.value)}
                        >
                          <Index.MenuItem value={1}>7 Days</Index.MenuItem>
                          <Index.MenuItem value={0}>30 Days</Index.MenuItem>
                        </Index.Select>
                      </Index.Box></Index.Box>
                    <LineChart
                      width={460}
                      height={300}
                      data={currentPhase}
                      margin={{
                        top: 20,
                        right: 20,
                        left: -10,
                        bottom: 10,
                      }}
                      className="line-chart-main"
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        height={60}
                        tick={<CustomizedAxisTick />}
                        className="xaxis-main"
                      />
                      <YAxis className="yaxis-main" />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="pv" stroke="#56290b">
                        <LabelList content={<CustomizedLabel />} />
                      </Line>
                      <Line type="monotone" dataKey="uv" stroke="#50af95" />
                    </LineChart>
                  </Index.Box>
                </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.CustomTabPanel>
          <Index.CustomTabPanel
            value={value}
            index={1}
            className="tab-two-dash"
          >
            <Index.Box className="card-main chart-main">
              <Index.Box className="charttitle">
                <Index.Box className="set-chart-drop">
                  Presale 1
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="chart-drop"
                    label="Age"
                    value={days}
                    onChange={(e) => setDays(e?.target?.value)}
                  >
                    <Index.MenuItem value={1}>7 Days</Index.MenuItem>
                    <Index.MenuItem value={0}>30 Days</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
              </Index.Box>
              <LineChart
                width={days == 1 ? 600 : 900}
                height={300}
                data={currentPhase}
                margin={{
                  top: 30,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
                className="line-chart-main"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="Name"
                  height={60}
                  tick={<CustomizedAxisTick />}
                  className="xaxis-main"
                  label={{ value: "USDT", position: "insideBottom" }}
                  offset={0}
                />

                <YAxis
                  className="yaxis-main"
                  label={{
                    value: "Transactions",
                    position: "left",
                    angle: -90,
                  }}
                />
                <Tooltip />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  iconType="circle"
                />
                <Line type="monotone" dataKey="Transaction" stroke="#56290b">
                  <LabelList content={<CustomizedLabel />} />
                </Line>
                <Line type="monotone" dataKey="USDT" stroke="#50af95" />
              </LineChart>
            </Index.Box>
          </Index.CustomTabPanel>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AdminDashboard;
