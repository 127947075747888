import React , {useState} from "react";
import PropTypes from "prop-types";
import Index from "../../../../component/Index";
import UserEditProfile from "./UserEditProfile";
import UserChangePassword from "./UserChangePassword";


export default function GeneralSetting() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>

      <Index.Box className="dashboard-content add-user-containt">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Account Settings
        </Index.Typography>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="admin-tabs-main"
              >
                <Index.Tab
                  label="Edit Profile"
                  {...Index.a11yProps(0)}
                  className="admin-tab"
                  disableRipple
                />
                <Index.Tab
                  label="Change Password"
                  {...Index.a11yProps(1)}
                  className="admin-tab"
                  disableRipple
                />
              </Index.Tabs>
            </Index.Box>
            <Index.CustomTabPanel value={value} index={0} className="admin-tabpanel">
              <UserEditProfile />
            </Index.CustomTabPanel>
            <Index.CustomTabPanel value={value} index={1} className="admin-tabpanel">
              <UserChangePassword />
            </Index.CustomTabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </div>
  );
}
