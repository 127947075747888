import React, { Fragment, useEffect, useState } from "react";

import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./treeStyle.css";
import Index from "../../../Index";

const Card = (props) => {
  return (
    <ul>
      {props.data.map((item, index) => {
        let multi = [];
        if (item.children?.length === 2) {
          if (item.children[0]["type_referral"] !== "Left") {
            multi[0] = item.children[1];
            multi[1] = item.children[0];
          } else {
            multi[0] = item.children[0];
            multi[1] = item.children[1];
          }
        }
        return (
          <Fragment key={item.first_name}>
            <li>
              <div className="card tree-card">
                <div className="card-body tree-body">
                  {/* <h4>{item.first_name}</h4>
                  <p>{item.last_name}</p> */}
                  <div className="card-footer tree-footer">
                    <h4>
                      {item.first_name} ({item.type_referral})
                    </h4>
                  </div>
                </div>

                <div></div>
              </div>
              {item.children?.length && (
                <Card
                  data={item.children?.length === 2 ? multi : item.children}
                />
              )}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

const Chart = () => {
  const navigate = useNavigate();
  const details = useSelector((store) => store.admin.userDetail);
  let userDetails = JSON.parse(window.atob(details));
  const token = useSelector((store) => store.admin.loginUserToken);
  let userToken = window.atob(token);

  let userDetails1 = localStorage.getItem("UserLogin");

  const [treedata, setTreeData] = useState([]);

  useEffect(() => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", userDetails._id?.trim());
    DataService(userToken)
      .post(API.User.GET_TREE_DATA, urlencoded)
      .then((res) => {
        setTreeData(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  }, []);

  return (
    <>
      {/* <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <UserSidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <UserHeader /> */}
      <Index.Box className="tree-center setting-card">
        <div className="org-tree custom-tree">
          <Card data={treedata} />
        </div>
      </Index.Box>

      {/* </Index.Box>
      </Index.Box> */}
    </>
  );
};

export default Chart;
