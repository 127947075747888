import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import QrCodeGenerate from "../../../../component/common/QrCodeGenerate";

import copyTextToClipboard from "copy-text-to-clipboard";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import DepositModal from "./depositModal";
import { getSettingData } from "../../../../redux/features/slices/Admin/AdminSlice";
import Withdraw from "../myIncome/myIncomeTab/withdraw/Withdraw";
import WarningIcon from "@mui/icons-material/Warning";

function DashboardContent() {
  const dispatch = useDispatch();
  const details = useSelector((store) => store?.admin?.userDetail);
  let userDetails = JSON?.parse(window?.atob(details));
  const token = useSelector((store) => store?.admin?.loginUserToken);
  let userToken = window?.atob(token);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const [totalValues, setTotalValues] = useState({});
  const [qrCodeData, setQRCodeData] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [getsettingData, setGetSettingData] = useState({});
  const [referralCount, setReferralCount] = useState({});
  const [typeReferral, setTypeReferral] = useState("");
  const [mainReferral, setMyReferral] = useState({});
  const [allAmounts, setAllAmounts] = useState({});
  const [exchangeWallet, setExchangeWallet] = useState({});

  // For Withdraw Modal
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const handleOpenWithdrawModal = () => setOpenWithdraw(true);
  const handleCloseWithdrawModal = () => setOpenWithdraw(false);

  // call Exchange Wallet from Exchange Api

  const ExchangeWallet = async () => {
    await DataService(userToken)
      .get(API.Exchange.GET_WALLET)
      .then((res) => {
        console.log(res?.data?.data, "res");
        setExchangeWallet(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    ExchangeWallet();
  }, []);

  const getAllReferralData = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", userDetails._id?.trim());
    DataService(userToken)
      .post(API.User.GET_TREE_DATA, urlencoded)
      .then((res) => {
        setReferralCount({
          leftUsers: res.data.leftUsers,
          rightUsers: res.data.rightUsers,
        });
        // setTreeData(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  const getMainReferraldata = async () => {
    await DataService(userToken)
      .get(API.User.USRT_MAIN_REFERRAL)
      .then((res) => {
        setMyReferral(res.data.data);
      })
      .catch((error) => {
        console.log(error, "Error");

        // Index.toast.error(error?.response?.data?.message);
      });
  };

  const getAllWalletCounts = async () => {
    await DataService(userToken)
      .get(API.User.USER_WALLET)
      .then((res) => {
        setAllAmounts(res.data.data);
      })
      .catch((error) => {
        console.log(error, "Error");
        // Index.toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getAllReferralData();
    getMainReferraldata();
    getAllWalletCounts();
  }, []);

  // Handle First Modal
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Handle Deposit Modal
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => {
    setOpen1(false);
  };

  // copy referral link
  const handleCopy = (data, type) => {
    copyTextToClipboard(
      `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?${type}-Referral=${data}`
    );
    toast.success("Referral link Copied");
  };

  // handle Referral QRCode
  const handleQrScan = (data, type) => {
    setReferralLink(
      `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?${type}-Referral=${data}`
    );
    handleOpen();
  };

  // handle deposit QRCode
  const handleDeposite = (data) => {
    // setQRCodeData("hello world");
    if (
      !getsettingData?.tron_address?.is_disable ||
      !getsettingData?.usdt_address?.disable ||
      getSettingData?.tron_address?.address ||
      getSettingData?.usdt_address?.address_tron
    ) {
      handleOpen1();
    } else {
      toast.error(
        "Due to some technical errors deposit of USDT is not available right now."
      );
    }
  };

  // Get setting Data
  const getSettingDetails = async () => {
    await DataService(userToken)
      .get(API.User.GET_SETTING)
      .then((res) => {
        let obj = {
          usdtAddress: res.data.data.usdt_address.address,
          tronAddress: res.data.data.tron_address.address_tron,
        };
        dispatch(getSettingData(res.data.data));
        setGetSettingData(res.data.data);
        if (res?.data?.data?.usdt_address?.disable) {
          // setQRCodeData(res.data.data.tron_address.address_tron);
          if (res?.data?.data?.tron_address?.is_disable) {
            // setQRCodeData(res.data.data.usdt_address.address);
            setOpen1(false);
          } else {
            setQRCodeData(res.data.data.tron_address.address_tron);
          }
        } else {
          setQRCodeData(res?.data?.data?.usdt_address?.address);
        }
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  const getTotaltoken = async () => {
    await DataService(userToken)
      .get(API.User.GET_TOTAL_TOKENS)
      .then((res) => {
        setTotalValues(res.data.data);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getSettingDetails();
    getTotaltoken();
  }, []);

  useEffect(() => {
    if (open1 == false) {
      getTotaltoken();
    }
  }, [open1]);

  return (
    <div>
      <Index.Box class="">
        <Index.Box className=" ">
          <Index.Box className="dashboard-content">
            <Index.Box className="res-set-btn-deposit">
              <Index.Box className="stack-box-btn">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Dashboard
                </Index.Typography>

                <Index.Box className="main-stack-box">
                  <Index.Box className="set-stack-btn">
                    <Index.Box className="set-qr-code set-qr-code-new">
                      <Index.Link class="text-decoration">
                        <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                          <Index.Button
                            onClick={() => {
                              handleDeposite();
                            }}
                            class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main "
                            tabindex="0"
                            type="button"
                            disableRipple
                          >
                            Deposit <span class=""></span>
                          </Index.Button>
                        </Index.Box>
                      </Index.Link>
                    </Index.Box>
                    <Index.Box className="set-qr-code set-qr-code-new">
                      <Index.Link class="text-decoration">
                        <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                          <Index.Button
                            class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main"
                            tabindex="0"
                            type="button"
                            disableRipple
                            onClick={(e) => {
                              userDetails?.is_verified
                                ? handleOpenWithdrawModal(e)
                                : Index.toast.error(
                                    "You are not verified for access withdraw"
                                  );
                            }}
                          >
                            Withdraw
                          </Index.Button>
                        </Index.Box>
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Link class="text-decoration res-set-btn-deposit-link">
                <Index.Box class="primary-border-btn-main res-primary-stack ">
                  <Index.Button
                    onClick={() => {
                      handleDeposite();
                    }}
                    class=" primary-border-btn  res-primary-border-btn  res-history-btn "
                    tabindex="0"
                    type="button"
                    disableRipple
                  >
                    Deposit <span class=""></span>
                  </Index.Button>
                  <Index.Button
                    class=" primary-border-btn  res-primary-border-btn  res-history-btn res-stack-box "
                    tabindex="0"
                    type="button"
                    disableRipple
                    onClick={(e) => {
                      userDetails?.is_verified
                        ? handleOpenWithdrawModal(e)
                        : Index.toast.error(
                            "You are not verified for access withdraw"
                          );
                    }}
                  >
                    Withdraw
                  </Index.Button>
                </Index.Box>
              </Index.Link>
            </Index.Box>
            <Index.Box className="admin-dashboad-row res-admin-dashboad-row">
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box user-dash-box current-rank-box">
                  <Index.Box className="flex-all admin-dashboard-table-row user-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        {" "}
                        Current Rank: {userDetails?.rank}
                      </Index.Typography>
                    </Index.Box>
                    {userDetails?.is_verified ? (
                      ""
                    ) : (
                      <Index.Box className="admin-sub-title-main">
                        <Index.Link to="/user/kyc">
                          <Index.Typography
                            className="note"
                            component="p"
                            variant="p"
                          >
                            <WarningIcon style={{ marginRight: "5px" }} />{" "}
                            Please complete your KYC
                          </Index.Typography>
                        </Index.Link>
                      </Index.Box>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Token
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            {totalValues?.totalToken
                              ? totalValues?.totalToken
                              : 0}{" "}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Png.logo_new}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Purchase USDT
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price"
                            variant="h1"
                            component="h1"
                          >
                            {totalValues?.totalUsdt
                              ? totalValues?.totalUsdt?.toFixed(2)
                              : 0}{" "}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* {totalValues?.remainingValue != undefined && (
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-dashboard-box">
                        <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                          <Index.Box className="admin-dash-left">
                            <Index.Typography
                              className="admin-dash-text user-dash-text"
                              variant="p"
                              component="p"
                            >
                              Total Remaining Balance
                            </Index.Typography>
                            <Index.Typography
                              className="admin-dash-price user-dash-price "
                              variant="h1"
                              component="h1"
                            >
                              {totalValues?.remainingValue
                                ? totalValues?.remainingValue?.toFixed(2)
                                : 0}{" "}
                              USDT
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="admin-dash-right dash-icon-main">
                            <img
                              src={Index.Svg.usdtlogo}
                              className="dash-icons"
                              alt="dashboard icon"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  )} */}

                  {/* <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Unstake Amount
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            {allAmounts?.unstak_amount
                              ? allAmounts?.unstak_amount?.toFixed(2)
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            My Total Income
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            {allAmounts?.total_amount
                              ? allAmounts?.total_amount?.toFixed(2)
                              : 0}{" "}
                            USDT
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            B4HIT Coin Withdrawal
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            {exchangeWallet?.length > 0
                              ? exchangeWallet?.map((item) => {
                                  if (item?.token_name == "B4HIT") {
                                    return Number(item?.token_balance).toFixed(
                                      2
                                    );
                                  }
                                })
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Png.logo_new}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            USDT Unstake
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            {exchangeWallet?.length > 0
                              ? exchangeWallet?.map((item) => {
                                  if (item?.token_name !== "B4HIT") {
                                    return Number(item?.token_balance).toFixed(
                                      2
                                    );
                                  }
                                })
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {/* <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Unstak Token
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            0 USDT
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            My Income
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            0 USDT
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Withdrawal
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            0 USDT
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box">
                      <Index.Box className="admin-dashboard-inner-box user-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text user-dash-text"
                            variant="p"
                            component="p"
                          >
                            Bonus
                          </Index.Typography>
                          <Index.Typography
                            className="admin-dash-price user-dash-price "
                            variant="h1"
                            component="h1"
                          >
                            0 USDT
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right dash-icon-main">
                          <img
                            src={Index.Svg.usdtlogo}
                            className="dash-icons"
                            alt="dashboard icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}
                  {/* deposit btn */}
                  {/* <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 3",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="set-stack-btn">
                      <Index.Box className="set-qr-code">
                        <Index.Link class="text-decoration">
                          <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                            <Index.Button
                              onClick={() => {
                                totalValues?.totalUsdt >= 1000
                                  ? toast.error(
                                      "Your exceed the limit of 1000 USDT. "
                                    )
                                  : handleDeposite();
                              }}
                              class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main "
                              tabindex="0"
                              type="button"
                              disableRipple
                            >
                              Deposit <span class=""></span>
                            </Index.Button>
                          </Index.Box>
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="set-qr-code">
                        <Index.Link class="text-decoration">
                          <Index.Box class="primary-border-btn-main  primary-btn-main-comman">
                            <Index.Button
                              class=" primary-border-btn history-btn deposit-link-btn-dark-comman-main "
                              tabindex="0"
                              type="button"
                              disableRipple
                            >
                              Stak <span class=""></span>
                            </Index.Button>
                          </Index.Box>
                        </Index.Link>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}
                  {/* end */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 8",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box current-rank-box user-dash-box">
                      <Index.Box className="flex-all admin-dashboard-table-row user-dashboard-table-row">
                        <Index.Box className="admin-sub-title-main">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            {" "}
                            My Referral Links
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="set-code-box-main">
                        <Index.Box>
                          <Index.Box className="admin-sub-title-main admin-sub-title-main-user  referral-box-code">
                            <Index.Typography
                              className="admin-sub-title"
                              component="h3"
                              variant="h3"
                            >
                              Left Referral
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box class="desktop-view ">
                          <Index.Box class="dash-referral-btn-main res-dash-referral-btn-main display-flex ">
                            <Index.Box class="text-copy-box dash-text-copy-box ">
                              <Index.Typography
                                className="text-copy"
                                component="h3"
                                variant="h3"
                              >
                                {`${process.env.REACT_APP_BASE_URL}/user/join-waitlist?Left-Referral=${userDetails?.left_referral}`}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="res-primary-btn">
                              <Index.Box class="primary-btn-main copy-btn res-copt-text">
                                <Index.IconButton
                                  className="history-btn"
                                  onClick={() => {
                                    handleCopy(
                                      userDetails?.left_referral,
                                      "Left"
                                    );
                                  }}
                                >
                                  <Index.ContentCopyIcon />
                                </Index.IconButton>
                              </Index.Box>

                              <Index.Link class="text-decoration res-copt-text">
                                <Index.Box class="primary-border-btn-main copy-btn copy-btn-set">
                                  <Index.IconButton
                                    onClick={() => {
                                      handleQrScan(
                                        userDetails?.left_referral,
                                        "Left"
                                      );
                                      setTypeReferral("Left Referral");
                                    }}
                                  >
                                    <Index.QrCodeScannerIcon />
                                  </Index.IconButton>
                                </Index.Box>
                              </Index.Link>

                              <Index.Box className="flex-text">
                                <Index.Box class="primary-btn-main copy-btn name-copy-qr">
                                  <Index.IconButton
                                    className="history-btn"
                                    onClick={() => {
                                      handleCopy(
                                        userDetails?.left_referral,
                                        "Left"
                                      );
                                    }}
                                  >
                                    Copy Text
                                  </Index.IconButton>
                                </Index.Box>
                                <Index.Link class="text-decoration">
                                  <Index.Box class="primary-border-btn-main copy-btn copy-btn-set name-copy-qr">
                                    <Index.IconButton
                                      onClick={() => {
                                        handleQrScan(
                                          userDetails?.left_referral,
                                          "Left"
                                        );
                                        setTypeReferral("Left Referral");
                                      }}
                                    >
                                      Qr Code
                                    </Index.IconButton>
                                  </Index.Box>
                                </Index.Link>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="set-code-box-main">
                        <Index.Box>
                          <Index.Box className="admin-sub-title-main admin-sub-title-main-user  referral-box-code">
                            <Index.Typography
                              className="admin-sub-title"
                              component="h3"
                              variant="h3"
                            >
                              Right Referral
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box class="desktop-view ">
                          <Index.Box class="dash-referral-btn-main res-dash-referral-btn-main display-flex ">
                            <Index.Box class="text-copy-box dash-text-copy-box ">
                              <Index.Typography
                                className="text-copy"
                                component="h3"
                                variant="h3"
                              >
                                {`${process.env.REACT_APP_BASE_URL}/user/join-waitlist?Right-Referral=${userDetails?.right_referral}`}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="res-primary-btn">
                              <Index.Box class="primary-btn-main copy-btn  res-copt-text">
                                <Index.IconButton
                                  className="history-btn"
                                  onClick={() => {
                                    handleCopy(
                                      userDetails?.right_referral,
                                      "Right"
                                    );
                                  }}
                                >
                                  <Index.ContentCopyIcon />
                                </Index.IconButton>
                              </Index.Box>
                              <Index.Link class="text-decoration  res-copt-text">
                                <Index.Box class="primary-border-btn-main copy-btn copy-btn-set">
                                  <Index.IconButton
                                    onClick={() => {
                                      handleQrScan(
                                        userDetails?.right_referral,
                                        "Right"
                                      );
                                      setTypeReferral("Right Referral");
                                    }}
                                  >
                                    <Index.QrCodeScannerIcon />
                                  </Index.IconButton>
                                </Index.Box>
                              </Index.Link>
                              <Index.Box className="flex-text">
                                <Index.Box class="primary-btn-main copy-btn name-copy-qr">
                                  <Index.IconButton
                                    className="history-btn"
                                    onClick={() => {
                                      handleCopy(
                                        userDetails?.right_referral,
                                        "Right"
                                      );
                                    }}
                                  >
                                    Copy Text
                                  </Index.IconButton>
                                </Index.Box>
                                <Index.Link class="text-decoration">
                                  <Index.Box class="primary-border-btn-main copy-btn copy-btn-set name-copy-qr">
                                    <Index.IconButton
                                      onClick={() => {
                                        handleQrScan(
                                          userDetails?.right_referral,
                                          "Right"
                                        );
                                        setTypeReferral("Right Referral");
                                      }}
                                    >
                                      Qr Code
                                    </Index.IconButton>
                                  </Index.Box>
                                </Index.Link>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 6",
                      lg: "span 4",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box user-dash-box">
                      <Index.Box className="flex-all admin-dashboard-table-row user-dashboard-table-row">
                        <Index.Box className="admin-sub-title-main">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            {" "}
                            My Team Counts
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="referral-box">
                        <Index.Box className="admin-sub-title-main admin-sub-title-main-user  referral-box-code">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            Left Referral
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className=" dash-token-value-box ">
                          <Index.Typography
                            className="user-value-box"
                            component="h3"
                            variant="h3"
                          >
                            {referralCount?.leftUsers
                              ? referralCount?.leftUsers
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="referral-box">
                        <Index.Box className="admin-sub-title-main referral-box-code admin-sub-title-main-user ">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            Right Referral
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className=" dash-token-value-box ">
                          <Index.Typography
                            className="user-value-box"
                            component="h3"
                            variant="h3"
                          >
                            {referralCount?.rightUsers
                              ? referralCount?.rightUsers
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-dash-box user-dash-box set-count-box-top">
                      <Index.Box className="flex-all admin-dashboard-table-row user-dashboard-table-row">
                        <Index.Box className="admin-sub-title-main">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            {" "}
                            My Referral
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="referral-box">
                        <Index.Box className="admin-sub-title-main admin-sub-title-main-user  referral-box-code">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            Left Referral
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className=" dash-token-value-box ">
                          <Index.Typography
                            className="user-value-box"
                            component="h3"
                            variant="h3"
                          >
                            {mainReferral?.leftList
                              ? mainReferral?.leftList?.length
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="referral-box">
                        <Index.Box className="admin-sub-title-main referral-box-code admin-sub-title-main-user ">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            Right Referral
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className=" dash-token-value-box ">
                          <Index.Typography
                            className="user-value-box"
                            component="h3"
                            variant="h3"
                          >
                            {mainReferral?.rightList
                              ? mainReferral?.rightList?.length
                              : 0}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Referral QR Code */}
      <Index.Modal
        className="set-qr-btn-modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <QrCodeGenerate
          referralLink={referralLink}
          typeReferral={typeReferral}
        />
      </Index.Modal>

      {/* Deposit QR Code */}

      <Index.Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DepositModal handleClose1={handleClose1} />
      </Index.Modal>

      {/* withdraw Modal */}
      <Index.Modal
        open={openWithdraw}
        onClose={handleCloseWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Withdraw
          handleCloseWithdrawModal={handleCloseWithdrawModal}
          totalBalance={allAmounts?.total_amount}
        />
      </Index.Modal>
    </div>
  );
}

export default DashboardContent;
