import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Countdown from "react-countdown";
import JoinWaitlist from "./JoinWaitlist";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export default function Home() {
  const navigate = Index.useNavigate();
  const { search } = Index.useLocation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [progress, setProgress] = useState("");

  // Get Referral Code from location state
  let referral;
  if (search.includes("?Left-Referral")) {
    referral = search.split("?Left-Referral=")[1];
  } else if (search.includes("?Right-Referral")) {
    referral = search.split("?Right-Referral=")[1];
  }
  // End

  // get Presale Details
  const getPresaleDetails = async () => {
    await DataService()
      .get(API.User.GET_WAITLIST_USER)
      .then((res) => {
        setEndDate(res?.data?.data?.current[0]?.endDate);
        setStartDate(res?.data?.data?.current[0]?.startDate);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getPresaleDetails();
  }, []);

  // calculate progress of presale phase
  useEffect(() => {
    var startdate = Index.moment(startDate);
    var enddate = Index.moment(endDate);
    let total = Index.moment(enddate)?.diff(startdate);

    var now = Index.moment();
    let remaining = Index.moment(enddate)?.diff(now);
    let totalRemaining = total - remaining;
    let percenteage = (totalRemaining * 100) / total;
    setProgress(percenteage);
  }, [startDate, endDate]);

  const renderer = ({ hours, minutes, seconds, days }) => {
    return (
      <>
        <div className="time-flex-list">
          <div className="main-time-box">
            <h6 className="time-numer-presale">{Math.round(days)}</h6>
            <p className="time-numer-presale-list">Days</p>
          </div>
          <div className="main-time-box">
            <h6 className="time-numer-presale">{Math.round(hours)}</h6>
            <p className="time-numer-presale-list">Hours</p>
          </div>
          <div className="main-time-box">
            <h6 className="time-numer-presale">{Math.round(minutes)}</h6>
            <p className="time-numer-presale-list">Mins</p>
          </div>
          <div className="main-time-box">
            <h6 className="time-numer-presale">{Math.round(seconds)}</h6>
            <p className="time-numer-presale-list">Seconds</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Index.Box>
        <Index.Box className="header-main-details position-relative">
          <Index.Box className="header-main-p header-token">
            <Index.Box className="comman-flex-justify header-flex-content">
              <Index.Box className="logo-main-left logo-token-left">
                <Index.Box className="logo-main-left-logo logo-token">
                  <Index.Link
                    to="/user/join-waitlist"
                    className="redirect-home-link"
                  >
                    <Index.Box className="set-home-logo">
                      <img
                        src={Index.Png.logo_new}
                        className="admin-header-logo"
                        alt="logo"
                      />
                      <Index.Box className="">
                        <Index.Typography
                          variant="span"
                          component="span"
                          class="logo-text home-logo-text"
                        >
                          B-4 Hit Coin
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Link>
                </Index.Box>
              </Index.Box>
              <Index.Box className="res-set-logo-home">
                <div class="primary-btn-main ">
                  <button
                    class="btn-dark-comman"
                    type="submit"
                    onClick={() => navigate("/user/login")}
                  >
                    Login
                  </button>
                </div>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="referral-main-component token-presale-background position-relative ">
        <Index.Box>
          <Index.Box className="container">
            <Index.Grid
              container
              spacing={6}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Index.Grid item xs={8} md={5} lg={4} className="res-gird-box">
                <div className="billionair-tokenpre-card">
                  <div className="billionair-tokenpre-body">
                    <div className="billionair-list-content">
                      <h5 className="hero-token-presale">Token Pre - Sale</h5>
                      <p className="hero-token-details-presale">
                        Join the official waitlist to be among the first to be
                        notified about the public token sale, invite your
                        friends, climb the leaderboard.
                      </p>
                    </div>

                    <div className="time-progressbar-list">
                      {endDate && (
                        <Countdown
                          date={new Date(endDate).getTime() + 86400000}
                          renderer={renderer}
                        />
                      )}

                      <div className="time-progress-line">
                        <div className="time-progress-bar-main">
                          <span
                            className="time-progress-bar"
                            style={{ width: `${progress}%` }}
                          >
                            <span className="progress-bar" />
                          </span>
                        </div>
                      </div>
                      <div className="time-presale-btns-list">
                        <div className="time-presale-btns-list new-time-presale-btns-list">
                          <div className="">
                            <div className="primary-btn-main white-paper-margin">
                              <Index.Link
                                target="_blank"
                                to="https://wordpressdemo.co.in/b4h/wp-content/uploads/2023/05/B4H_Wihite_Paper_V5_Low.pdf"
                              >
                                <button
                                  className="btn-dark-comman"
                                  type="submit"
                                >
                                  Whitepaper
                                </button>
                              </Index.Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Index.Grid>
              <Index.Grid item xs={8} md={7} lg={8} className=" res-pt-20 ">
                <JoinWaitlist referral={referral} />
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="footer-main-component">
        <Index.Footer />
      </Index.Box>
    </>
  );
}
