import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

function Referral() {
  const details = Index.useSelector((store) => store.admin.userDetail);
  let userDetails = JSON.parse(window.atob(details));

  const [leftRefferalList, setLeftRefferalList] = useState([]);
  const [rightRefferalList, setRightRefferalList] = useState([]);
  const [leftReferralAmount, setLeftReferralAmount] = useState("");
  const [rightReferralAmount, setRightReferralAmount] = useState("");

  const getAllReferralData = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("_id", userDetails._id)?.trim();
    DataService(userDetails?.token?.trim())
      .post(API.User.GET_TREE_DATA, urlencoded)
      .then((res) => {
        setRightReferralAmount(res?.data?.rightTotal);
        setLeftReferralAmount(res?.data?.leftTotal);
        setLeftRefferalList(res?.data?.leftUsersList);
        setRightRefferalList(res?.data?.rightUsersList);
      })
      .catch((error) => {
        // Index.toast.error(error?.response?.data?.message);
        console.log(error, "Error");
      });
  };

  useEffect(() => {
    getAllReferralData();
  }, []);

  const handleCopy = (data) => {
    Index.copyTextToClipboard(data);
    Index.toast.success("Referral link Copied");
  };

  return (
    <div>
      <Index.Box class="">
        <Index.Box className="setting-card">
          <Index.Box className="dashboard-content referral-list-tree-btn"></Index.Box>

          <Index.Box className="flex-table ">
            <Index.Box className="ref-card-main">
              <Index.Box className="ref-title" align="center">
                Left Team (${" "}
                {leftReferralAmount ? leftReferralAmount?.toFixed(2) : 0})
              </Index.Box>
              <Index.Box className="ref-body">
                <Index.Box className="ref-inner">
                  {leftRefferalList.length > 0
                    ? leftRefferalList?.map((item) => {
                        console.log(item, 92);
                        let LeftLink = `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?Left-Referral=${item.left_referral}`;
                        let showLeftLink = `${Index.ReplaceCharsWithAsterisks(
                          item.left_referral
                        )}`;
                        return (
                          <>
                            <Index.Box
                              className="ref-card-inner"
                              onClick={() => handleCopy(LeftLink)}
                            >
                              {item?.user_name} ({showLeftLink} )
                            </Index.Box>
                          </>
                        );
                      })
                    : "No Data Found"}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="ref-card-main">
              <Index.Box className="ref-title" align="center">
                Right Team (${" "}
                {rightReferralAmount ? rightReferralAmount?.toFixed(2) : 0})
              </Index.Box>
              <Index.Box className="ref-body">
                <Index.Box className="ref-inner">
                  {rightRefferalList?.length > 0
                    ? rightRefferalList?.map((item) => {
                        let rightLink = `${process.env.REACT_APP_BASE_URL}/user/join-waitlist?Right-Referral=${item.right_referral}`;
                        let showRightLink = `${Index.ReplaceCharsWithAsterisks(
                          item.right_referral
                        )}`;

                        return (
                          <>
                            <Index.Box
                              className="ref-card-inner"
                              onClick={() => handleCopy(rightLink)}
                            >
                              {item?.user_name} ({showRightLink} )
                            </Index.Box>
                          </>
                        );
                      })
                    : "No Data Found"}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default Referral;
